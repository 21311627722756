import React,{useEffect,useState} from 'react';
import Accounting from './Accounting';
import Banners from './Banners';
import ChargeFacility from './ChargeFacility';
import ClientMaster from './ClientMaster';
import Statements from './Statements';
import StationeryManagement from './StationeryManagement';
import Technology from './Technology';
import Transactions from './Transactions';
import DematRequest from './DematRequest';
import ChangeBilling from './ChargeBilling';
import Branches from './Branches';
import Website from './Website';
import BranchEstro from './BranchEstro';

function ProducrEstro
() {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
},[])

useEffect(() => {
  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  window.addEventListener('resize', handleWindowResize);

  return () => {
    window.removeEventListener('resize', handleWindowResize);
  };
}, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}


  return (
    <>
    <Banners/>
    <ClientMaster/>
    <Transactions/>
    <ChargeFacility/>
    {windowSize?.innerWidth >= 768 ? <Branches/> : <BranchEstro/>}
    <StationeryManagement/>
    <DematRequest/>
    <Statements windowSize={windowSize?.innerWidth}/>
    <ChangeBilling/>
    <Accounting/>
    <Technology/>
    <Website/>
    </>
  )
}

export default ProducrEstro;
