import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import Tooltip from '@mui/material/Tooltip';
import MobileSidebar from './MobileSidebar';
const Navbar = ({ data }) => {

    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const ref = useRef();

  

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [open]);

    const menu = (
        <Menu
          items={[
            {
              key: '1',
              label: (<Link to="/trade-plus" >TradePlus</Link>),
            },
            
            {
              key: '2',
              label: (<Link to="/cross" >Cross</Link>),
            },
                          
            {
              key: '3',
              label: (<Link to="/estro" >Estro</Link>),
            },

            {
              key: '4',
              label: (<Link to="/commex" >Commex</Link>),
            },
            {
              key: '5',
              label: (<Link to="/trade-web" >TradeWeb</Link>),
            },
            {
              key: '6',
              label: (<Link to="/e-sign" > Associated Modules</Link>),
            },

            // {
            //   key: '3',
            //   label: (<Link to="/estro" >Estro</Link>),
            // },
            
          ]}
        />
      );

       const dmenu = (
        <Menu
          items={[
            {
              key: '1',
              label: (<Link to="/downloads/TradePlus&TradeNet" >TradePlus & TradeNet</Link>),
            },
            {
              key: '2',
              label: (<Link to="/downloads/ESTRO&EstroNet" >Estro & EstroNet</Link>),
            },
            {
                key: '3',
                label: (<Link to="/downloads/CROSS&CrossNet" >Cross & CrossNet</Link>),
              },
              {
                key: '4',
                label: (<Link to="/downloads/CommEx&CommNet" >Commex</Link>),
              },
          ]}
        />
      );

      const Contact = (
        <Menu
          items={[
            {
              key: '1',
              label: <a
                href="mailto:info@tplus.in"
                className="text-gray-700 block px-4 py-2 text-sm text-end  cursor-pointer"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-0"
            >
              info@tplus.in
            </a>,
            },
            {
              key: '2',
              label: <a  
                className="text-gray-700 block px-4 py-2 text-sm text-end cursor-auto"
                role="menuitem"
                tabIndex={-1}
                id="menu-item-1"
                href="#"
            >
                +91 9920189071
            </a>,
              
            },
          ]}
        />
      );

    return (
        <>

<header class="border-gray-200  lg:py-2 md:py-2 py-0">
  <div ref={ref} className="container flex justify-between items-center mx-auto lg:py-[5px] ">
  <div className="flex items-center cursor-pointer" onClick={()=>{navigate("/")}}>
                        <img
                            src="/assets/img/logo.svg"
                            className="mr-3 lg:w-[147px] md:w-[147px] w-[120px] sm:h-12"
                            alt=""
                        />
                    </div>
    <nav  className="justify-between items-center md:w-full md:flex   mx-auto "
                        id="mobile-menu-2">
     <ul className="2xl:contents xl:contents lg:contents mt-0 md:flex-row md:mt-0 md:text-sm  md:font-medium justify-content-left items-left
                          hidden md:flex 
                        ">
                            {data.map((val,i) => (
                                <li ref={ref} className='nav-item mr-8' key={val.id}>
                                    {val.title !== "Products" ? val.title == "Downloads" ? <Dropdown overlay={dmenu} placement="bottom" arrow>
                                        <Link to="/Downloads" className={`active-item ${val.selected ? "text-[#3491FF] active" : "text-[#ccc]"} font-medium text-lg btn-group__item`}
                                            placement="bottom">{val.title}
                                        </Link>
                                    
                                  </Dropdown>:
                                    <Link to={val.path}>
                                        <a
                                            className={`active-item ${val.selected ? "text-[#3491FF] active" : "text-[#ccc]"} font-medium text-lg btn-group__item`}
                                            aria-current="page"
                                            onClick={()=>{
                                                setOpen(false)
                                            }}
                                        >
                                        {val.title}
                                        </a>
                                    </Link>:
                                    <Dropdown overlay={menu} placement="bottom" arrow>
                                        <Link to="/products" className={`active-item ${val.selected ? "text-[#3491FF] active" : "text-[#ccc]"} font-medium text-lg btn-group__item`}
                                            placement="bottom">{val.title}
                                        </Link>
                                    
                                  </Dropdown>
                                    }
                                </li>
                            ))}
                            <li className='relative nav-item'>
                                    <Dropdown overlay={Contact} placement="bottom" arrow>
                                        <div to="/products" className={`   text-[#ccc] font-medium text-lg btn-group__item`}
                                            
                                            placement="bottom">Contact
                                        </div>
                                    
                                  </Dropdown>
                                {/* <a
                                    href="#!"
                                    className={`active-item text-[#ccc] font-medium text-lg btn-group__item flex items-center`}
                                    id="menu-button"
                                    aria-expanded="true"
                                    aria-haspopup="true"
                                    onClick={() => setOpen(!open)}
                                >
                                    Contact
                                    <svg
                                        className="-mr-1 ml-2 h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </a>
                                <ul
                                    className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="menu-button"
                                    tabIndex={-1}
                                    style={{ display: open ? 'block' : 'none' }}
                                >
                                    <div className="py-1" role="none">
                                        <a
                                            href="mailto:info@secmark.in"
                                            className="text-gray-700 block px-4 py-2 text-sm text-end"
                                            role="menuitem"
                                            tabIndex={-1}
                                            id="menu-item-0"
                                        >
                                            info@secmark.in
                                        </a>
                                        <div  
                                            className="text-gray-700 block px-4 py-2 text-sm text-end"
                                            role="menuitem"
                                            tabIndex={-1}
                                            id="menu-item-1"
                                            href="#"
                                        >
                                            +91 9920189071
                                        </div>
                                    </div>
                                </ul> */}
                            </li>
                        </ul>

    </nav>

    <a href="https://tplus.in/" className="text-[#FFFFFF] lg:block md:block hidden text-[16px] px-4 py-3 lg:w-[14%] md:w-[16%] rounded-md bg-blue-400 hover:bg-slate-50" target="_blank">
    Switch to Old Site
                </a>
    <MobileSidebar NavItems={data} />
  </div>
</header>
        </>
    )
}
export default Navbar;