import React from "react";

const CrossTable = ({ data,windowSize }) => {
  console.log("🚀 ~ file: CrossTable.js ~ line 4 ~ CrossTable ~ data", windowSize)
  function createData(Cross, apipre) {
    return { Cross, apipre };
  }

  const rows = [
    createData("CrossNet", "CrossNet"),
    createData("CrossWeb", "Website"),
    createData("eSign", "ESign"),
  ];
  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-5 mb-10	">
        
        <table className="w-[100%] overflow-scroll text-sm text-center text-gray-500 ">
          <thead className="text-xs  text-gray-700 capitalize  ">
            <tr>
              <th rowSpan={1} colSpan={6}>
              <div className="text-xs text-white capitalize bg-[#3491FF] w-100">
          <div class="flex items-center justify-center py-3">
          <input
              checked={data?.Cross == "Y"}
              className="w-[18px] h-[18px] mr-[5px] items-center"
              type="checkbox" 
            />
            <div className="text-lg font-medium normal-case">Cross</div>
          </div>
        </div>
              </th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              {/* <th scope="col" className="px-6 py-3 text-center border-l">
                Clearing ID
              </th> */}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr class={`${i % 2 == 0 && "bg-[#F3F7FC]"}`}>
                <th class="w-1/5">
                  <div class="flex items-center justify-left px-6 py-3">
                    <div className="flex mx-2">
                      <input
                        id="default-checkbox"
                        checked={data?.[`${row.apipre}`] == "Y"}
                        type="checkbox"
                        value=""
                        className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded border-gray-300  "
                      />
                    </div>
                    <div className="text-[#3491FF] text-md font-medium">
                      {row.Cross}
                    </div>
                  </div>
                </th>
                <th></th>
                <th className="w-[200px]"></th>
                {/* <th className="p-2 border-l w-1/5">
                  <div className="flex justify-start ">
                    <input
                      
                      type="text"
                      // value={data?.[row.apipre]}
                      id="visitors"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer      "
                      placeholder=""
                      required
                    />
                  </div>
                </th> */}
              </tr>
            ))}
            <tr className="">
              {windowSize >= 430 &&
              <th></th>
              }
              <th>
                <div className="text-sm font-medium text-right">
                  Participant ID
                </div>
              </th>
              <th>
                <div className={`${windowSize <= 430 ? "w-[93px]" : ""} flex px-2 justify-center mb-4`}>
                  <input disabled
                    type="number"
                    value={data?.ParticipantID}
                    name="ParticipantID"
                    id="visitors"
                    className=" block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
                    placeholder={data?.ParticipantID}
                    required
                  />
                </div>
              </th>
            </tr>
          </tbody>
          {/* <div class="flex items-center justify-end py-3">
            <div className="text-sm font-medium">Participant ID</div>
            <div className="flex mx-2">
              <input
                disabled
                type="text"
                value={data?.ParticipantID}
                name="ParticipantID"
                id="visitors"
                className=" block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
                placeholder=""
                required
              />
            </div>
          </div> */}
        </table>
      </div>
    </div>
  );
};

export default CrossTable;
