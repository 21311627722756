import React from 'react'
import './special.css';
import {IconOne,IconTwo,IconThree,IconFour,IconFive,IconSix,IconSeven,IconEight,} from './Icons';
const dataList = [
    {count: "", icons:<IconOne/>,
     desc:"Multiple company / Multiple Exchanges."},
    {count: "", icons:<IconTwo/>,
     desc:"Simple & easy to understand entry screens and reports."},
    {count: "", icons:<IconThree/>,
     desc:"Can invoke Calculator / calendar from within the software."},
    {count: "", icons:<IconFour/>,
     desc:"All processes taken care of on single screen."},
    {count: "", icons:<IconFive/>,
     desc:"Automatic transfer of bills to accounts."},
    {count: "", icons:<IconSix/>,
     desc:"Very optimally designed data structure, gives better response time."},
    {count: "", icons:<IconSeven/>, desc:" Built in backup facility for fast and simple backup procedure."},
]

function Special() {
    return (
        <>
            <section className="text-gray-600 body-font bg-[#F4FAFF] px-0 py-12">
            <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-2 pb-0 text-[#0A2A4A] text-center">
            Special Features
          </h2>
                <div className="container lg:px-5 md:px-5 px-0 py-14 mx-auto flex flex-wrap">
                    <div className="flex flex-wrap -m-4 ">
                        {dataList.map((item)=>(
                                 <div className=" lg:w-1/3 md:w-1/2 sm:w-[490px] p-12 ">
                                 <a href="#" className="block p-6 h-[10rem]  md:w-full bg-white rounded-lg border border-gray-200 shadow-md relative">
                                     <div className='absolute garident-svgImg'>
                                         {item.icons}
                                     </div>
                                     <p className="font-normal text-[#808D9A] lg:text-[18px] md:text-[17px] text-[15px] lg:mt-4 md:mt-4 mt-6 pt-2 text-center lg:p-3 md:p-8 sm:p-10">
                                        {item.desc}
                                     </p>
                                 </a>
                             </div>
                        ))}
                       
                    </div>
                </div>
            </section>
        </>
    )
}

export default Special