import React, { useState, useEffect } from "react";
import SliderCard from "./SliderCard";
import Slider from "react-slick";
import axios from "axios";
import fileDownload from "js-file-download";
import EstroNetPopUp from "./EstroNetPopUp";
import Marquee from "react-fast-marquee";

const BackArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      class="back-forword-btn bg-white -m-8 absolute z-50	 ext-blue-700 border border-blue-700 hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[12%]"
    >
      <svg
        className="stroke-[#3491ff] hover:stroke-[#ffffff]  "
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};

const ForwardArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      class="back-forword-btn  bg-white -ml-5 z-50 text-blue-700 border border-blue-700 hover:bg-[#3491FF]   hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute right-[4%]"
    >
      <svg
        className="stroke-[#3491ff] hover:stroke-[#ffffff]"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};

const EstroNetDownload = (props) => {
  const [checkObj, setCheckObj] = useState({});
  const {dateData} = props

  console.log(
    "🚀 ~ file: EstroNetDownload.js ~ line 31 ~ EstroNetDownload ~ checkObj",
    checkObj
  );
  const [model, setModal] = useState("");
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    nextArrow: <ForwardArrow />,
    prevArrow: <BackArrow />,
  };

  const DownloadZipFile = async (zippedFile, fileName = "ZippedData.zip") => {
    // var blob = new Blob([str2bytes(myData)], {type: "application/zip"});
    // saveAs(blob, "data.zip");
 
    axios
      .get(zippedFile, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });

    //   const blobPromise =  fetch(zippedFile)
    // .then(function (response) {
    //     if (response.status === 200 || response.status === 0) {
    //         return Promise.resolve(response.blob());
    //     } else {
    //         return Promise.reject(new Error(response.statusText));
    //     }
    // })

    //   let blob = new Blob([blobPromise], { type: 'application/zip' })

    //   const downloadUrl = URL.createObjectURL(blob)
    //   let a = document.createElement("a");
    //   a.href = downloadUrl;
    //   a.download = fileName;
    //   document.body.appendChild(a);
    //   a.click();
  };
  useEffect(() => {
    console.log("first");
    let Data = JSON.parse(localStorage.getItem("ProfileTab"));
    let check = Data?.["Estro"];
    setCheckObj(check);
  }, []);
  return (
    <>
    {checkObj.Estro === "Y" && (<div className=" test-back">
        <>
          <div className="py-10">  
          <div >
            <div className="font-medium lg:text-3xl md:text-3xl text-[25px] mb-4	text-center text-[#0A2A4A]">
              Estro & EstroNet
            </div>
            <div className="lg:text-[18px] mg:text-[18px] text-[16px] text-center text-[#808D9A] font-normal">
              This Electronic Security Treasury Records Organiser provides back
              officer / front office coordination, billing and accounting
              software for DPs with NSDL.
            </div>
            <div className="lg:text-[18px] mg:text-[18px] text-[14px] text-center text-[#808D9A] font-normal px-8">
            {/* <Marquee>
            {
              props?.Result?.map((d,i)=>{
                return (
                  i==2 && <div dangerouslySetInnerHTML={{__html: d?.result?.Data?.Message}}></div>
                )
              })
            }
            </Marquee> */}

            </div>
          </div>
          <div className=" grid sm:grid-cols-2 md:grid-cols-4 justify-between mx-10 download-sliders">
            {![checkObj.Estro, checkObj.ESign].every((d) => d == "N") && (
              <div className="">
                <h2 className=" text-white text-lg text-center rounded-tl rounded-bl bg-[#3491FF] py-3 m-0">
                  {" "}
                  Head Office
                </h2>
                <div className="table-auto w-full whitespace-no-wrap p-0 m-0 border-l-2">
                  <div className="bg-[#3491]  odd-color">
                    <div className={`${checkObj?.Estro=="N" && "hidden"}`}>
                      <div>
                        {" "}
                        <SliderCard
                          title="Head officer"
                          subTitle="Estro"
                          date={dateData.length > 0 && dateData?.find(a=> a[1] == "estro/estro.zip")?.[2]}
                          img={"/assets/img/svg-1.svg"}
                          arrow={true}
                          download={"/download/estro/estro.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.Estro}
                          clickToDownload
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.Estro=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          title="Head officer"
                          subTitle="DLLs"
                          date={ dateData.length > 0 && dateData?.find(a=> a[1] == "estro/Estrodll.zip")?.[2]}

                          img={"/assets/img/svg-1.svg"}
                          arrow={true}
                          download={"/download/estro/Estrodll.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={checkObj?.Estro}
                        />
                      </div>
                    </div>
                    {/* <div className={`${checkObj?.Estro=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          title="Head officer "
                          subTitle="DLLs for XP"
                          img={"/assets/img/svg-7.svg"}
                          arrow={false}
                          index={props.index}
                          download={"/download/estro/Estrodll.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.Estro}
                          clickToDownload
                        />
                      </div>
                    </div> */}
                    <div className={`${checkObj?.Estro=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          title="Head officer"
                          subTitle="DB-Support"
                          date={ dateData.length > 0 && dateData?.find(a=> a[1] == "estro/Estrodb.zip")?.[2]}
                          img={"/assets/img/svg-1.svg"}
                          arrow={true}
                          download={"/download/estro/Estrodb.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={checkObj?.Estro}
                        />
                      </div>
                    </div>

                    <div className={`${checkObj?.ESign=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          title="Head officer"
                          subTitle="E-Sign"
                          img={"/assets/img/svg-1.svg"}
                          date={ dateData.length > 0 && dateData?.find(a=> a[1] == "Esignpro/EsignproEstro.zip")?.[2]}

                          arrow={true}
                          download={"/download/Esignpro/EsignproEstro.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.ESign} 
                          clickToDownload
                        />
                      </div>
                    </div>
                    
                  </div>
                </div>
                {/* <SliderCard title='Head officer' subTitle='DB Support' img={'/assets/img/svg-1.svg'} arrow={true} download={'/download/Estrodb.zip'} DownloadZipFile={DownloadZipFile} buttonTxt={checkObj?.Estro} clickToDownload/> */}
              </div>
            )}
            {![checkObj.EstroNet].every((d) => d == "N") && (
              <div className="relative">
                <h2 className=" text-white text-lg text-center bg-[#3491FF] py-3 m-0">
                  {" "}
                  Branch                
                </h2>
                <div className="table-auto w-full whitespace-no-wrap p-0 m-0 border-l-2">
                  <div className="bg-[#3491]  odd-color">
                    <div className={`${checkObj?.EstroNet=="N" && "hidden"}`}>
                      <div>
                {/* <SliderCard title='Branch & Individual Client' subTitle='DLLs for XP' img={'/assets/img/svg-7.svg'} arrow={false} index={props.index} download={'/download/Estrodll.zip'} DownloadZipFile={props.DownloadZipFile} viewDetails={()=>{setModal("Branch & Individual Client")}}/> */}
                <SliderCard
                  title="Branch"
                  subTitle="EstroNet"
                  img={"/assets/img/svg-7.svg"}
                  arrow={false}
                  date={ dateData.length > 0 && dateData?.find(a=> a[1] == "Estronet/estronet35.zip")?.[2]}
                  download={"/download/Estronet/estronet35.zip"}
                  DownloadZipFile={props.DownloadZipFile}
                  buttonTxt={checkObj?.EstroNet}
                  clickToDownload
                />
                </div>
                    </div>
                  </div>
                </div>
                {/* <SliderCard title='Branch & Individual Client' subTitle='Estro Web' img={'/assets/img/svg-1.svg'} arrow={true} index={props.index} download={'/download/estro.zip'} DownloadZipFile={props.DownloadZipFile} viewDetails={()=>{setModal("Head officer")}}/> */}
              </div>
            )}
            {![checkObj.Website].every((d) => d == "N") && (
              <div className="">
                <h2 className="text-white text-lg text-center rounded-tl rounded-bl bg-[#3491FF] py-3 m-0">
                  Individual Client
                </h2>
                <div className="table-auto w-full whitespace-no-wrap p-0 m-0 border-l-2">
                  <div className="bg-[#3491]  odd-color">
                    <div className={`${checkObj?.Website=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          title="Individual Client"
                          subTitle="Estro Web"
                          img={"/assets/img/svg-1.svg"}
                          arrow={true}
                          date={ dateData.length > 0 && dateData?.find(a=> a[1] == "estro/estro.zip")?.[2]}

                          download={"/download/estro/estro.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.Website}
                          clickToDownload
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <SliderCard title='Branch & Individual Client' subTitle='DLLs for XP' img={'/assets/img/svg-7.svg'} arrow={false} index={props.index} download={'/download/Estrodll.zip'} DownloadZipFile={props.DownloadZipFile} viewDetails={()=>{setModal("Branch & Individual Client")}}/>
            <SliderCard title='Branch & Individual Client' subTitle='EstroNet' img={'/assets/img/svg-7.svg'} arrow={false} index={props.index} download={'/download/estronet35.zip'} DownloadZipFile={props.DownloadZipFile} viewDetails={()=>{setModal("Branch & Individual Client")}}/> */}
              </div>
            )}
            {checkObj?.Estro == "Y" && (
              <div className="relative">
                <h2 className=" text-white text-lg text-center rounded-tr rounded-br bg-[#3491FF] py-3 m-0">
                  Knowledge Base
                </h2>
                <div className="table-auto w-full  whitespace-no-wrap p-0 m-0 border-l-2 ">
                  <div className="bg-[#3491]  odd-color">
                    <div className={`${checkObj?.Estro=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          title="Knowledge Base"
                          subTitle="Circulars"
                          img={"/assets/img/svg-6.svg"}
                          arrow={false}
                          download={""}
                          section="E"
                          clickToDownload
                          buttonTxt={checkObj?.Estro}
                          view
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.Estro=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          title="Knowledge Base"
                          subTitle="Help"
                          img={"/assets/img/svg-6.svg"}
                          arrow={false}
                          download={true}
                          dtitle={"estro"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={checkObj?.Estro}
                          view
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.Estro=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          title="Knowledge Base"
                          subTitle="Modifications"
                          img={"/assets/img/svg-6.svg"}
                          arrow={false}
                          download={""}
                          section="E"
                          clickToDownload
                          buttonTxt={checkObj?.Estro}
                          view
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <SliderCard title='Knowledge Base' subTitle='DB-Support' img={'/assets/img/svg-1.svg'} arrow={false} download={'/download/Estrodb.zip'}  clickToDownload buttonTxt={checkObj?.Estro}/> */}
              </div>
            )}
          </div>
          {/* <EstroNetPopUp
            openModal={model}
            checkObj={checkObj}
            onclose={() => {
              setModal(false);
            }}
            DownloadZipFile={props.DownloadZipFile}
          /> */}
          </div>
        </>
      
    </div>)}
    </>
  );
};

export default EstroNetDownload;
