import React from "react";
import TradePlusTable from "./TradePlusTable";

const ProductTable = ({tableData,data,windowSize}) => {
  console.log("🚀 ~ file: ProductTable.js ~ line 4 ~ ProductTable ~ data", data,tableData)
  function createData(Branch, Commex, ClearingID,apiPre,apitab) {
    return { Branch, Commex, ClearingID ,apiPre,apitab};
  }

  const rows = [
    createData("TradeNet", "NSE",'','Nse','TradeNet'),
    createData("TradeWeb", "BSE",'','Bse','Website'),
    createData("eSign", "MCX",'','MCX','ESign'),
  ];

  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-5 mb-10	">
        
        <table className={`${windowSize >= 430 ? "w-[100%]" : "w-[560px]"} overflow-scroll text-sm text-center text-gray-500`}>
          <thead className="text-xs  text-gray-700 capitalize  ">
            <tr>
              <th rowSpan={1} colSpan={6}>
                    <div className="text-xs text-white capitalize bg-[#3491FF] w-100">
                <div className="flex items-center justify-center py-3">
                  <input
                    checked={data?.TradePlus == "Y"}
                    className="w-[18px] h-[18px] mr-[5px] items-center"
                    type="checkbox" 
                  />
                  <div className="text-lg font-medium normal-case">TradePlus</div>
                </div>
              </div>
              </th>
            </tr>
            <tr className="bg-[#F3F7FC]">
              <th scope="col" classNameN="px-6 py-3 text-center border-r"></th>
              <th scope="col" classNameN="px-6 py-3 text-center border-l"></th>
              <th scope="col" className="px-6 py-3 text-center border-l">
                Cash
              </th>
              <th scope="col" className="px-6 py-3 text-center border-l">
                F&O
              </th>
              <th scope="col" className="px-6 py-3 text-center border-l">
                FX
              </th>
              <th scope="col" className="px-6 py-3 text-center border-l">
                Clearing ID
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr className={`${i % 2 !== 0 && "bg-[#F3F7FC]"}`}>
                <th className="">
                  <div className="flex items-center justify-left pl-6">
                    <div className="flex px-2">
                      <input  
                        id="default-checkbox"
                        type="checkbox"
                        checked={
                          data?.[`${row.apitab}`] == "Y"
                        }
                        className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded   "
                      />
                    </div>
                    <div className="px-3 text-[#3491FF] text-sm font-medium">
                      {row.Branch}
                    </div>
                  </div>
                </th>
                <th className="px-3 border-l text-[#3D3D3D]">{row.Commex}</th>
                <th className="p-2 border-l">
                  <div>
                    <input  
                      id="default-checkbox"
                      type="checkbox"
                      checked={
                        data?.[`${row.apiPre}Cash`] == "Y"
                      }
                      className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded    "
                    />
                  </div>
                </th>
                <th className="p-2 border-l">
                  <div>
                    <input  
                      id="default-checkbox"
                      type="checkbox"
                      checked={
                        data?.[`${row.apiPre}FO`] == "Y"
                      }
                      className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded    "
                    />
                  </div>
                </th>
                <th className="p-2 border-l">
                  <div>
                    <input  
                      id="default-checkbox"
                      type="checkbox"
                      checked={
                        data?.[`${row.apiPre}FX`] == "Y"
                      }
                      className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded    "
                    />
                  </div>
                </th>
                <th className="p-2 border-l w-1/5">
                  <div className="flex justify-center ">
                    <input disabled 
                      type="text"
                      value={data?.[`${row.apiPre}ClgID`] }
                      id="visitors"
                      className=" block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2  appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
                    />
                  </div>
                </th>
              </tr>
            ))}
            <tr className="">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              {/* <th>
                <div className="text-sm font-medium text-right">
                  Participant ID
                </div>
              </th>
              <th>
                <div className="flex px-2 justify-center mb-4">
                  <input disabled
                    type="number"
                    value={data?.ParticipantID}
                    name="ParticipantID"
                    id="visitors"
                    className=" block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
                    placeholder=""
                    required
                  />
                </div>
              </th> */}
            </tr>
          </tbody>
        </table>
      </div>:
    </div>
  );
};

export default ProductTable;
