import React from 'react'

const CrossForm = (props) => {
  return (
        <div>
            <form>
            <div className="mb-1">
                <label className="block text-[#0A2A4A] text-sm font-medium mb-2" for="username">
                Participant ID for CDSL: 
                </label>
                <input  name="cdslDPID" className="shadow appearance-none border rounded 2xl:w-96 xl:w-96 lg:w-96 md:w-72 sm:w-60 w-[100%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Enter your Participant ID for CDSL" onChange={(e)=>{props.setData(e)}} />
            </div>
            {props.check&&<div className="text-red-500 mx-2">Please Enter valid Details...</div>}
            </form>
        </div>  )
}

export default CrossForm