import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Menu from '@mui/icons-material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropDownList from './DropDownList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link, useNavigate } from 'react-router-dom';
// import {
//     Apps,
//     Menu,
//     ContactMail, 
//     AssignmentInd,
//     Home
//   } from "@material-ui/icons";
export default function MobileSidebar({NavItems}) {
  
const navigate = useNavigate();
    
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [activeDropDown,setActiveDropDown] = React.useState(false);
  const HandleLink = (link)=>{
    // toggleDrawer('right', false)
    if(link==='mailto:info@tplus.in' || link==='info@tplus.in' || link==='#'){
      return;
    }
    
    let anchorTag = 'right';
    setState({ ...state, [anchorTag]: false });

    navigate(link)
    console.log(link)
  }


const menuItems = [
    {title:'Home',link:'/',Icon:'',childs:[]},
    {title:'Contact',link:'/',Icon:'',childs:[
      {title:'info@tplus.in',link:'mailto:info@tplus.in',Icon:''},
      {title:'+91 9920189071',link:'#',Icon:''},
    ]},

    {title:'Products',link:'/products',Icon:'',childs:[
        {title:'TradePlus',link:'/trade-plus',Icon:''},
        {title:'Cross',link:'/cross',Icon:''},
        {title:'Estro',link:'/estro',Icon:''},
        {title:'Commex',link:'/commex',Icon:''},
        {title:'TradeWeb',link:'/trade-web',Icon:''},
        {title:'Associated modules',link:'/e-sign',Icon:''},
    ]},
    {title:'Downloads',link:'/Downloads',Icon:'',childs:[
        {title:'TradePlus & TradeNet',link:'/downloads/TradePlus&TradeNet',Icon:''},
        {title:'Estro & EstroNet',link:'/downloads/ESTRO&EstroNet',Icon:''},
        {title:'Cross & CrossNet',link:'/downloads/CROSS&CrossNet',Icon:''},
        {title:'Commex',link:'/downloads/CommEx&CommNet',Icon:''},
    ]},
];
  return (
    <>
    <div className='sidebar-navigations'  >
        <Button 
        onClick={toggleDrawer('right', true)}
        id="hamburger-btn"
        >
            <Menu />
        </Button>

        <Drawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            
        >

            <Box 
                sx={{ width: '260px',height:'100%', backgroundColor:'#0a2a4a' }}
                role="presentation"
                // onClick={toggleDrawer('right', false)}
                // onKeyDown={toggleDrawer('right', false)}
            >
                

                <List>
                    {menuItems.map((SingleMenu, index) => (
                        <>
                            {SingleMenu.childs.length > 0 ?  
                            <>
                                    <ListItem key={index} disablePadding>
                                            <ListItemButton onClick={()=>setActiveDropDown(activeDropDown ? false : index)}>
                                            <ListItemIcon className="icons" >
                                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                            </ListItemIcon>
                                            {/* <Link to={SingleMenu.link} className="link-tag"  onClick={()=>toggleDrawer('right', false)} > */}
                                                <ListItemText primary={SingleMenu.title} 
                                                    // onClick={()=>HandleLink(SingleMenu.link)}
                                                    className="link-tag"
                                                />
                                            {/* </Link> */}
                                                    {activeDropDown===index ? <KeyboardArrowUpIcon className="icons ml-auto"/> : <KeyboardArrowDownIcon className="icons ml-auto"/>}
                                            </ListItemButton>
                                    </ListItem>
                                    <DropDownList ListData={SingleMenu.childs} isActive={activeDropDown===index} HandleLink={HandleLink}/>
                            </>
                             : 
                            <ListItem key={index} disablePadding >
                                {/* <Link to={SingleMenu.link} className="link-tag"  onClick={()=>toggleDrawer('right', false)} > */}
                                    <ListItemButton onClick={()=>HandleLink(SingleMenu.link)}  
                                    className="link-tag"
                                    >
                                        <ListItemIcon className="icons" >
                                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                        </ListItemIcon>    
                                        <ListItemText primary={SingleMenu.title} />
                                    </ListItemButton>
                                {/* </Link> */}
                            </ListItem>
                             }


                        </>
                    ))}




                            <ListItem key={'swtich-to-old-site'} disablePadding >
                              <a href="https://tplus.in/" target="_blank" className="link-tag"  >

                              
                                    <ListItemButton   
                                    className="link-tag"
                                    >
                                        <ListItemIcon className="icons" >
                                             <MailIcon />
                                        </ListItemIcon>    
                                        <ListItemText primary={'Swtich to old Site'} />
                                    </ListItemButton>
                                    </a>
                            </ListItem>
                </List>
            </Box>
        </Drawer>
    </div>

      {/* {['left', 'right', 'top', 'bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))} */}
    </>
  );
  
}
