import React from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';

export default function DropDownList({isActive,ListData,HandleLink}) {

    if(!isActive){
        return "";
    }
  return (
    <>
    <List sx={{paddingLeft:'57px'}}>

    {ListData.map((Item,index)=>(
        <ListItem key={index} disablePadding>
            {/* <Link to={Item.link} className="link-tag" > */}
                
            {
                (Item.link==='mailto:info@tplus.in' || Item.link==='info@tplus.in' || Item.link==='#') ? 
                <ListItemButton className="link-tag">
                    <a href={Item.link} className="text-white link-tag " > 
                        {Item.title}
                    </a>    
                </ListItemButton>
                : 
                <ListItemButton onClick={()=>HandleLink(Item.link)} className="link-tag">    
                    <ListItemText primary={Item.title} />
                </ListItemButton>
            }
            {/* </Link> */}
        </ListItem>
    ))}

    </List>
    </>
  )
}
