import React, { useEffect } from 'react';
// import { PDFExport,savePDF } from "@progress/kendo-react-pdf";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import AccountDescription from './AccountDescription';
import BillDescription from './BillDescription';
import SingleInvoice from './invoice/SingleInvoice';
const a = (a,b)=>{

}

const b = ()=>{
    a("asas")
    c()
}

const c = ()=>{
}

function DownloadInvoice({ fileName, setinvoice, downloadList, selectbox, isloading,setDownloadFileName,setDownloadList }) {
    const pdfExportComponent = React.useRef(null);
    useEffect(() => {
        if (fileName == selectbox[selectbox.length - 1]) {
            isloading(false);
        }
        let element = pdfExportComponent.current;
        if (fileName && element) {

            
            // setDownloadFileName("")
            // setDownloadList("")    
            if (element) {
                element.save()
              }

              setTimeout(()=>{
                        setDownloadFileName("")
                        setDownloadList("")            
                    },
                1000);
        //     savePDF(element, {
        //         paperSize: "A4",
        //         margin:'1',
        //         fileName: fileName,
        //         forcePageBreak:".break"
        //     }   ,()=>{
        //         setDownloadFileName("")
        //         setDownloadList("")            
        //     });
        // }
            }
    }, [fileName])
    return (
        <>
            <div >
                {/* <PDFExport
        keepTogether="p"
        margin="2cm"
        paperSize="auto"
        fileName={fileName}
        ref={pdfExportComponent}
        > */}
                {fileName &&<AccountDescription setinvoice={setinvoice} BillInfo={downloadList?.Data || downloadList} isDowbload={true} />}
                {/* </PDFExport> */}
            </div>
            <PDFExport
// forcePageBreak=".page-break"
fileName={fileName + "invoice"}
paperSize="A4"
title="invoice-pdf"
margin={2}
ref={pdfExportComponent}
scale={0.8}
forcePageBreak=".breack"
>
            <div  ref={pdfExportComponent} className="none">
         
   
                {fileName &&<SingleInvoice setinvoice={setinvoice} BillInfo={downloadList?.Data || downloadList} isDowbload={true} />}
               
            </div> </PDFExport>
        </>
    )
}

export default DownloadInvoice