import React from 'react'

function DerivativesSegment() {
    return (
        <>
            <section className="text-gray-600 body-font bg-[#F4FAFF]">
                <div className="container lg:pl-[3rem] md:pl-[3rem] pl-0  lg:pr-[1.5rem] md:pr-[1.5rem] pr-0 py-6 mx-auto">
                    <div className='title-box text-center'>
                        <h2 className='title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl mt-3 mb-10 text-[#0A2A4A]'>Derivatives Segment</h2>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="p-6 lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-left relative ">
                                <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute lg:-left-5 md:-left-5 lg:top-1 md:top-1 lg:mr-4 md:mr-4 mr-0 text-opacity-20 text-[#3491FF]">01</span>
                                <div className="flex-grow text-center p-6">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Separate brokerage for Future / Option / American / Euro / Buy / Sell.</p>
                                </div>
                            </div>
                       </div>
                        <div className="p- lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-center relative">
                            <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute lg:bottom-[3.8rem] md:bottom-[3.8rem] bottom-[6rem] lg:mr-4 md:mr-4 mr-0 lg:left-0 md:left-0 left-[23px] text-opacity-20 text-[#3491FF]">02</span>
                                <div className="flex-grow text-center p-12">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Brokerage on Exercise/Assignment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p- lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-center relative">
                            <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute top-7 lg:mr-4 md:mr-4 mr-0 lg:-left-0 md:-left-0 left-[22px] text-opacity-20 text-[#3491FF]">03</span>
                                <div className="flex-grow text-center p-12">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Same day/Any day squaring off brokerages and option brokerage at minimum per lot.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-6 lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-center relative">
                            <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute  top-1 lg:mr-4 md:mr-4 mr-0 lg:-left-5 md:-left-5 left-0 text-opacity-20 text-[#3491FF]">04</span>
                                <div className="flex-grow text-center p-6">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Collateral /Margin Management. Both cash (with accounting effect) and share collaterals.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p- lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-center relative">
                            <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute top-7 lg:mr-4 md:mr-4 mr-0 lg:-left-0 md:-left-0 left-[23px] text-opacity-20 text-[#3491FF]">05</span>
                                <div className="flex-grow text-center p-12">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Span Interface including facility to calculate span for each arbitrage account.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p- lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-center relative">
                            <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute top-7 lg:mr-4 md:mr-4 mr-0 lg:-left-0 md:-left-0 left-[23px] text-opacity-20 text-[#3491FF]">06</span>
                                <div className="flex-grow text-center p-12">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Facility to generate reverse margin file to be provided to NSE.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-6 lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-center relative">
                            <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute  top-1 lg:-left-4 md:-left-4 left-0 lg:mr-4 md:mr-4 mr-0 text-opacity-20 text-[#3491FF]">07</span>
                                <div className="flex-grow text-center p-6">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Profit/Loss report MTM, Premium P/L, In-out Money P/L.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p- lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-center relative">
                            <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute top-7 lg:mr-4 md:mr-4 mr-0 lg:-left-0 md:-left-0 left-[23px] text-opacity-20 text-[#3491FF]">08</span>
                                <div className="flex-grow text-center p-12">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Facility to debit / credit margin to bill of client or to debit / credit the same to Margin account of client.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p- lg:w-1/3 md:w-1/2 w-full">
                            <div className=" flex items-center relative">
                            <span alt="team" className="text-[25px] lg:text-5xl font-bold sm:text-3xl md:text-3xl absolute top-7 lg:mr-4 md:mr-4 mr-0 lg:-left-0 md:-left-0 left-[23px] text-opacity-20 text-[#3491FF]">09</span>
                                <div className="flex-grow text-center p-12">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[16px] text-left justify-center font-medium">Margin Statement showing Bill values, Receipts payments and Collateral valuation and Open position and underlying wise Span margin.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DerivativesSegment;