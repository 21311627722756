import React, { useEffect, useState } from "react";
import Axios from "axios";
import CloseIcon from '@mui/icons-material/Close';

const Downloader = ({ files = [], remove }) => {
  return (
    <div className="downloader">
      <div className="card">
        <div className="card-header ml-2 mt-2">Download List</div>
        <ul className="list-group list-group-flush">
          {/* {JSON.stringify(files)} */}
          {files.map((file, idx) => (<div className="flex flex-row">
            <DownloadItem
              key={idx}
              removeFile={() => {
                remove(file.downloadId)}}
              {...file}
            />
          </div>
          ))}
        </ul>
      </div>
    </div>
  );
};
const DownloadItem = ({ name, file, filename, removeFile }) => {
  const [downloadInfo, setDownloadInfo] = useState({
    progress: 0,
    completed: false,
    total: 0,
    loaded: 0,
  });
  useEffect(() => {
    const options = {
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        setDownloadInfo({
          progress: Math.floor((loaded * 100) / total),
          loaded,
          total,
          completed: false,
        });
      },
    };
    Axios.get(file, {
      responseType: "blob",
      ...options,
    }).then(function (response) {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: response.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      setDownloadInfo((info) => ({
        ...info,
        completed: true,
      }));
      setTimeout(() => {
        removeFile();
      }, 4000);
    });
  }, []);
  const formatBytes = (bytes) => `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-12 d-flex">
          <div className="d-inline font-weight-bold text-truncate text-[16px] ml-1 ">{name}</div>
          <div className="d-inline ml-1 lg:pt-0 md:pt-0 pt-2">
            <small>
              {downloadInfo.loaded > 0 && (
                <>
                  <span className="text-success">
                    {formatBytes(downloadInfo.loaded)}
                  </span>
                  / {formatBytes(downloadInfo.total)}
                </>
              )}
              {downloadInfo.loaded === 0 && <>Initializing...</>}
            </small>
          </div>
          <div className="d-inline ml-1">
            {downloadInfo.completed && (
              <span className="text-success text-[#15DD4D] flex d-inline">
                COMPLETED &nbsp; <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.45922 16.6667C3.78719 16.6667 0 12.9358 0 8.33333C0 3.73083 3.78719 0 8.45922 0C13.1312 0 16.9184 3.73083 16.9184 8.33333C16.9184 12.9358 13.1312 16.6667 8.45922 16.6667ZM8.45922 15C10.254 15 11.9753 14.2976 13.2445 13.0474C14.5136 11.7971 15.2266 10.1014 15.2266 8.33333C15.2266 6.56522 14.5136 4.86953 13.2445 3.61929C11.9753 2.36905 10.254 1.66667 8.45922 1.66667C6.6644 1.66667 4.94309 2.36905 3.67396 3.61929C2.40483 4.86953 1.69184 6.56522 1.69184 8.33333C1.69184 10.1014 2.40483 11.7971 3.67396 13.0474C4.94309 14.2976 6.6644 15 8.45922 15V15ZM7.61583 11.6667L4.02659 8.13083L5.22272 6.9525L7.61583 9.31L12.4004 4.59583L13.5973 5.77417L7.61583 11.6667Z" fill="#15DD4D" />
                </svg>
              </span>
            )}
          </div>
        </div>
        <div className="col-12 mt-1 flex items-center">
          <div className="lg:w-[250px] md:w-[250px] w-[200px] bg-gray-200 rounded-full h-2.5  dark:bg-gray-700">
            <div
              className={`${downloadInfo.completed ? 'bg-green-600' : 'bg-blue-600'}  h-2.5 rounded-full dark:bg-blue-500 `}
              style={{ width: downloadInfo.progress + "%" }}
            />
          </div>   
          {/* <CloseIcon className="cursor-pointer" onClick={removeFile} /> */}
        </div>
      </div>
    </li>
  );
};

export default Downloader;