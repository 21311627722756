import React from 'react'

function StationeryManagement() {
    return (
        <>
            <div className=' text-center'>
            <h2 className='title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-10 pb-0 text-[#0A2A4A] text-center'>Stationery Management</h2>
            </div>
            <section className="text-gray-600 body-font">
                <div className="md:container md:mx-auto px-5 py-8 mb-12  flex flex-wrap pl-10 pr-10">
                    <div className="flex flex-wrap -m-4 ">
                        <div className="p-4 xl:w-1/2 lg:w-1/2 md:w-full lg:px-8 md:px-8 px-4 sm:border-r-2 sm:border-[#B3ABC4]">
                            <div className="flex  lg:p-8 md:p-8 p-0 sm:flex-row flex-col">
                                <div className="w-20 h-20 sm:mr-8 sm:mb-0 mt-0 inline-flex items-center justify-center rounded-full  text-indigo-500 flex-shrink-0">
                                    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle opacity="0.12" cx="80" cy="80" r="80" fill="#00C673" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M115.07 88.1111C115.07 87.8851 115.025 87.6591 114.939 87.4499C114.852 87.2408 114.724 87.0491 114.564 86.8894C114.404 86.7297 114.212 86.6014 114.003 86.5152C113.794 86.4284 113.568 86.3838 113.342 86.3838H74.2461V79.6272C74.2461 79.3258 74.1648 79.0245 74.0147 78.7629C73.864 78.502 73.6435 78.2814 73.3819 78.1307C73.1209 77.98 72.8196 77.8993 72.5182 77.8993C72.2169 77.8993 71.9156 77.98 71.654 78.1307L57.2855 86.4254L42.9086 94.7284C42.6476 94.8791 42.427 95.0997 42.2764 95.3613C42.1257 95.6228 42.0449 95.9236 42.0449 96.2255C42.0449 96.5268 42.1263 96.8282 42.2764 97.0898C42.427 97.3507 42.6476 97.5713 42.9092 97.722L57.2849 106.015V106.016L71.6521 114.318C71.9131 114.469 72.2144 114.55 72.5164 114.55C72.8177 114.55 73.1191 114.47 73.3806 114.318C73.6422 114.168 73.8628 113.947 74.0134 113.686C74.1641 113.425 74.2449 113.124 74.2449 112.822V106.065H113.341C113.567 106.065 113.793 106.02 114.002 105.934C114.211 105.847 114.403 105.719 114.563 105.56C114.722 105.399 114.851 105.208 114.937 104.999C115.024 104.79 115.069 104.564 115.069 104.338V88.1119L115.07 88.1111Z" fill="#00C673" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M118.063 63.8265C118.077 63.5119 118.025 63.2118 117.874 62.9358C117.723 62.6597 117.498 62.4187 117.226 62.2608L102.835 53.9451L88.4707 45.6558C88.2091 45.5051 87.9084 45.44 87.6064 45.44C87.3051 45.44 87.0038 45.5051 86.7422 45.6558C86.4812 45.8064 86.2721 46.0427 86.1214 46.3036C85.9708 46.5646 85.8786 46.8659 85.8786 47.1679V53.9179H46.7826C46.5566 53.9179 46.3167 53.9661 46.1076 54.0529C45.8984 54.1397 45.7279 54.2711 45.5676 54.4308C45.4078 54.5905 45.2759 54.7616 45.1897 54.9708C45.1029 55.1799 45.0547 55.4198 45.0547 55.6458V71.8735C45.0547 72.0995 45.1029 72.3129 45.1897 72.5214C45.2765 72.7305 45.4079 72.9282 45.5676 73.0885C45.7279 73.2482 45.8984 73.3802 46.1076 73.4664C46.3167 73.5532 46.5566 73.6014 46.7826 73.6014H85.8786V80.3514C85.8786 80.6527 85.9708 80.9541 86.1214 81.2156C86.2721 81.4766 86.4812 81.6857 86.7422 81.8364C87.0032 81.9871 87.3045 82.0793 87.6064 82.0793C87.9078 82.0793 88.2091 81.9871 88.4707 81.8364L102.835 73.5471L117.226 65.2578C117.475 65.1137 117.67 64.91 117.82 64.6635C117.969 64.4176 118.05 64.1145 118.063 63.8264V63.8265ZM112.906 63.7457L101.106 70.5501L89.3342 77.3544V71.8737C89.3342 71.6477 89.286 71.4078 89.1992 71.1987C89.1124 70.9895 88.981 70.819 88.8213 70.6587C88.6616 70.499 88.4904 70.367 88.2813 70.2808C88.0722 70.194 87.8323 70.1458 87.6063 70.1458H48.5103V57.3751H87.6063C87.8323 57.3751 88.0722 57.3269 88.2813 57.2401C88.4904 57.1533 88.6616 57.0219 88.8213 56.8622C88.981 56.7025 89.113 56.5042 89.1992 56.2951C89.286 56.086 89.3342 55.8732 89.3342 55.6472V50.1394L101.106 56.9437L112.906 63.748V63.7457Z" fill="#99E8C7" />
                                    </svg>
                                </div>
                                <div className="flex-grow">
                                  <p className="leading-relaxed  text-[17px] text-[#808D9A] pt-4">This enables to track lot of information from transfer instruction serial No and allow monitoring of stationery stock of various kinds.</p>
                                </div>
                            </div>  
                        </div>
                        <div className="p-4 xl:w-1/2 lg:w-1/2 md:w-full lg:px-8 md:px-8 px-4">
                            <div className="flex  lg:p-6 md:p-8 p-0 sm:flex-row flex-col mt-4">
                                <div className="w-20 h-20 sm:mr-8 sm:mb-0 mt-0 inline-flex items-center justify-center rounded-full text-indigo-500 flex-shrink-0">
                                    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle opacity="0.12" cx="80" cy="80" r="80" fill="#00C673" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M52.3572 113.198C52.509 113.324 52.6615 113.449 52.8158 113.574C63.7267 122.41 77.6373 125.056 90.2995 121.959L90.3116 119.931L90.3477 113.803C79.5629 117.093 67.3977 115.174 57.94 107.616L107.623 57.934C117.782 70.6228 118.121 89.0966 107.472 102.245L98.3159 94.8307L98.167 119.976L122.732 114.602L113.576 107.187C126.782 90.8792 126.161 67.8722 113.205 52.3495L121.657 43.8982L116.103 38.3445L38.3475 116.1L43.9012 121.654L52.3572 113.198ZM42.2726 101.066C33.9254 86.1034 34.9874 66.9442 46.4298 52.8147L37.2733 45.3999L61.8386 40.0258L61.6892 65.1713L52.5333 57.7565C43.6739 68.6975 42.4203 83.3232 48.1 95.2371L42.2726 101.066ZM101.063 42.2755C91.3353 36.8296 80.0988 35.5014 69.7046 38.044L69.6925 40.0726L69.6558 46.2C77.9878 43.6585 87.1425 44.2256 95.2379 48.0996L101.063 42.2755Z" fill="#00C673" />
                                        <line x1="119.182" y1="41.782" x2="40.882" y2="120.082" stroke="#99E8C7" stroke-width="9" />
                                    </svg >
                                </div>
                                <div className="flex-grow">
                                    <p className="leading-relaxed  text-[17px] text-[#808D9A] pt-4">Prevent transfer on instruction slips reported missing by clients using stop transfers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default StationeryManagement