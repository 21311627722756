import React from "react";

import Button from "@mui/material/Button";
import Disclaimer from "./Disclaimer";
import PrivacyPolicy from "./PrivacyPolicy";

function Footer() {
  const [Modulopen, setModulopen] = React.useState(false);
const handleModulopen = () => {
  setModulopen(true);
};

const [open, setOpen] = React.useState(false);
const handleOpen = () => {
  setOpen(true);
};
  return (
    <>
      <footer className="text-gray-600 body-font bg-[#0A2A4A] md:px-10 mt-5  ">
        <div className="container   pt-12  mx-auto">
          <div className="flex flex-wrap ">
            <div className=" lg:w-1/4 w-full px-4">
              <h2 className="title-font">
                <img onClick={() => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                  className="fill-current object-cover  w-[10.5rem] mb-6"
                  src="/assets/img/logo.svg"
                />
              </h2>
              <nav className="list-none md:mb-10">
                <li>
                  <div className="text-[#ffff] lg:text-left md:text-left text-center font-medium text-[13px] mt-3 lg:text-[14px] md:text-[14px]">
                    SecMark Consultancy Limited.
                  </div>
                </li>
                <li className="mt-5">
                  <div className="text-[#FFFFFF] lg:text-left md:text-left text-center font-small lg:text-[14px] md:text-[14px] text-[1p3x]">
                    36 / 227, RDP 10, Sector VI, Charkop, Kandivali(W), Mumbai,
                    Maharashtra - 400 067, India.
                  </div>
                </li>
              </nav>
            </div>
            <div className="md:p-4 lg:w-1/2 w-full  mt-10 text-center ">
              <div className="title-box ">
                <h2 className="title text-[16px] font-bold  md:text-[17px] mt-3 text-[#00C673] text-center justify-center">
                  Technology For Commerce
                </h2>
              </div>
              <nav class="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center mt-3">
                <span class="mr-3 text-[#FFFFFF] text-[13px] font-small  md:text-[16px] border-r-2 sm:border-[#fff] pr-3 font-light flex align-center items-center ">
                  <img src="/assets/icons/lion.svg" className="w-14 mr-2" />
                  <span>Make in India</span>
                </span>
                <a href="https://www.secmark.in/" className="text-[#FFFFFF] text-[13px] font-small  md:text-[16px]  font-light underline">
                  Powered by SecMark Consultancy Limited
                </a>
              </nav>
            </div>
            <div className="p-4 lg:w-1/4 w-full px-10 ">
              <h2 className="title-font font-medium text-[#3491FF] tracking-widest text-lg mb-5 border-l-[1px] sm:border-[#fff] align-left text-left pl-2">
                Contact us
              </h2>
              <div className="flex-col-reverse block xl:flex-wrap md:flex-wrap lg:flex-wrap flex-wrap justify-center text-center">
                <div className="lg:mt-2 md:w-60 w-full items-end xl:mt-0 flex-shrink-0 inline-flex text-white bg-[#123961]  border-0 py-2 md:px-4 rounded">
                  <img
                    className="fill-current px-3 "
                    src={require("../Images/Calling.png")}
                  />
                  <div className="">+91 9920189071</div>
                </div>
              </div>
              <a href="mailto:info@secmark.in" className="flex-col-reverse block xl:flex-wrap md:flex-wrap lg:flex-wrap flex-wrap justify-center text-center  mt-3">
                <button className="lg:mt-2 md:w-60  w-full xl:mt-0 flex-shrink-0 items-center inline-flex text-white bg-[#123961]  border-0 py-2 md:px-6 focus:outline-none hover:bg-indigo-600 rounded">
                  <img
                    className="fill-current px-2"
                    src={require("../Images/Message.png")}
                  />
                  <a href="mailto:info@tplus.in" rel="noopener noreferrer" className="text-white hover:text-white">
                  info@tplus.in
                  </a>
                </button>
              </a>
            </div>
          </div>
          <div className="bg-[#0A2A4A] border-t-[0.5px] border-[#FFFFFF]  w-full ">
            <div className="flex  justify-between align-middle items-center px-4" >  
              <p className="text-[#fff] text-sm md:text-[16px] text-center sm:text-left flex items-baseline 
                text-[12px] m-0
              ">
                2022 Copyright:
                <span className="flex">
                <a
                    href="mailto:info@tplus.in"
                    className="text-[#3491FF] block px-1 py-5 text-sm text-end  cursor-pointer underline"
                    role="menuitem"
                    id="menu-item-0"
                >
                 info@tplus.in
                </a>
                </span>
              </p>
              <nav className="list-none flex-row ">
                  <li className="inline pr-2">
                    <a className="text-[#ffffff] font-small md:text-[16px] text-[12px]" onClick={handleOpen}> Disclaimer</a>
                  </li>
                  <li className="inline">
                    <a className="text-[#ffffff] font-small md:text-[16px] text-[12px]" onClick={handleModulopen}>  Privacy Policy.</a>
                  </li>
                </nav>
            </div>
            {/* <div className=" mx-auto py-2 flex flex-wrap flex-col sm:flex-row justify-between px-4">
              <p className="text-[#fff] text-sm text-center sm:text-left flex items-baseline">
                2022 Copyright:
                <span className="flex">
                <a
                    href="mailto:info@tplus.in"
                    className="text-[#3491FF] block px-1 py-2 text-sm text-end  cursor-pointer underline"
                    role="menuitem"
                    id="menu-item-0"
                >
                 info@tplus.in
                </a>
                </span>
              </p>
              <div className="flex justify-between flex-wrap pl-3 space-x-4 sm:space-x-8">
                <nav className="list-none flex-row ">
                  <li className="inline pr-2">
                    <a className="text-[#ffffff] font-small text-md" onClick={handleOpen}> Disclaimer</a>
                  </li>
                  <li className="inline">
                    <a className="text-[#ffffff] font-small text-md" onClick={handleModulopen}>  Privacy Policy.</a>
                  </li>
                </nav>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
      {/* <div class="fixed animate-bounce bottom-10 right-10    border-spacing-1 border-white-200 rounded-full  ">
        <a href="#!">
          <img
            src={"/assets/icons/download.svg"}
            alt="download-icon"
            className="w-20"
          />
        </a>
      </div> */}
      <Disclaimer open={open} setOpen={setOpen} />
      <PrivacyPolicy Modulopen={Modulopen} setModulopen={setModulopen}/>
    </>
  );
}

export default Footer;