import React from "react";
import './clientmaster.scss';

const leftContent = [
    { num: '01', title: ' Grouping by Group / Family / Branch.' },
    { num: '02', title: 'Client code generation facility to find next code available for a given alphabet.' },
];
const rightContent = [
    { num: '03', title: 'Clients can provide their e-mail address where bills / contracts / ledgers / positions / statements etc. may be emailed.' },
    { num: '04', title: 'Provision to Freeze / Unfreeze clients.' },
]


const ClientMaster = () => {
    return (
        <>
            <section id="timeline-section" className="bg-[#F4FAFF]">
                <div className="container flex flex-wrap mx-auto ">
                    <div className='title-box text-center w-full px-20 py-9'>
                        <h2 className=' text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-8 pb-4 text-[#0A2A4A]'>Client Master</h2>
                    </div>
                    <div className="grid lg:grid-cols-2 grid-cols-1 w-full md:px-10 lg:px-10 py-5 ">
                        <div className="timeline w:1/2">
                            {leftContent.map((singleLeft) => (
                                <div className="outer">
                                    <div className="card">
                                        <div className="info">
                                            <div className="title-section">
                                                <h3 className="title">{singleLeft.num}</h3>
                                            </div>
                                            <div className="desc">
                                                <h3 className="text-[16px] text-gray-500">{singleLeft.title}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="right-timeline w:1/2">
                            {rightContent.map((singleRight) => (
                                <div className="outer">
                                    <div className="card">
                                        <div className="info">
                                            <div className="desc">
                                                <h3 className="text-[16px] text-gray-500">{singleRight.title}</h3>
                                            </div>
                                            <div className="title-section">
                                                <h3 className="title">{singleRight.num}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ClientMaster;