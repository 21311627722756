import React, { useRef, useState } from "react";
import { PostMethod } from "../../../hooks/Service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Password = (props) => {
  const tabsRef = useRef();
  const [password, setpassword] = useState("");
  const [fillpass, setfillpass] = useState(false);
  const handleSeeCompleteList = () => {
    const tabs = tabsRef.current;
    if (tabs) {
      tabs.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const [passwordhint, setPasswordHint] = useState('');
  const ValidatePasswordWithAPI = async () => {
    let sendData = { "pwd": password }
    let resultAPI = await PostMethod('Tplus/ValidatePassword', sendData);
    if (resultAPI.result.Status) {
      if (resultAPI.result.Data.Valid === "Y") {
        return true;
      } else {
        setPasswordHint(resultAPI.result.Data.Hint)
        toast.error("invalid Password", {
          progress: undefined,
          autoClose: 5000,
        })
        return false;
      }
    } else {
      toast.error("invalid Password", {
        progress: undefined,
        autoClose: 5000,
      })
      return false;
    }
  }
  const CheckPassword = async () => {
    if (validate(password)) {
      setfillpass(validate(password));
    } else {
      let checkPwd = await ValidatePasswordWithAPI(password)
      if (checkPwd) {
        props.setcurrentTab(true);
        var now = new Date();
        now.setTime(now.getTime() + 2 * 60 * 60 * 1000);
        let cookievalue = escape(password) + ";"
        document.cookie = "password=" + cookievalue + ";" + "expires=" + now.toUTCString() + ";"
      }
    }
  }
  const validate = (values) => {
    let errors;
    if (!values) {
      errors = "Cannot be blank";
    } else if (values.length < 4) {
      errors = "Password must be more than 4 characters";
    }
    return errors;
  };
  return (
    <>
      <div className="border back-forword-btn lg:w-[40%] w-[84%] mx-auto rounded-2xl px-6 py-6 ">
        <div className="font-medium lg:text-lg md:text-lg text-[14px] text-center  py-4 text-[#0A2A4A]">
          To access your account enter password & Proceed
        </div>
        <div className="relative z-0 w-full mb-2 group">
          <input
            type="password"
            name="password"
            className="block py-2.5 px-0 w-full lg:text-[17px] md:text-[17px] text-[15px] text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            placeholder=" "
            required=""
            onChange={(e) => {
              setpassword(e.target.value)
            }}
          />
          <label
            htmlFor="password"
            className="peer-focus:font-medium absolute lg:text-[18px] md:text-[18px] text-[15px] font-medium text-[#0A2A4A] duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
          >
            Password
          </label>
        </div>
        {fillpass && <div className="text-red-500">{fillpass}</div>}
        {passwordhint && <div className="text-red-500 ">Password Hint  <span className="font-bold">{passwordhint}</span></div>}
        <div
          className=" font-medium underline text-[#3491FF] lg:text-[18px] md:text-[18px] text-[15px] text-end ml-12 my-4 cursor-pointer"
          onClick={() => { props.setcurrentTab("changepassword") }}
        >
          Change Password
        </div>
        <div className=" text-center  my-4">
          <button
            onClick={() => { CheckPassword() }}
            type="submit"
            className="text-white lg:text-lg md:text-lg text-[14px]  bg-[#3491FF] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg  lg:w-full md:w-full w-auto sm:w-auto px-6 py-2 text-center"
          >
            {" "}
            Proceed
          </button>
        </div>
      </div>
      <div ref={tabsRef} className=" text-center px-5 py-5 align-center">
        <h2 className=" lg:text-[18px] md:text-[18px] text-[15px]  font-semibold  pt-8 text-[#3D3D3D]">
        If you {" "}
          <strong className="underline text-[#3491FF] lg:text-[18px] md:text-[18px] text-[15px] font-medium">
            Do Not Remember Password, 
          </strong>
           We will send emails to 5 recently used email IDs from your organization in sometime.
        </h2>
      </div>
    </>
  );
};

export default Password;
