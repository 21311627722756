import React from 'react'
import MyQueryTable from './MyQueryTable'

const MyQuery = (props) => {
    
  return (
    <div>
    <div className='lg:px-12 px-3 pt-2 pb-8'>
        <div>
            <MyQueryTable setcheck={props.setcheck}/>
        </div>
    </div>
    </div>
  )
}

export default MyQuery