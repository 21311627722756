import React from 'react'

const BillingTable = ({BillInfo}) => {
  console.log("🚀 ~ file: BillingTable.js:4 ~ BillingTable ~ BillInfo", BillInfo)
  return (
    <table className='w-[100%]'>
        <thead className='ring-0 bg-[#3491FF] text-white'> 
                <th className='w-[55%] rounded-tl-2xl px-6 py-3 text-lg font-medium text-left font-bold'>Description</th>
                <th className='w-[30%]'></th>
                <th className="w-[15%] rounded-tr-2xl px-[10px] py-3  text-lg font-medium text-right font-bold">Rupees</th>  
        </thead>
        <tbody className=''>
            <tr className='border border-bottom-style'>
                <th className='w-[55%] text-left 2xl:px-7 xl:px-7 lg:px-7 md:px-7 sm:px-7 px-1 py-2 underline text-[#3D3D3D] text-bold text-base font-bold border-l' dangerouslySetInnerHTML={{__html: BillInfo.Description}}></th>
                <th className='w-[30%] border-l'></th>
                <th className='w-[15%] text-right py-2 text-[#3D3D3D] mr-4 border-l align-baseline'><span className='mr-4'>{BillInfo.Amount}</span></th>
            </tr>
            <tr className='border border-top-style'>
                <th className='w-[55%] text-left px-7 py-2 text-[#3D3D3D] text-bold text-base font-bold border-l'>Your GST ID:<span className='mr-4'>{BillInfo.YourGSTID}</span></th>
                <th className='border-l'></th>
                <th className='border-l'></th>
            </tr>
            {BillInfo?.SGST ?<tr className="border">
              <th  className="w-[55%] border-r justify-center"></th>
              <th className="w-[30%] text-right border-r py-[15px]"><span className='2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4'>SGST Collected @ 9%</span></th>
              <th className="w-[15%] border-r text-right mr-4 py-[15px]"><span className='2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4'>{BillInfo.SGST}</span></th>
            </tr>:""}
            {BillInfo?.CGST ?<tr className="border">
              <th  className="w-[55%] border-r justify-center"></th>
              <th className="w-[30%] text-right border-r py-[15px]"><span className='2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4'>CGST Collected @ 9%</span></th>
              <th className="w-[15%] border-r text-right mr-4 py-[15px]"><span className='2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4'>{BillInfo.CGST}</span></th>
            </tr>:""}
            {BillInfo?.IGST ?<tr className="border">
              <th  className="w-[55%] border-r justify-center"></th>
              <th className="w-[30%] text-right border-r py-[15px]"><span className='2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4'>IGST Collected @ 18%</span></th>
              <th className="w-[15%] border-r text-right mr-4 py-[15px]"><span className='2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4'>{BillInfo.IGST}</span></th>
            </tr>:""}
            {BillInfo?.UGST ?<tr className="border">
            <th  className="w-[55%] border-r justify-center"></th>
              <th className="w-[30%] text-right border-r py-[15px]"><span className='2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4'>UGST Collected @ 18%</span></th>
              <th className="w-[15%] border-r text-right mr-4 py-[15px]"><span className='2xl:mr-4 xl:mr-4 lg:mr-4 md:mr-4 sm:mr-4'>{BillInfo.UGST}</span></th>
            </tr>:""}
            <tr className='border'>
                <th className='w-[55%] text-left px-7 py-2 text-[#3D3D3D] text-bold text-base font-bold'>{BillInfo.TotalInWords}</th>
                <th className='w-[30%] border-l'></th>
                <th className='w-[15%] border-l text-right mr-4'><span className='mr-4'>{BillInfo.Total}</span></th>
            </tr>
        </tbody>
    </table>
    // <div>
    //     <div className='flex justify-between ring-0	bg-[#3491FF] rounded-tl-2xl rounded-tr-2xl text-white'>
    //         <div className="px-6 py-3 text-lg font-medium text-left font-bold">Description</div>
    //         <div className="px-[10px] py-3  text-lg font-medium text-right font-bold">Rupees</div>
    //     </div>
    //     <div className='flex justify-between border	h-full'>

    //         <div className='w-3/5'>
    //             <div className='text-left px-7 py-2 underline text-[#3D3D3D] text-bold text-base font-bold' dangerouslySetInnerHTML={{__html: BillInfo.Description}}></div>
    //             <div className='text-left px-7'>Your GST ID: <span className='font-bold'>{BillInfo.YourGSTID}</span></div>
    //         </div>  
    //         <div className='border-l w-1/5 text-center py-2'></div>
    //         <div className='border-l w-1/5 text-right py-2 text-[#3D3D3D] mr-4'>{BillInfo.Amount}</div>
    //     </div>

    //     <div className='flex justify-between 	h-20'>
    //         <div className='w-3/5 text-left px-7 py-10'></div>
    //         <div className='border-l w-1/5 text-right py-3.5  pl-5'>
    //             <div className='mr-4'>SGST Collected @ 9%</div>
    //             <div className='mr-4'>CGST Collected @ 9%</div>
    //             { BillInfo.IGST ? <div>IGST Collected @ 9%</div> : ""}
    //             { BillInfo.UGST ? <div>UGST Collected @ 9%</div> : ""}

    //         </div>
    //         <div className='border-l w-1/5 text-right py-3.5 mr-4'>
    //             <div>{BillInfo.SGST}</div>          
    //             <div>{BillInfo.CGST}</div>
    //             { BillInfo.IGST ? <div>{BillInfo.IGST}</div> : ""}
    //             { BillInfo.UGST ? <div>{BillInfo.UGST}</div> : ""}
                
    //         </div>
    //     </div>
    //     <div className='flex justify-between border	h-12'>
    //         <div className='w-3/5 text-left py-4 pl-6'>
    //             {BillInfo.TotalInWords}
    //         </div>
    //         <div className='border-l w-1/5'></div>
    //         <div className='border-l w-1/5 p-2 font-bold text-right text-lg mr-1'>{BillInfo.Total}</div>
    //     </div>
    // </div>
  )
}

export default BillingTable