import React, { useState, useEffect } from "react";
import Button from "devextreme-react/button";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import Paginate from "./Pagination";

const Table = ({ ContactPerson }) => {
  const [pageData, setPageData] = useState([]);
  useEffect(() => {
    pageChange(1);
  }, [ContactPerson]);
  const pageChange = (page) => {
    var start = (page - 1) * 10;
    var end = start + 10;
    let data = [...ContactPerson].slice(start, end);
    setPageData(data);
  };
  const onRowPre = (e) => {
    if (e.rowType == "header") {
      // e.rowElement.style.display = "none";
    }
    if (e.rowType == "data") {
      e.rowElement.style.margin = "15px";
      e.rowElement.style.fontStyle = "medium";
      e.rowElement.style.color = "#3D3D3D";
      e.rowElement.style.lineHeight = "35px";
      e.rowElement.style.fontWeight = 400;      
    }
    if (e.rowIndex % 2 === 0) {
      e.rowElement.style.backgroundColor = "#E1F1FF";
    }
  };
  return (
    <>
                <div className="relative overflow-x-auto shadow-lg sm:rounded-lg ">

      <React.Fragment>
       
        <DataGrid
          className=" py-4 rounded-b-2xl back-forword-btn font-medium text-lg"
          id="gridContainer"
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
          dataSource={pageData}
          keyExpr="EmailID"
          onRowPrepared={onRowPre}
          // width={1370}
          
        >
           {/* <GroupPanel visible={true} />
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Grouping autoExpandAll={false} /> */}
          {/* <Editing className="border-2 border-indigo-600"
                mode="row"
                allowUpdating={true}
                allowDeleting={true}
                useIcons={true}
                /> */}
               <Column
          dataField="Name"
          caption="Contact Person"
          dataType="string"
         alignment="left"
        />
        <Column
          dataField="EmailID"
          caption="Email ID"
          hidingPriority={1}
          dataType="string"
         alignment="left"
        />
        <Column
          dataField="MobileNo"
          caption="Mobile No"
          hidingPriority={0}
          dataType="string"
         alignment="left"
        />

          {/* <Column dataField="Name" caption="Contact Person" />
          <Column dataField="EmailID" />
          <Column dataField="MobileNo" /> */}
          {/* <Column dataField="Mobile" /> */}
          {/* <Column dataField="Designation" /> */}
        </DataGrid>
        <div className="m-3 mr-0">
          <Paginate pageChange={pageChange} length={ContactPerson.length} />
        </div>
      </React.Fragment>
      </div>
    </>
  );
};
export default Table;
