import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";
import SliderCard from "./SliderCard";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const TradePlusPopUp = ({ openModal, onclose, DownloadZipFile, checkObj }) => {
  console.log(
    "🚀 ~ file: TradePlusPopUp.js ~ line 22 ~ TradePlusPopUp ~ checkObj",
    checkObj
  );

  const [open, setOpen] = React.useState(openModal);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onclose(false);
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  return (
    <Modal
      className="w-100%"
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="flex justify-between mb-4">
          <div className="helpheading font-semibold text-2xl items-center text-blue-800 "></div>
          <div>
            <CloseIcon onClick={onclose} className="cursor-pointer" />
          </div>
        </div>
        {open == "Head Office" ? (
          <div className="">
            <div className="flex justify-center font-bold text-lg">{open}</div>
            <div className="flex flex-wrap grid grid-cols-4 justify-around">
              <SliderCard
                subTitle="TradeplusX EXE"
                img={"/assets/img/svg-1.svg"}
                arrow={true}
                download={"/assets/zip/TradeplusX.zip"}
                DownloadZipFile={DownloadZipFile}
                clickToDownload
                buttonTxt={checkObj?.TradePlus}
              />
              <SliderCard
                subTitle="Tradeplus EXE"
                img={"/assets/img/svg-1.svg"}
                arrow={true}
                download={"/assets/zip/Tradeplus.zip"}
                DownloadZipFile={DownloadZipFile}
                buttonTxt={checkObj?.TradePlus}
                clickToDownload
              />
              {/* <SliderCard
              subTitle="Uttility"
              img={"/assets/img/svg-1.svg"}
              arrow={true}
              download={"/assets/zip/tputils.zip"}
              DownloadZipFile={DownloadZipFile} clickToDownload
            />
            <SliderCard
              subTitle="DB Support"
              img={"/assets/img/svg-1.svg"}
              arrow={true}
              download={"/assets/zip/Tradeplusdb.zip"}
              DownloadZipFile={DownloadZipFile}
              buttonTxt={checkObj?.TradePlus} clickToDownload
            /> */}
            <SliderCard
              subTitle="E-Sign Pro"
              img={"/assets/img/svg-1.svg"}
              arrow={true}
              download={"/assets/zip/esignpro.zip"}
              DownloadZipFile={DownloadZipFile} clickToDownload
              buttonTxt={checkObj?.ESign}
            />
            </div>
          </div>
        ) : (
          ""
        )}
        {open == "Branch" ? (
          <div className="">
            <div className="flex justify-center font-bold text-lg">{open}</div>
            <div className="flex flex-wrap grid grid-cols-4 justify-around">
              <SliderCard
                subTitle="TradeNet"
                img={"/assets/img/svg-7.svg"}
                arrow={true}
                download={"/assets/zip/Tradenet.zip"}
                DownloadZipFile={DownloadZipFile}
                buttonTxt={checkObj?.TradeNet}
                clickToDownload
              />
              {/* <SliderCard
                subTitle="TradeWeb"
                img={"/assets/img/svg-7.svg"}
                arrow={true}
                download={"/assets/zip/Tradeweb.zip"}
                DownloadZipFile={DownloadZipFile}
                clickToDownload
                buttonTxt={checkObj?.Website}
              /> */}
              <SliderCard
                subTitle="TradeNetx"
                img={"/assets/img/svg-7.svg"}
                arrow={true}
                download={"/assets/zip/TradeNetX.zip"}
                DownloadZipFile={DownloadZipFile}
                buttonTxt={checkObj?.TradeNet}
                clickToDownload
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {open == "Individual Client" ? (
          <div className="">
            <div className="flex justify-center font-bold text-lg">{open}</div>
            <div className="flex flex-wrap grid grid-cols-4 justify-around">
              <SliderCard
                subTitle="TradeWeb"
                img={"/assets/img/svg-7.svg"}
                arrow={true}
                download={"/assets/zip/Tradeweb.zip"}
                DownloadZipFile={DownloadZipFile}
                clickToDownload
                buttonTxt={checkObj?.Website}
              />
              <SliderCard
                subTitle="TradeWeb X"
                img={"/assets/img/svg-7.svg"}
                arrow={true}
                download={"/assets/zip/Tradeweb.zip"}
                DownloadZipFile={DownloadZipFile}
                clickToDownload
                buttonTxt={checkObj?.Website}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        
        {open == "Knowledge Base" ? (
          <div className="">
            <div className="flex justify-center font-bold text-lg">{open}</div>
            <div className="flex flex-wrap grid grid-cols-4 justify-around">
              {" "}
              <SliderCard
                subTitle="Circulars"
                section="T"
                img={"/assets/img/svg-6.svg"}
                arrow={false}
                download={""}
                buttonTxt={checkObj?.TradePlus}
                clickToDownload
              />
              {console.log(
                "🚀 ~ file: TradePlusPopUp.js ~ line 131 ~ TradePlusPopUp ~ buttonTxt",
                checkObj?.TradePlus !== "Y"
              )}
              <SliderCard
                subTitle="Help"
                img={"/assets/img/svg-6.svg"}
                arrow={false}
                download={true}
                dtitle={"trad"}
                DownloadZipFile={DownloadZipFile}
                clickToDownload
                buttonTxt={checkObj?.TradePlus}

                // onClick={onOpen}
              />
              <SliderCard
                subTitle="Modifications"
                section="T"
                img={"/assets/img/svg-6.svg"}
                arrow={false}
                DownloadZipFile={DownloadZipFile}
                clickToDownload
                table
                download={""}
                buttonTxt={checkObj?.TradePlus}
              />
              <SliderCard
                subTitle="TradePlusX"
                img={"/assets/img/svg-6.svg"}
                arrow={false}
                download={true}
                dtitle={"Trade Plus-X"}
                DownloadZipFile={DownloadZipFile}
                clickToDownload
                buttonTxt={checkObj?.TradePlus}
              />
              <SliderCard
                subTitle="DB Support"
                img={"/assets/img/svg-1.svg"}
                arrow={false}
                download={"/assets/zip/Tradeplusdb.zip"}
                dtitle={"DB Support"}
                DownloadZipFile={DownloadZipFile}
                clickToDownload
                buttonTxt={checkObj?.TradePlus}
              />
              
            </div>
          </div>
        ) : (
          ""
        )}
      </Box>
    </Modal>
  );
};

export default TradePlusPopUp;
