import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
const dataList = [
  { link: "/trade-plus", title: "TradePlus", img: "/assets/img/product-1.svg", desc: "Back office package for stock brokers." },
  { link:"/trade-web", title:"TradeWeb",img:"/assets/img/product-5.svg",desc:"Back office data access utility by client linked through web"},

  { link: "/commex", title: "Commex", img: "/assets/img/product-4.svg", desc: "Back office software for commodities derivatives." },
  { link: "/cross", title: "Cross", img: "/assets/img/product-2.svg", desc: "Back office / CDSL co-ordination, billing and accounting software for DPs with CDSL" },

  { link: "/estro", title: "Estro", img: "/assets/img/product-3.svg", desc: "Back office / NSDL co-ordination, billing and accounting software for DPs with NSDL." },

  { link: "/e-sign", title: "Associated Modules", img: "/assets/img/product-6.svg", desc: "Web site Browser based online branches Offline branches with database and data transfer concept. Digitally signed documents (contracts, bills, ledgers etc.)" },
]
const Products = () => {
  useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
},[])
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className='title-box text-center px-5 py-9'>
          <h2 className='title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl text-[#0A2A4A]'>Products</h2>
        </div>
        <div className="container px-14 py-12 mx-auto pt-4">
          <div className="flex flex-wrap -m-4 justify-center">
            {dataList.map((item, index) => (
              <div className="px-4 md:w-1/3" key={index}>
                <Link to={item.link}>
                  <div className="lg:h-80 h-64 border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden mt-4">
                    <img className="lg:w-24 lg:h-24 w-16 place-self-center object-cover object-center mx-auto lg:mt-10 mt-6" src={item.img} alt="blog" />
                    <div className="p-6">
                      <h1 className="title-font lg:text-2xl md:text-2xl text- font-medium text-center text-[#3491FF] mb-3 underline">{item.title}</h1>
                      <p className=" mb-3 text-center text-[#808D9A] lg:text-[18px] text-[0.75rem]">{item.desc}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Products