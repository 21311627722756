import React from "react";
import "./css/ChargeBilling.scss";
const Image = require("./img/ChargeBillImg.png");

function ChargeBilling() {

  const DataList  = [
    {title:'Defining charges scheme applicable to different clients',key:1},
    {title:'Defining various charges on percentage / minimum basis',key:2},
    {title:'Including Demat request charges in bills for better accounting.',key:3},
    {title:'Levying additional charges for special tasks carried out for clients.',key:4},
    {title:' Printing bills on blank papers / letter heads / pre defined stationery on Dot Matrix Printer / Page printer in pre-set summarized / detailed mode.',key:5},
    {title:'Forwarding detailed bill to client via E-mail to who are provided summarized bills in print.',key:6},
    {title:'Verifying NSDL bill figure including late pay-in charges.',key:7},
  ];
  return (
    <section
      id="change-billng-section"
      className="bg-[#F4FAFF] min-h-[30rem]  "
    >
      <div className="container py-12 px-7  mx-auto">
        <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-5 pb-4 text-[#0A2A4A] relative text-center  ">
          Charges & Billing
        </h2>
        <div className="mobile-timeline" >
          <ul>
            {DataList.map((single)=>(
              <li>
                <p className="text-[#808D9A] text-[17px] pl-[30px]">{single.title}</p>
              </li>
              ))}
          </ul>
        </div>
        <div className="ps-timeline-sec h-[33rem] pt-[6rem] hidden md:block">
          <div className="container ">
            <img src={Image} className="absolute hidden md:block"  /> 
            <ol className="ps-timeline">
              <li>
                <div className="img-handler-bot pt-5">
                  <p className="text-[#808D9A] text-[17px] ">
                    Defining charges scheme applicable to different clients
                  </p>
                </div>
                <span className="ps-sp-bot">01</span>
              </li>
              <li>
                <div className="img-handler-top">
                  <p className="text-[#808D9A] text-[17px]">
                  Defining various charges on percentage / minimum basis
                  </p>
                </div>
                <span className="ps-sp-top">02</span>
              </li>
              <li>
                <div className="img-handler-bot pt-5">
                  <p className="text-[#808D9A] text-[17px]">
                  Including Demat request charges in bills for better accounting.
                  </p>
                </div>
                <span className="ps-sp-bot">03</span>
              </li>
              <li>
                <div className="img-handler-top">
                  <p className="text-[#808D9A] text-[17px]">
                  Levying additional charges for special tasks carried out for clients.
                  </p>
                </div>
                <div className="ps-bot"></div>
                <span className="ps-sp-top">04</span>
              </li>
              <li>
                <div className="img-handler-bot pt-5">
                  <p className="text-[#808D9A] text-[17px]">
                  Printing bills on blank papers / letter heads / pre defined stationery on Dot Matrix Printer / Page printer in pre-set summarized / detailed mode.
                  </p>
                </div>
                <span className="ps-sp-bot">05</span>
              </li>
              <li>
                <div className="img-handler-top ">
                  <p className="text-[#808D9A] text-[17px] p-12">
                  Forwarding detailed bill to client via E-mail to who are provided summarized bills in print.
                  </p>
                </div>
                <span className="ps-sp-top">06</span>
              </li>
              <li>
                <div className="img-handler-bot pt-5">
                  <p className="text-[#808D9A] text-[17px]">
                    Verifying NSDL bill figure including late pay-in charges.
                  </p>
                </div>
                <span className="ps-sp-bot">07</span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChargeBilling;
