import React from "react";

const ChargeList = [
    {title: "	On completion of settlement, billing related data like, trade price, quantity, settlement number, billing information including GST and Turnover Charges, if any, will be updated on to the web data."},
    {title: "	Since data is available across web, speed is a factor that needs to be given due consideration. We have worked out a special format for bill which saves on time and yet easier to understand for clients than traditional bills that are forwarded by brokers."},
    {title: "	Bills amount and delivery pay-in / pay-out details can be provided as soon as settlement is over and final Standard rate and margin data is available from the stock exchange."},
]

function Charges() {
  return (
    <>
      <section className="text-gray-600 body-font bg-[#F5FAFE]">
        <div className="title-box text-center px-5 py-9">
          <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-4 pb-0 text-[#0A2A4A] text-center">
            Bills / Reports
          </h2>
        </div>
        <div className="container px-12 mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className=" md:pr-8">
            {ChargeList.map((single,index)=>(
              <div className="flex relative pb-6" key={index}>
              <div className=" pl-3 pr-2 border-l-2 border-[#0A2A4A]">
                <span className="leading-relaxed text-[#0A2A4A] text-xl">
                  0{index+1}
                </span>
              </div>
              <div className="flex-shrink-0 w-[2rem] h-[2rem] inline-flex items-center justify-center text-white relative z-10 ">
                <svg
                  width="46"
                  height="50"
                  viewBox="0 0 46 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M46 25L2.38106e-06 50L8.84615 25L4.56663e-06 -2.01072e-06L46 25Z"
                    fill="#3491FF"
                  />
                </svg>
              </div>
              <div className=" pl-4">
                <p className="leading-relaxed pt-1 lg:text-[18px] md:text-[18px] text-[15px] font-medium text-[#808D9A]">
                  {single.title}
                </p>
              </div>
              </div>
            ))}

            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Charges;
