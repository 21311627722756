import React,{useEffect} from 'react';
import DownloadSlider from './DownloadSlider'

const Download = () => {
  useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
},[])
  return (
    <DownloadSlider />
  )
}

export default Download