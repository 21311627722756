import React from 'react'

const PmsForm = (props) => {
  return (
    <div>
        <form>
        <div className="mb-1">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-xl" for="username">
            Account Code:  
            </label>
        <input  name="clientCd" className="shadow appearance-none border rounded 2xl:w-96 xl:w-96 lg:w-96 md:w-72 sm:w-60 w-[100%] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Enter your Participant ID for CDSL" onChange={(e)=>{props.setData(e)}}/>
    </div>
    {props.check&&<div className="text-red-500 mx-2">Please Enter Details...</div>}
        </form>
    </div>
  )
}

export default PmsForm