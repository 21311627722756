import React from 'react'

const EsignExeForm = (props) => {
  return (
    <div className="">
        <form>
        <div className="mb-0 mt-4">
            <label className="block text-[#0A2A4A] text-sm font-medium mb-2" for="username">
            Participant ID:
            </label>
        <input  name="Esign CDSL" className="shadow appearance-none border rounded w-96 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Enter your Participant ID" onChange={(e)=>{props.setData(e)}}/>
    </div>
        </form>
    </div>
  )
}

export default EsignExeForm