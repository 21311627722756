import React from 'react';

const Delieveries = ()=>{
    return (
        <>
            
        <section id="deliveries" className='bg-[#F5FAFE]'>

            <div className="title-box text-center px-5 pt-4">
            <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-4 pb-0 text-[#0A2A4A] text-center">
                Deliveries
            </h2>
            </div>
            
            <div className="container flex flex-wrap text-gray-500 lg:text-[17.5px] md:text-[17.5px] text-[15px] lg:px-20 md:px-20 px-10 pb-5 mx-auto trade-web-charges">
                <p>
                Details of deliveries received from / delivered to client / branch physical / along with demat deliveries over last 1 month may be made Online this site to clients. Details of deliveries may also have reference of delivering agency and their docket No. Clients from time to time may verify the entries appearing here and make themselves sure that deliveries sent by them have been received by broker.
                </p>
            </div>  
        </section>
        </>
    )
}
export default Delieveries;