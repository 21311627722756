import React, { useState } from "react";
const accordion = [
  {
    id: 0,
    num: "01",
    title: "E-mails to you:",
    description: "clients may post their queries and differences through an E-mail utility or sort of a complaint register which can be counter updated with answers by concerned department / persons at brokers end.",
  },
  {
    id: 1,
    num: "02",
    title: "Old Data:",
    description: "Client may put in his request for any old data (from the one that is made available) on web site. This data will be made available to broker on daily bases and the broker can take action on it and forward the data to the client in e-mail form or couriered to him.",
  },
  {
    id: 2,
    num: "03",
    title: "Security:",
    description: "A password will be provided for each account code for access. The user will only be able to access data of Account he has logged in for. To access data of any other account he may have to login into other account or a link into other accounts may be established at our end to give access to all his family accounts. The user will be allowed to modify his / her own password.",
  },
  {
    id: 3,
    num: "04",
    title: "Info Space:",
    description: "Space can be provided at top / bottom / sides of screen to put information relating to other services provided by company / associated companies or information like closing process of securities / Currencies etc. Space can also be utilized in the nature of advertisements.",
  },
  {
    id: 4,
    num: "05",
    title: "Print & other options:",
    description: " user can select the option to view/Print/download data as per his choice. The printing will be done to his own printer. Download option is provided in various formats like Text File, data files for use by client for his back office purpose.",
  },
  {
    id: 5,
    num: "06",
    title: "Data Transfer:",
    description: <>A full functional utility is provided to broker to port his data onto web site. Here, Thus the broker does not have to do additional efforts. All that he has to do is just click on an icon when his data is ready in all respect to be transferred to web site. <br/>
    Since we have complete insight into your back office related data, we have basically no questions relating to data and thus you are relieved of answering question for which you do not have answers.
    </>,
  },
];
function Accordion() {
  const [howThisWork, setHowThisWork] = useState(false);
  const [listData, setListData] = useState(accordion);
  const [button, setbutton] = useState(true);

  const showData = (item) => {
    let updatedData = listData.map((isItem) =>
      isItem.id === item.id
        ? { ...isItem, selected: item.selected ? false : true }
        : { ...isItem, selected: false }
    );
    setListData(updatedData);
  };
  const handleClick = (value,index)=>{
    return;
    if(button){
      showData(value);
      setbutton(false);
    }else{
      showData(value);
      setbutton(index); 
    }
  }
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container px-12 py-6 mx-auto ">
          {listData.map((value, index) => (
            <>
              <div className="rounded-xl h-30 mx-auto mb-10 mt-0 bg-gradient-to-r p-[1.5px] from-[#0080F6]  via-[#05C876]  to-[#6EE7B7] ">
                <div className="flex justify-between items-center h-full bg-white text-white rounded-lg text-center">
                  <div
                    id={"accordion-collapse"+index}
                    data-accordion="collapse"
                    className="w-full"
                  >
                    <h2 id={"accordion-collapse-heading-1"+index} onClick={()=>handleClick(value,index)}>
                      <button
                        type="button"
                        className="flex justify-between items-center pl-3 w-full font-medium text-left rounded-t-xl border border-b-sm border-gray-200  "
                        data-accordion-target={"#accordion-collapse-body-1"+index}
                        aria-expanded="true"
                        aria-controls="accordion-collapse-body-1"
                      >
                        <span className="text-[#0A2A4A] text-xl">
                          {value.title}
                        </span>
                        {button===index ? (
                          <svg
                            onClick={() => {
                              showData(value);
                              setbutton(false);
                            }}xmlns="http://www.w3.org/2000/svg"
                            width="72"
                            height="72"
                            fill="#3491FF"
                            className="bi bi-dash-circle-fill"
                            viewBox="0 0 30 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
                          </svg>
                        ) : (
                          <svg
                            onClick={() => {
                              setbutton(index);
                              showData(value);
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="72"
                            height="72"
                            fill="#3491FF"
                            className="bi bi-plus-circle-fill"
                            viewBox="0 0 30 16"
                          >
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                          </svg>
                        )}
                      </button>
                    </h2>
                    {value.selected && index == value.id && (
                      <div
                        id="accordion-collapse-body-1 "
                        className=""
                        aria-labelledby="accordion-collapse-heading-1"
                      >
                        <div
                          className="p-5 border border-b-2 rounded-b-xl
                        border-gray-200 dark:border-gray-700 "
                        >
                          <p className="mb-2 text-[#808D9A] text-[17.5px] text-left">
                            {value.description}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </section>
    </>
  );
}
export default Accordion;
