import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { GetAPI, PostAPI } from "../../../hooks/Service";
import { toast } from "react-toastify";
import CommexForm from "./CommexForm";
import CrossForm from "./CrossForm";
import DescriptionBox from "./DescriptionBox";
import EsignExeForm from "./EsignExeForm";
import EstroForm from "./EstroForm";
import PmsForm from "./PmsForm";
import TradePlusForm from "./TradePlusForm";

const DownloadCard = () => {
  useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
},[])
  // const [nseid, setnseid]=useState("")
  // const [bseid, setbseid] = useState("")
  // var TestData = {
  //   NSEID : nseid,
  //   BSEID : bseid,
  // }
  const [temp, settemp] = useState({});
  const [id, setid] = useState({});
  const [check, setcheck] = useState(false);
  const [user, setUser] = useState(false)
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [selectedCompo, setSelectedCompo] = useState("");
  const params = useParams();
  const setData = (data) => {
    let obj = { ...id };
    if(data.target.value[0]==="/"){
      obj.clientCd = data.target.value
      obj[data.target.name] = ""
    }else{
    console.log("🚀 ~ file: DownloadCard.js ~ line 31 ~ setData ~ obj", obj)
    obj[data.target.name] = data.target.value;
    obj.clientCd=""
  }
    setid(obj);
  };

  const handleChange = async () => {
    setloading(true);
    let sendData = {
      bseCMID: "",
      nseCMID: "",
      cdslDPID: "",
      nsdlDPID: "",
      mcxCMID: "",
      ncdexCMID: "",
      clientCd: "",
      ...id,
    };
    console.log("🚀 ~ file: DownloadCard.js ~ line 48 ~ handleChange ~ sendData", sendData)
    let usercheck = false
    if (Object.values(id)?.filter(d=>d).length == 0 ) {
      setcheck(true);
      setloading(false)
      return
    }
    
    if((sendData?.bseCMID !== "" && sendData?.nseCMID !== "")||(sendData?.mcxCMID !== "" && sendData?.ncdexCMID !== "")){
      usercheck = true; 
      setUser(true)
      setloading(false)
      return
    }

    console.log("🚀 ~ file: DownloadCard.js ~ line 46 ~ handleChange ~ sendData", sendData)

    if(sendData.clientCd){
      sendData.clientCd =  sendData.clientCd.replace("/","")
    }
    
    let resultAPI = await PostAPI("Tplus/Login", sendData);
    if (resultAPI.status) {
      console.log("🚀 ~ file: DownloadCard.js ~ line 75 ~ handleChange ~ Object.values", Object.values(id))
      if (Object.values(id)?.length == 0 ) {
        setcheck(true);
      }
       else {
        localStorage.setItem("dataobj", JSON.stringify(id));
        localStorage.setItem("trade_plus_token", resultAPI.result.token);
        // localStorage.setItem("i  d",JSON.stringify(Object.values(sendData).filter((d)=>d!=="")));

        var now = new Date();
        now.setTime(now.getTime() + 2 * 60 * 60 * 1000);
        let cookievalue = escape(resultAPI.result.token) + ";";
        document.cookie =
          "token=" + cookievalue + ";" + "expires=" + now.toUTCString() + ";";
        {!usercheck && navigate(`/downloads/${selectedCompo}/tabs`);}

      }
    } else {
      toast.error("Invalid User-ID");
      setcheck(false);
    }
    setloading(false);
    // setcheck(false)
    delete_cookie();

    return;
  };

  function delete_cookie(cookievalue) {
    
                
    // The "expire" attribute of every cookie is 
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    document.cookie="password="
      }


  const CardComponents = [
    {
      name: "TradePlus&TradeNet",
      components: (
        <TradePlusForm setData={setData} check={check} setid={setid} user={user}/>
      ),
    },
    {
      name: "ESTRO&EstroNet",
      components: <EstroForm setData={setData} check={check} />,
    },
    {
      name: "CROSS&CrossNet",
      components: <CrossForm setData={setData} check={check} />,
    },
    {
      name: "CommEx&CommNet",
      components: <CommexForm setData={setData} check={check} setid={setid} user={user}/>,
    },
    { name: "PMS", components: <PmsForm setData={setData} check={check} /> },
    {
      name: "eSign",
      components: <EsignExeForm setData={setData} check={check} />,
    },
  ];
  useEffect(() => {
    params.id && setSelectedCompo(params.id);
  }, [params.id]);
  return (
    <div className="xl:flex lg:flex md:flex  bg-[#F4FAFF] w-full">
      <div className="md:w-1/2 sm:w-[100%] xl:w-1/2 lg:w-1/2 w-[100%] lg:my-28  lg:px-28 px-10 my-10 ">
        <DescriptionBox />
      </div>
      <div className="md:w-1/2 sm:w-[100%] xl:w-1/2 lg:w-1/2 md:w-1/2   w-[100%] lg:my-28  lg:px-20 px-10 my-10">
        <div className="text-[#3491FF] font-bold mb-7 lg:text-2xl md:text-2xl text-[16px]">
          Enter Your Details
        </div>
        <div>
          {CardComponents.map((data) => {
            if (data.name === selectedCompo) {
              return <div className="min-h-[7rem]">{data.components}</div>;
            }
          })}
        </div>
        <div>
          <button
            className="uppercase text-sm font-bold tracking-wide bg-[#3491FF] text-white p-3 rounded-lg w-86 focus:outline-none focus:shadow-outline  w-24 mt-0	"
            onClick={() => {
              handleChange();
            }}
          >
            {loading ? (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="ml-[20px] w-6 h-6 items-center justify-center text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>Go</>
            )}
          </button>
        </div>
      </div>
      <></>
    </div>
  );
};

export default DownloadCard;
