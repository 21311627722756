import React, { useRef } from 'react';

function SingleTr({ index, row, SelectCheckBox, HandleInvoiceInfo, HandleClick }) {
  const pdfExportComponent = React.useRef(null);
  return (
    <>
      <tr className={`${index % 2 == 0 && 'bg-[#F3F7FC]'}`}>
        <th className="p-2 border-l">
          <div>
            <input
              id="default-checkbox"
              type="checkbox"
              value=""
              className="w-4 h-4 text-blue-600 bg-gray-100 rounded   dark:bg-[#3491FF] "
              onClick={() => { SelectCheckBox(row.BillNo) }}
            />
          </div>
        </th>
        <th className='py-4 text-[#3491FF] md:text-lg text-xs cursor-pointer'
          onClick={() => HandleInvoiceInfo(row.BillNo)}>
          {row.BillNo}
        </th>
        <th className='py-4 text-[#3D3D3D] md:text-lg text-xs font-medium'>{row.Date}</th>
        <th className='py-4 text-[#3D3D3D] md:text-lg text-xs font-medium'>{row.Amount}</th>
        <th className='py-4 text-[#3D3D3D] md:text-lg text-xs font-medium'>{row.Received}</th>
        <th className='py-4 text-[#3D3D3D] md:text-lg text-xs font-medium'>{row.TDS}</th>
        <th className='py-4 text-[#3D3D3D] md:text-lg text-xs font-medium'>{row.Balance}</th>
        <th className='flex justify-center h-12'>
          <svg
            className='w-4 cursor-pointer'
            xmlns="http://www.w3.org/2000/svg"
            id={`Layer_1_${index}`}
            data-name={`Layer_1_${index}`}
            viewBox="0 0 122.88 120.89"
            onClick={() => HandleClick(row.BillNo, pdfExportComponent.current)}

          ><title>download-file</title>
            <path d="M84.58,47a7.71,7.71,0,1,1,10.8,11L66.09,86.88a7.72,7.72,0,0,1-10.82,0L26.4,58.37a7.71,7.71,0,1,1,10.81-11L53.1,63.12l.16-55.47a7.72,7.72,0,0,1,15.43.13l-.15,55L84.58,47ZM0,113.48.1,83.3a7.72,7.72,0,1,1,15.43.14l-.07,22q46,.09,91.91,0l.07-22.12a7.72,7.72,0,1,1,15.44.14l-.1,30h-.09a7.71,7.71,0,0,1-7.64,7.36q-53.73.1-107.38,0A7.7,7.7,0,0,1,0,113.48Z" /></svg>
        </th>
      </tr>
    </>
  )
}

export default SingleTr