import React from "react";
import DataGrid, {
  Column,
  LoadPanel,
  Grouping,
  GroupPanel,
  Selection,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";

const TradePlusTable = ({tableData,data,windowSize}) => {
  console.log("🚀 ~ file: TradePlusTable.js:14 ~ TradePlusTable ~ data", data,tableData)
  function createData(Branch, Commex, ClearingID, apiPre, apitab) {
    return { Branch, Commex, ClearingID, apiPre, apitab };
  }

  const rows = [
    createData("TradeNet", "NSE", "", "Nse", "TradeNet"),
    createData("TradeWeb", "BSE", "", "Bse", "Website"),
    createData("eSign", "MCX", "", "MCX", "ESign"),
  ];

  const column1 = (row) => {
    console.log("🚀 ~ file: TradePlusTable.js:25 ~ column1 ~ data", data)
    
    return (<div>
                <div className="flex items-center justify-left">
                    <div className="flex px-2">
                      <input  
                        id="default-checkbox"
                        type="checkbox"
                        checked={
                          data?.[`${row?.apitab}`] == "Y"
                        }
                        className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded   "
                      />
                    </div>
                    <div className="px-3 text-[#3491FF] text-sm font-medium">
                      {row?.Branch}
                    </div>
                </div>
           </div>)
  }
  const column2 = (row) => {
    return (
        <div className="px-7 text-[#3D3D3D]">{row?.Commex}</div>
    )
  }
  const column3 = (row) => {
    return ( <div className="flex items-center justify-left pl-6">
            <div className="flex px-2">
              <input  
                id="default-checkbox"
                type="checkbox"
                checked={
                    data?.[`${row?.apiPre}FO`] == "Y"
                }
                className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded   "
              />
            </div>
        </div>)
    }

  const column4 = (row) => {
    return ( <div className="flex items-center justify-left pl-6">
            <div className="flex px-2">
              <input  
                id="default-checkbox"
                type="checkbox"
                checked={
                  data?.[`${row?.apitab}`] == "Y"
                }
                className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded   "
              />
            </div>
        </div>)
    
  }
  const column5 = (row) => {
    return ( <div className="flex items-center justify-left pl-6">
            <div className="flex px-2">
              <input  
                id="default-checkbox"
                type="checkbox"
                checked={
                    data?.[`${row?.apiPre}FX`] == "Y"
                }
                className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded   "
              />
            </div>
        </div>)
    
  }
  const column6 = (row) => {
    return ( <div className="flex items-center justify-left pl-6">
            <div className="flex px-2">
            <input disabled 
                      type="text"
                      value={data?.[`${row?.apiPre}ClgID`] }
                      id="visitors"
                      className=" block pb-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2  appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
                    />
            </div>
        </div>)
  }

  return (
    <>
      <div>
        <div className="px-[1px]">
            <div className="flex items-center justify-center py-3 header-style">
                <input
                    checked={data?.TradePlus == "Y"}
                    className="w-[18px] h-[18px] mr-[5px] items-center"
                    type="checkbox" 
                />
                <div className="text-lg font-medium normal-case">TradePlus</div>
            </div>
        </div>
      </div>

      <div>
        <DataGrid
          dataSource={rows}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          showBorders={true}
          keyExpr="Branch"
          >
            {console.log("🚀 ~ file: TradePlusTable.js:64 ~ TradePlusTable ~ rows", rows)}
          <Column
            dataField="Branch"
            caption=""
            width={125}
            dataType="string"
            alignment="center"
            cellRender={(e) => column1(e.data)}
            
          />
          <Column
            dataField="Plateform"
            caption=""
            width={110}
            dataType="string"
            alignment="center"
            cellRender={(e) => column2(e.data)}
          />
          <Column
            dataField="Cash"
            caption="Cash"
            dataType="string"
            alignment="center"
            hidingPriority={3}
            cellRender={(e) => column3(e.data)}
          />
          <Column
            dataField="FO"
            caption="F&O"
            dataType="string"
            alignment="center"
            hidingPriority={2}
            cellRender={(e) => column4(e.data)}
          />
          <Column
            dataField="FX"
            caption="FX"
            dataType="string"
            alignment="center"
            hidingPriority={1}
            cellRender={(e) => column5(e.data)}
          />
          <Column
            dataField="ClearingID"
            caption="Clearing ID"
            hidingPriority={0}
            dataType="string"
            alignment="center"
            cellRender={(e) => column6(e.data)}
          />
          
        </DataGrid>
      </div>
    </>
  );
};

export default TradePlusTable;
