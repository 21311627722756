import React, { useState, useEffect } from "react";
import AccountDescription from "./AccountDescription";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import SingleTr from "./SingleTr";
import DownloadInvoice from "./DownloadInvoice";
import { toast } from 'react-toastify';
import Paginate from "./Pagination";
import MultiDownload from "./MultiDownload";
import useFileDownloader from "../../../hooks/useFileDownloader";
import Downloader from "./Downloader";
import moment from "moment"
import DataGrid, {
  Column,
  LoadPanel,
  Grouping,
  GroupPanel,
  Selection,
  Pager,
  Paging,
  // StateStoring,
  SearchPanel,
} from 'devextreme-react/data-grid';
import BillDescription from "./BillDescription";
import SingleInvoice from './invoice/SingleInvoice'
const ref = React.createRef();
const onRowPre = (e) => {
  if (e.rowType == "header") {
    e.rowElement.style.backgroundColor = "black !important";
    e.rowElement.style.fontSize = "1rem";
    e.rowElement.style.lineHeight = "1.5rem";
  }
  if (e.rowType == "data") {
    e.rowElement.style.margin = "15px";
    e.rowElement.style.fontStyle = "medium";
    e.rowElement.style.color = "#3D3D3D";
    e.rowElement.style.lineHeight = "35px";
    e.rowElement.style.fontWeight = 400;
    if (e.rowIndex % 2 === 0) {
      e.rowElement.style.backgroundColor = "#E1F1FF";
    }
  }
};
const MyAccountTable = ({
  HandleInvoiceInfo,
  AccountData,
  BillInfoData,
  GetInvoice, 
  BillInfo,
  setinvoice, 
  invoice, 
  index,
  loadPanelVisible
}) => {
  const [selectbox, setselectbox] = useState([]);
  const [downloadList, setDownloadList] = useState([]);
  const [loading, setloading] = useState(false);
  const [downloadFIleName, setDownloadFileName] = useState("");
  const [multiDownloadData, setMultiDownloadData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [pageData, setPageData] = useState([])
  const loadingPdf = React.useRef(null);
  const MultiDownloadRef = React.useRef(null);
  const [collapsed, setCollapsed] = useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize());


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  const onContentReady = (e) => {
    if (!collapsed) {
      e.component.expandRow(['EnviroCare']);
      setCollapsed(true)
    }
  }
  const data = JSON.parse(localStorage.getItem("ProfileTab"))
  const pageChange = (page) => {
    var start = ((page - 1) * 10);
    var end = start + 10;
    let data = [...AccountData].slice(start, end);
    setPageData(data)
  }
  const SelectCheckBox = async (billNo,currentBill) => {

    let dataArray = [...selectbox];
    let multiDownloadDataList = [...multiDownloadData];
    console.log("🚀 ~ file: MyAccountTable.js ~ line 88 ~ SelectCheckBox ~ multiDownloadDataList",billNo,currentBill,dataArray, multiDownloadDataList)

    if (dataArray.includes(billNo)) {
      dataArray = dataArray.filter((d) => d !== billNo);
      multiDownloadDataList = multiDownloadDataList.filter((d) => d.BillNo !== currentBill)
    } else {
      dataArray.push(billNo);
      // Get Billing Number 
      let resultData = await GetInvoice(billNo);
      if (resultData.result.Status) {
        multiDownloadDataList.push(resultData.result.Data);
      } else {
        toast.warning(billNo + " No Bill Found ")
      }
    }
    setMultiDownloadData(multiDownloadDataList);
    setselectbox(dataArray);
  };
  const HandleClick = async (billNo, current = "", index = 0,date,Bill) => {
    console.log("🚀 ~ file: MyAccountTable.js:120 ~ HandleClick ~ billNo", billNo)
    let newDate = date.split("/")
    newDate = newDate[2] + "-" + newDate[1] + "-" + newDate[0]
    if((moment(newDate).isAfter('2022-10-22')|| moment(newDate).isSame('2022-10-22')) && (!billNo.toLowerCase().includes('perfoma'))){
      let dBURL = 'https://tplus.in/Bills/' + Bill + ".pdf";
      // const link = document.createElement('a');
      window.open(dBURL, '_blank');
      // link.href = dBURL;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      return
    }
    loadingPdf.current = index;
    setDownloadFileName("");
    let resultData = await GetInvoice(billNo);
    console.log("🚀 ~ file: MyAccountTable.js:125 ~ HandleClick ~ resultData", resultData)
    if (resultData.result.Status) {
      if (resultData.result) {
        setDownloadList(resultData.result);
        setDownloadFileName(billNo);
      } else {
        toast.warning(billNo + " No Bill Found")
      }
    }
    if (resultData.status === false) {
      toast.warning(billNo + " No Bill Found")
    }
    loadingPdf.current = false;
  };
  const isloading = (value) => {
    setloading(value);
  };
  const Handle_multi_down = () => {
    if (MultiDownloadRef.current) {
      MultiDownloadRef.current.save();
    }
  }
  const viewDate = (date) => {
    let newdate = date.replace(/\//g, "-")
    newdate = newdate.split("-")
    newdate = newdate[2] + "-" + newdate[1] + "-" + newdate[0]
    newdate = moment(new Date(newdate)).format("DD/MM/yyyy")
    return <p>{newdate}</p>
  }
  const decimalfraction = (amount) => {
    let newAmount = amount.toFixed(2)
    return <p>{newAmount}</p>
  }
  const RanderBillNUm = (billNo, InfoId,date,Bill) => {
    console.log("🚀 ~ file: MyAccountTable.js:170 ~ RanderBillNUm ~ billNo", billNo,InfoId,date,Bill)
    return <>
      <div className="flex  items-center">
        {/* <input
          id="checkbox"
          type="checkbox"
          checked={selectbox.includes(InfoId)}
          className="w-4 h-4 text-blue-600 bg-gray-100 rounded   dark:bg-[#3491FF]"
          onClick={() => SelectCheckBox(InfoId,billNo)}
        /> */}
        {/* <span className="text-blue-500 cursor-pointer ml-6 hover:underline" */}
        <span className="text-blue-500 cursor-pointer ml-2" 
          onClick={() => HandleInvoiceInfo(InfoId,billNo,date,Bill)}
        >{billNo}</span>
      </div>
    </>
  }
  const DownloadButton = (DownloadID,data,Bill) => {
    console.log("🚀 ~ file: MyAccountTable.js:180 ~ DownloadButton ~ data", data)
    // onClick={() => HandleClick(row.Year+"/"+row.Bill, ref.current, i)}
    return <>
      <svg
        className="w-4 cursor-pointer 2xl:ml-20 xl:ml-20 md:ml-20 sm:ml-20 ml-1"
        xmlns="http://www.w3.org/2000/svg"
        id={`Layer_1`}
        data-name={`Layer_1`}
        viewBox="0 0 122.88 120.89"
        onClick={() => HandleClick(DownloadID, ref.current, 0,data?.Date,Bill)}
      >
        <title>download-file</title>
        <path d="M84.58,47a7.71,7.71,0,1,1,10.8,11L66.09,86.88a7.72,7.72,0,0,1-10.82,0L26.4,58.37a7.71,7.71,0,1,1,10.81-11L53.1,63.12l.16-55.47a7.72,7.72,0,0,1,15.43.13l-.15,55L84.58,47ZM0,113.48.1,83.3a7.72,7.72,0,1,1,15.43.14l-.07,22q46,.09,91.91,0l.07-22.12a7.72,7.72,0,1,1,15.44.14l-.1,30h-.09a7.71,7.71,0,0,1-7.64,7.36q-53.73.1-107.38,0A7.7,7.7,0,0,1,0,113.48Z" />
      </svg>
    </>
  }
  const { files, downloadFile, remove } = useFileDownloader();
  const DownloadZipFile = (file) => {
    let obj = {
      name: file?.split("/")?.reverse()?.[0],
      file: file,
      filename: file?.split("/")?.reverse()?.[0],
    };
    if (file) {
      downloadFile(obj);
    } else {
      toast.warning("File Not Found", {
        progress: undefined,
        autoClose: 5000,
      });
    }
  };
  const downloadfile = () => {
    let url = `/Compliance_Check_206AB.pdf`;
    let dBURL = 'https://tplus.in'+url;
    window.open(dBURL, '_blank');
    // const link = document.createElement('a');
    // link.href = dBURL;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link); 
   }




  
const onSelectionChanged = ({ selectedRowKeys }) => {
  setSelectedRowKeys(selectedRowKeys)
  selectedRowKeys.map(d=>{
    SelectCheckBox(d.Year + '/' + d.Bill)
  })
}
  // const TableHead = ["QueryID","SrNo", "Message","Date", "Time", "InOut", "Mode", "FromTo"]
  return (
    <>  {invoice ? 
    <AccountDescription setinvoice={setinvoice} BillInfo={BillInfo} HandleClick={HandleClick} index={index} windowSize={windowSize?.innerWidth}/> : 
    
    <div className="lg:px-12 px-3 pt-2 pb-0">
      {/* <div className='text-[#3491FF] lg:text-lg text-base	'>{data?.ClientName}</div> */}
     
      {/* <LoadPanel
                height={100}
                width={250}
                visible={true}
                showIndicator={true}
            /> */}
            <div className="w-[100%] overflow-scroll px-3" id="scrollbar">
      <DataGrid
        dataSource={AccountData}
        allowColumnReordering={true}
        rowAlternationEnabled={true}
        showBorders={true}
        onContentReady={onContentReady}
        onRowPrepared={onRowPre}
        keyExpr="BillNo"
        // width={windowSize?.innerWidth >= 1440 ? "100%" : 1440}
        // onSelectionChanged={onSelectionChanged}
      >
        {/* <StateStoring
        enabled={true}

        type="localStorage"

        storageKey="audit-templates-list-custom"

        /> */} 
        {/* {console.log("🚀 ~ file: MyAccountTable.js ~ line 239 ~ AccountData", AccountData)} */}
        {/* <Selection mode="multiple" /> */}
        {/* <GroupPanel visible={true} /> */}
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        {/* <Grouping autoExpandAll={false} /> */}
        {/* <Column dataField="QueryID" caption="Querie ID"  /> */}
        <Column
          dataField="BillNo"
          // paddingLeft="100px"
          // marginLeft="100px"
          caption="Bill No"
          dataType="string"
          alignment="left"
          width={190}


          
          // cellRenderer = {(e)=>RanderBillNUm(e.data.BillNo, e.data.Year + '/' + e.data.Bill)}
          cellRender={(e) => RanderBillNUm(e.data.BillNo, e.data.Year + '/' + e.data.Bill,e.data.Date,e.data.Bill)}
          defaultSortIndex={0}
        />
        <Column
          dataField="BillNo"
          caption="Date" 
          hidingPriority={5}
          defaultSortOrder={"desc"}
          dataType="date"
          alignment="left"  
          cellRender={(e) => viewDate(e.data.Date)}
        />
        <Column
          dataField="Amount"
          caption="Amount"
          hidingPriority={4}
          dataType="string"
          alignment="right"
          cellRender={(e) => decimalfraction(e.data.Amount)}
        />
        <Column
          dataField="Received"
          caption="Received"
          hidingPriority={3}
          dataType="string"
          alignment="right"
          cellRender={(e) => decimalfraction(e.data.Received)}
        />
        <Column
          dataField="TDS"
          caption="TDS"
          hidingPriority={2}
          dataType="string"
          alignment="right"
          cellRender={(e) => decimalfraction(e.data.TDS)}
        />
        <Column
          dataField="Balance"
          caption="Balance"
          hidingPriority={1}
          dataType="string"
          alignment="right"
          cellRender={(e) => decimalfraction(e.data.Balance)}
        />
        <Column
          caption="Action"
          hidingPriority={0}
          dataType="string"
          format="currency"
          alignment="center"
          cellRender={(e) => DownloadButton(e.data.Year + '/' + e.data.Bill,e.data,e.data.Bill)}
        />
        <Pager allowedPageSizes={'10'} showPageSizeSelector={false} />
        <Paging defaultPageSize={10} />
      </DataGrid>
      </div>
      <div className="m-3 mr-0">
        {/* <Paginate pageChange={pageChange} length={AccountData.length} /> */}
      </div> 
      {selectbox.length > 0 && loading == false && (
        <div className="py-4">
          <div>
            <button
              type="submit"
              onClick={Handle_multi_down}
              className="text-white text-lg  bg-[#3491FF] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg  w-full sm:w-auto px-6 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {selectbox.length} Download
            </button>
          </div>
        </div>
      )}
      {loading == true && (
        <div class="flex items-center justify-center py-4">
          <button
            type="button"
            class="inline-flex items-center px-4 py-2 text-sm font-semibold leading-6 text-white transition duration-150 ease-in-out bg-indigo-500 rounded-md shadow cursor-not-allowed hover:bg-indigo-400"
            disabled=""
          >
            <svg
              class="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Downloading...
          </button>
        </div>
      )}
      <div
        style={{
          position: "absolute",
          left: "-2000px",
          top: 0,
          maxHeight: '10px',
          overflowY: 'scroll'
        }}
      >
        <div className="" >
          {multiDownloadData.map((singleDownload) => (
            <AccountDescription
              setinvoice={setinvoice}
              BillInfo={singleDownload}
              isDowbload={true}
              loadPanelVisible={loadPanelVisible}
            />
          ))}
        </div>
        <PDFExport
          fileName="all-invoices"
          paperSize="A4"
          title="invoice-pdf"
          margin={2}
          ref={MultiDownloadRef}
          scale={0.8}
          forcePageBreak=".breack"
        >
          <div ref={MultiDownloadRef} >
            {multiDownloadData.map((singleDownload, i) => (
              <div className={`${i !== 0 && 'breack'}`}>  <SingleInvoice
                setinvoice={setinvoice}
                BillInfo={singleDownload}
                isDowbload={true}
              />
              </div>
            ))}
          </div>
        </PDFExport>
      </div>
      </div>}
      <div className="2xl:flex xl:flex lg:flex md:flex justify-between md:my-8 my-6">
        <div
          className="text-[#3491FF] underline card-font text-base underline-offset-1 pl-7"
        >
          Last Updated On 03-06-2022 at 10:38
        </div>
        <div
          className="text-[#3491FF] underline card-font text-base underline-offset-1 mr-7 pl-7 cursor-pointer"
          onClick={() => downloadfile()}
        >
          Compliance Check for Section 206AB & 206CCA
        </div>
      </div>
    
      <div className="w-10 h-10px overflow-auto">
        <DownloadInvoice
          setinvoice={setinvoice}
          downloadList={downloadList}
          fileName={downloadFIleName}
          selectbox={selectbox}
          isloading={isloading}
          setDownloadFileName={setDownloadFileName}
          setDownloadList={setDownloadList}
        />
        {files.length ? (
          <div className=" fixed bg-slate-300  bottom-20 left-2 lg:w-[20%] md:w-[20%]  w-[80%] px-6 rounded-md z-[999999999999] ">
            <div className="w-[100%] flex justify-end"></div>
            <Downloader files={files} remove={remove} />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default MyAccountTable;

