import React,{useEffect,useState} from 'react';
import Accounts from './Accounts';
import Banners from './Banners';
import Billing from './Billing';
import Branches from './Branches';
import Charges from './Charges';
import ClientMaster from './ClientMaster';
import DematRequest from './DematRequest';
import Reports from './Reports';
import Statements from './Statements';
import StationeryManagement from './StationeryManagement';
import Technology from './Technology';
import Transactions from './Transactions';
import Website from './Website';
import OtherFeature from './OtherFeature';
import BranchCross from './BranchCross';
import BillingMob from './MobBilling';

function ProductCross
() {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  console.log("🚀 ~ file: index.js:22 ~ windowSize", windowSize)

  useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
},[])

useEffect(() => {
  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  window.addEventListener('resize', handleWindowResize);

  return () => {
    window.removeEventListener('resize', handleWindowResize);
  };
}, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

  return (
    <>
      <Banners/>
      <ClientMaster/>
      <Transactions/>
      <Charges/>
      {windowSize?.innerWidth >= 768 ?<Branches/>:<BranchCross/>}
      <StationeryManagement/>
      <DematRequest/>
      <Statements/>
      <Billing className=""/>
      <BillingMob className=""/>
      <Accounts/>
      <Reports/>
      <OtherFeature/>
      <Technology/>
      <Website/>
    </>
  )
}

export default ProductCross;
