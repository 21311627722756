import { Pagination } from 'antd';
import React, { useState } from 'react';

const Paginate = ({ pageChange, length }) => {
    const [current, setCurrent] = useState(1);

    const onChange = (page) => {
        setCurrent(page);
        pageChange(page)
    };

    return (<>
        {length ? <Pagination
            current={current}
            onChange={onChange}
            total={length}
            defaultPageSize={10}
            className="text-end text-right m-5 p-5"
            showSizeChanger={false}
        /> : <></>}
    </>)
};

export default Paginate;