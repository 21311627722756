import React from 'react'
const Img1 = require('./img/Web1.png');
const Img2 = require('./img/Web2.png');

function Website() {
  return (
    <>
    <section className='brancher-sections  branch-timeline bg-[#ffffff] '>
        <div className='2xl:px-12 xl:px-12 lg:px-12 md:px-12 sm:px-12 px-3 py-20 '  >
            <div className='container mx-auto' >
            <h2 className='title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-0  text-[#0A2A4A] relative text-center pb-10 '>Web Site Connectivity (Optional)</h2>
            <div className='grid grid-cols-1 relative h-[10rem] 2xl:pl-[8rem] xl:pl-[8rem] lg:pl-[8rem] md:pl-[8rem] sm:pl-[8rem] pl-[5rem]'>
                <div className='img-section absolute'>
                    <img src={Img1} className={'h-[10rem]'} />
                </div>
                <div className='txt-setion flex justify-left items-center' >
                    <p className='text-[#808d9a] 2xl:text-[17px] xl:text-[17px] lg:text-[17px] md:text-[17px] sm:text-[17px] text-[14px] m-0'>A comprehensive web application to allow clients to access information on their account using Internet connection.</p>
                </div>
            </div>
            <div className='grid grid-cols-1 relative h-[10rem] 2xl:pr-[8rem] xl:pr-[8rem] lg:pr-[8rem] md:pr-[8rem] sm:pr-[8rem] pr-[5rem] mt-10 '>
                <div className='txt-setion flex  items-center justify-right' >
                    <p className='w-full text-end text-[#808D9A] 2xl:text-[17px] xl:text-[17px] lg:text-[17px] md:text-[17px] sm:text-[17px] text-[14px] m-0'>Data on web site may be updated using back office application database with built in facility.</p>
                </div>
                <div className='img-section absolute right-0'>
                    <img src={Img2} className={'h-[10rem]'} />
                </div>
            </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default Website


// content: "";
//     border-top: 2px dashed #29AEAA;
//     width: 24rem;
//     position: absolute;
//     bottom: 117px;
//     left: -12rem;
//     transform: rotate(34deg);
// }