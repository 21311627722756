import React from 'react';
const SegIMg  = require('./img/seg-img.png');

function Statements() {
    return (
        <>
            <section className="text-gray-600 body-font">
                <div className=' text-center'>
                    <h2 className='title text-lg lg:text-3xl font-bold sm:text-3xl md:text-3xl pt-8 pb-0 text-[#0A2A4A] text-center'>Statements</h2>
                </div>
                <div className="container px-12 pb-20 mx-auto flex flex-wrap">
                    <div className="flex flex-wrap w-full justify-center">
                        <div className="p-4 md:w-1/2 sm:w-1/2">
                        <div className='relative lg:w-full md:w-full w-[293px] lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                                <img src={SegIMg} className="lg:absolute md:absolute inherit lg:h-auto md:h-auto h-[147px]" />
                                <div className="absolute px-[2rem] xl:top-[38%] lg:top-[16%] md:top-[5%] top-[11%]">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[17px] sm:text-[17px] font-medium text-center lg:pt-6 md:pt-6 pt-[12px]">Printing Holding statements, Transaction statements for clients at predefined intervals on Dot Matrix Printers / Page Printer.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 md:w-1/2 sm:w-1/2">
                        <div className='relative lg:w-full md:w-full w-[293px] lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                                <img src={SegIMg} className="lg:absolute md:absolute inherit lg:h-auto md:h-auto h-[147px]" />
                                <div className="absolute px-[2rem] xl:top-[38%] lg:top-[16%] top-[8%]">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[17px] sm:text-[17px] font-medium text-center pt-6">Automatic forwarding statements to client's e-mail address / Fax at predefined frequency.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 md:w-1/2 sm:w-1/2">
                        <div className='relative lg:w-full md:w-full w-[293px] lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                                <img src={SegIMg} className="lg:absolute md:absolute inherit lg:h-auto md:h-auto h-[147px]" />
                                <div className="absolute px-[2rem]  lg:top-[94px] top-[10%] text-center w-full">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[17px] sm:text-[17px] font-medium text-center pt-6 w-full">Can be printed on pre-printed stationery with on site set-up utility.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Statements