import React,{useState,useEffect} from 'react';
import { PostAPI } from '../../../hooks/Service';
const TradePlusForm = (props) => {
  const [Id, setId] = useState({
    bseCMID:"",
    nseCMID: "",
  })

  console.log("🚀 ~ file: TradePlusForm.js ~ line 8 ~ TradePlusForm ~ Id", Id)
  const storedata = (e) => {

    let Obj = {...Id}
        if(e.target.value[0]==="/"){
          Obj.clientCd = e.target.value
          Obj[e.target.name] = ""
        }else{
        Obj[e.target.name]=e.target.value
        Obj.clientCd=""

        }

        setId({...Obj})
        props.setid(Obj )
  }

  // if(Id?.bseCMID !== "" || Id?.nseCMID !== ""){
  //   setUser(true)
  // }

  return (
    <div>
       <div>
        <div className="mb-1">
            <label className="block text-[#0A2A4A] text-sm font-medium mb-2 " for="username">
            Clearing ID for BSE:
            </label>
            <input  name="bseCMID" className="shadow appearance-none border rounded 2xl:w-96 xl:w-96 lg:w-96 md:w-72 sm:w-60 w-[100%] py-2 px-3 text-[#808D9A] leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Enter your Clearing ID for BSE" 
            onChange={storedata}/>
        </div>
        {/* {props.check&&<div className="text-red-500 mx-2">Please Enter Details...</div>} */}
        <div className="mb-4 mt-4">
            <label className="block text-[#0A2A4A] text-sm font-medium mb-2 " for="username">
            Clearing ID for NSE:
            </label>
            <input   name="nseCMID" className="shadow appearance-none border rounded 2xl:w-96 xl:w-96 lg:w-96 md:w-72 sm:w-60 w-[100%]  py-2 px-3 text-[#808D9A] leading-tight focus:outline-none focus:shadow-outline" id="username" type="text" placeholder="Enter your Clearing ID for NSE" 
            onChange={storedata}/>
        </div>
        {props.check&&<div className="text-red-500 mx-2 mb-2">Please Enter Atleast One Details...</div>}
        </div>

          {console.log("🚀 ~ file: TradePlusForm.js ~ line 42 ~ TradePlusForm ~ props.user", props.user)}
            {props.user && <div className='mb-3'>
          <lable className="text-red-500 mx-2">Please Enter Any One Field!!!!!</lable>
        </div>}
    </div>
    
  )
}

export default TradePlusForm

// onChange={(e)=>{props.setData(e)}}