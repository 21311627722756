
import React,{useEffect} from 'react';
import Accordion from './Accordion';
import Banners from './Banners';
import Table from './Table';
import TechnicalDetails from './TechnicalDetails';
import Brokrage from './Brokrage';
import Charges from './Charges';
// import Contracts from './Contracts';
import Statements from './Statements';
import Delieveries from './Delieveries';

const TradeWeb =  ()=> {
  useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
},[])
  return (
    <>
        <Banners/>
        <TechnicalDetails/>
        <Brokrage/>
        <Charges/>
        <Statements/>
        <Delieveries/>
        <Accordion/>
    </>
  )
}

export default TradeWeb;