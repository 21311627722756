import React from 'react';
const BiCir = require('./img/bil-circle.png');
const ZigZag = require('./img/zig-zag.png');
const BillingMob = () => {
    return (
        <>
            <section className=' lg:hidden md:hidden block bg-[#F4FAFF] py-10 2xl:px-14 xl:px-14 lg:px-14 md:px-14 sm:px-14 px-3'>
                <div className='container mx-auto relative' >
                    <div className="flex flex-col text-center w-full mb-10">
                        <h2 className='title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-0 pb-0 text-[#0A2A4A]'>Billing</h2>
                    </div>
                    {/* <img src={ZigZag} className="absolute max-h-full left-[45%] w-[8rem] h-[90%] " /> */}
                    <ul className='list-wrraper'>
                        <li>
                            <div className='flex  justify-start relative mb-6'>
                                <div className='box-right relative text-center  flex justify-center items-center ' >
                                    <div className='w-[2.9rem] h-20 lg:relative absolute flex justify-end items-center  ' >
                                        <img src={BiCir} className="h-10 w-10 absolute z-[0] right-[-20px]" />
                                        <span className='text-[#FFFFFF] text-[17px] z-[10] absolute right-[-9px] top-[28px] font-semibold font-Lexend ' >
                                            01
                                        </span>
                                    </div>
                                </div>
                                <div className='content-left flex justify-center items-center lg:px-10 pl-[4rem] pr-6' >
                                    <p className='text-left text-[#808D9A] lg:text-[17px] md:text-[17px] text-[15px]'>
                                        Including Demat request charges in bills for better accounting. (upfront collection is also possible and simplified)
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='flex justify-end relative '>
                                <div className='box-right relative text-center  flex justify-center items-center' >
                                    <div className='w-[2.9rem] h-20 lg:relative absolute flex justify-end items-center  ' >
                                        <img src={BiCir} className="h-10 w-10 absolute z-[0] right-[-20px]" />
                                        <span className='text-[#FFFFFF] text-[17px] z-[10] absolute right-[-9px] top-[28px] font-semibold font-Lexend ' >
                                            02
                                        </span>
                                    </div>
                                </div>
                                <div className='content-left flex justify-center items-center lg:px-10 pl-[4rem] pr-6' >
                                    <p className='text-[#808D9A] lg:text-[17px] md:text-[17px] text-[15px]'>
                                        Levying additional charges for special tasks carried out for clients. (e.g. charges for indemnity bond)
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='flex  justify-start relative mt-5'>
                                <div className='box-right relative text-center  flex justify-center items-center ' >
                                    <div className='w-[2.9rem] h-20 lg:relative absolute flex justify-end items-center  ' >
                                        <img src={BiCir} className="h-10 w-10 absolute z-[0] right-[-20px]" />
                                        <span className='text-[#FFFFFF] text-[17px] z-[10] absolute right-[-9px] top-[28px] font-semibold font-Lexend ' >
                                            03
                                        </span>
                                    </div>
                                </div>
                                <div className='content-left flex justify-center items-center lg:px-10 pl-[4rem] pr-6' >
                                    <p className='text-left text-[#808D9A] lg:text-[17px] md:text-[17px] text-[15px]'>
                                        Printing bills on blank papers / letter heads / pre defined stationery on Dot Matrix Printer / line Printer / Page printer in summarized / detailed mode.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='flex justify-end relative mt-5'>
                                <div className='box-right relative text-center  flex justify-center items-center' >
                                    <div className='w-[2.9rem] h-20 lg:relative absolute flex justify-end items-center  ' >
                                        <img src={BiCir} className="h-10 w-10 absolute z-[0] right-[-20px]" />
                                        <span className='text-[#FFFFFF] text-[17px] z-[10] absolute right-[-9px] top-[28px] font-semibold font-Lexend ' >
                                            04
                                        </span>
                                    </div>
                                </div>
                                <div className='content-left flex justify-center items-center lg:px-10 pl-[4rem] pr-6' >
                                    <p className='text-[#808D9A] lg:text-[17px] md:text-[17px] text-[15px]'>
                                        Billing may be done for client himself or on a branch or franchise.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='flex  justify-start relative mt-5 '>
                                <div className='box-right relative text-center  flex justify-center items-center ' >
                                    <div className='w-[2.9rem] h-20 lg:relative absolute flex justify-end items-center  ' >
                                        <img src={BiCir} className="h-10 w-10 absolute z-[0] right-[-20px]" />
                                        <span className='text-[#FFFFFF] text-[17px] z-[10] absolute right-[-9px] top-[28px] font-semibold font-Lexend ' >
                                            05
                                        </span>
                                    </div>
                                </div>
                                <div className='content-left flex justify-center items-center lg:px-10 pl-[4rem] pr-6' >
                                    <p className='text-left text-[#808D9A] text-[17px]'>
                                        File export of bill Summery for plugging into your other software for users like banks, exchanges, brokers etc.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='flex justify-end relative mt-5'>
                                <div className='box-right relative text-center  flex justify-center items-center' >
                                    <div className='w-[2.9rem] h-20 lg:relative absolute flex justify-end items-center  ' >
                                        <img src={BiCir} className="h-10 w-10 absolute z-[0] right-[-20px]" />
                                        <span className='text-[#FFFFFF] text-[17px] z-[10] absolute right-[-9px] top-[28px] font-semibold font-Lexend ' >
                                            06
                                        </span>
                                    </div>
                                </div>
                                <div className='content-left flex justify-center items-center  lg:px-10 pl-[4rem] pr-6' >
                                    <p className='text-[#808D9A] lg:text-[17px] md:text-[17px] text-[15px]'>
                                        Specifying minimum bill amount below which amounts will be carried forward to next billing cycle for accumulation.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='flex  justify-start relative mt-5'>
                                <div className='box-right relative text-center  flex justify-center items-center ' >
                                    <div className='w-[2.9rem] h-20 lg:relative absolute flex justify-end items-center  ' >
                                        <img src={BiCir} className="h-10 w-10 absolute z-[0] right-[-20px]" />
                                        <span className='text-[#FFFFFF] text-[17px] z-[10] absolute right-[-9px] top-[28px] font-semibold font-Lexend ' >
                                            07
                                        </span>
                                    </div>
                                </div>
                                <div className='content-left flex justify-center items-center lg:px-10 pl-[4rem] pr-6' >
                                    <p className='text-left text-[#808D9A] lg:text-[17px] md:text-[17px] text-[15px]'>
                                        Broker DPs may redirect charges attributable to broking division to other account or offer NIL charges on them.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='flex justify-end relative mt-5'>
                                <div className='box-right relative text-center  flex justify-center items-center' >
                                    <div className='w-[2.9rem] h-20 lg:relative absolute flex justify-end items-center  ' >
                                        <img src={BiCir} className="h-10 w-10 absolute z-[0] right-[-20px]" />
                                        <span className='text-[#FFFFFF] text-[17px] z-[10] absolute right-[-9px] top-[28px] font-semibold font-Lexend ' >
                                            08
                                        </span>
                                    </div>
                                </div>
                                <div className='content-left flex justify-center items-center lg:px-10 pl-[4rem] pr-6' >
                                    <p className='text-[#808D9A] lg:text-[17px] md:text-[17px] text-[15px]'>
                                        Forwarding detailed bill to client via E-mail to who are provided summarized bills in print.
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}
export default BillingMob;