import React, { useState } from "react";

const CommexTable = ({ data,windowSize }) => {
  console.log("🚀 ~ file: CommexTable.js ~ line 4 ~ CommexTable ~ data", data)
  const [SelectAll, setSelectAll] = useState({
    first: false,
    second: false,
    frsAry: [],
    secAry: [],
  });

  const handleChange1 = (e) => {
    let ary = [...SelectAll.frsAry];
    if (!ary.includes(e.target.name)) {
      ary.push(e.target.name);
    } else {
      ary = ary.filter((d) => d !== e.target.name);
    }

    // let list = {...SelectAll}
    // list[e.target.name]=e.target.checked
    // list.second=false
    setSelectAll({ ...SelectAll, frsAry: ary });
  };
  const handleChange2 = (e) => {
    let ary = [...SelectAll.secAry];
    if (!ary.includes(e.target.name)) {
      ary.push(e.target.name);
    } else {
      ary = ary.filter((d) => d !== e.target.name);
    }
    // let list = {...SelectAll}
    // list[e.target.name]=e.target.checked
    // list.second=false
    setSelectAll({ ...SelectAll, secAry: ary });
  };

  function createData(Branch, Commex, ClearingID, apipre, apiprep, apibranch) {
    return { Branch, Commex, ClearingID, apipre, apiprep, apibranch };
  }
  const rows = [
    createData("Off line Branches", "NCDEX", "", "Ncdex", "NcdexClgID", "OffLineBranches"),
    createData("CommNet", "MCX", "", "Mcx", "McxClgID", "CommNet"),
    createData("Website", "NSEL", "", "Nsel", "NselClgID", "Website"),
    createData("eSign", "AHM-NMCE", "", "AhmNmce", "AhmNmceClgID", "ESign"),
    createData("", "ICEX", "", "Icex", "IcexClgID"),
    createData("", "NSX", "", "Nsx", "NsxClgID"),
    createData("", "ACE", "", "Ace", "AceClgID"),
  ];
  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-5 mb-10	">
        
        <table className={`${windowSize >= 430 ? "w-[100%]" : "w-[560px]"} overflow-scroll text-sm text-center text-gray-500  w-full`}>
          {/* <thead className="text-xs  text-gray-700 uppercase  ">
            <tr className="bg-[#F3F7FC]">
              
              <th classNameName="border-l">
                <div className="flex items-center justify-left pl-6">
                  <div className="px-4">
                    <input disabled
                      id="first"
                      type="checkbox"
                      value=""
                      name="second"
                      className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded  "
                      onClick={() => {
                        if (SelectAll.second) {
                          setSelectAll({
                            ...SelectAll,
                            second: !SelectAll.second, secAry: []
                          });
                        } else {
                          setSelectAll({
                            ...SelectAll,
                            second: !SelectAll.second,
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="p-2  text-start text-[#3D3D3D]">
                    Select All
                  </div>
                </div>
              </th>
              <th></th>
              <th scope="" className="px-6 py-3 text-center border-l">
                Clearing ID
              </th>
            </tr>
          </thead> */}
          <tbody>
            <tr>
              <th rowSpan={1} colSpan={8}>
              <div className="text-xs text-white capitalize bg-[#3491FF]">
          <div className="flex items-center justify-center py-3">
          <input
              checked={data?.Commex == "Y"}
              className="w-[18px] h-[18px] mr-[5px] items-center"
              type="checkbox" 
            />
            <div className="text-lg font-medium normal-case">Commex</div>
          </div>
        </div>
              </th>
            </tr>
            <tr>
              <th> </th>
              {rows.map((row, i) => (
                <th className=" border-l pt-2">
                  <div className="flex-inline items-center align-middle">
                    <div className="px-1 inline-block">
                      <input
                        // disabled
                        id="default-checkbox"
                        name={row.Commex}
                        type="checkbox"
                        checked={
                          data?.[`${row.apipre}`] == "Y" || SelectAll.second ||
                          SelectAll.secAry.includes(row.Commex)
                        }
                        value=""
                        className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded"
                        // onChange={handleChange2}
                      />
                    </div>
                    <div className="p-2 text-start text-[#3D3D3D] inline-block">
                      {row.Commex}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              <th scope="" className="px-6 py-3 text-center border-l text-[14px] font-normal text-[#000000]">
                Clearing ID
              </th>
              {rows.map((row, i) => (
              <th className="p-2 border-l">
                  <div className="flex justify-start  ">
                    <input disabled
                      type="text"
                      value={data?.[row.apiprep]}
                      id="visitors"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer      "
                      placeholder=""
                      required
                    />
                  </div>
                  </th>
              ))}
            </tr>
            <tr className="">
              {/* <th>
                <div className="text-sm font-medium text-right">
                  Participant ID
                </div>
              </th> */}
              {/* <th>
                <div className="flex px-2 justify-center mb-4">
                  <input disabled
                    type="number"
                    id="visitors"
                    className=" block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
                    placeholder=""
                    required
                  />
                </div>
              </th> */}
            </tr>
            <hr></hr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CommexTable;
