import React from 'react';


export default function PMSSection({data}) {
    function createData(Branch, Commex, ClearingID) {
        return { Branch, Commex, ClearingID};
      }

    const rows = [
     
        createData("Latest exe","MCX"),
    ];
  return (
    <>
    
    <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-5 mb-10	">
        <div className="text-xs text-white capitalize bg-[#3491FF] w-100">
            <div className='flex items-center justify-center py-3'>
                <div className='text-lg font-medium'>PMS</div>
            </div>
        </div>
            <table className="w-[1365px] overflow-scroll text-sm text-center text-gray-500 dark:text-gray-400">
            <thead className="text-xs  text-gray-700 capitalize  ">
            <tr className="bg-[#F3F7FC]">
              
              <th classNameName="border-l">
                {/* <div className="flex items-center justify-left pl-6">
                  <div className="px-4">
                    <input disabled
                      id="first"
                      type="checkbox"
                      value=""
                      name="second"
                      className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded  "
                      onClick={() => {
                        if (SelectAll.second) {
                          setSelectAll({
                            ...SelectAll,
                            second: !SelectAll.second, secAry: []
                          });
                        } else {
                          setSelectAll({
                            ...SelectAll,
                            second: !SelectAll.second,
                          });
                        }
                      }}
                    />
                  </div>
                  <div className="p-2  text-start text-[#3D3D3D]">
                    Select All
                  </div>
                </div> */}
              </th>
              <th></th>
              <th scope="col" className="px-6 py-3 text-center border-l">
                Clearing ID
              </th>
            </tr>
        </thead>
                <tbody>
                    {rows.map((row,i)=>(
                        <tr className={`${i%2!==0&&'bg-[#F3F7FC]'}`}>
                            <th className=''>
                                <div className='flex items-center justify-left pl-6 py-5'>
                                    <div className='flex px-2'>
                                        <input id={"default-checkbox-"+i} type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 rounded   dark:bg-[#3491FF]" />
                                    </div>
                                    <div className='px-3 text-[#3491FF] text-sm font-medium'>  
                                    <label for={"default-checkbox-"+i}>
                                        {row.Branch}
                                    </label>
                                    </div>
                                </div>
                            </th>
                            <th className="w-1/5"></th>
                <th className="p-2 border-l w-1/5">
                  <div className="flex justify-start ">
                    <input disabled
                      type="text"
                      value={data?.[row.apipre]}
                      id="visitors"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer      "
                      placeholder=""
                      required
                    />
                  </div>
                </th>
                        </tr>
                    ))}
                    <tr className="">
              <th></th>
              <th>
                <div className="text-sm font-medium text-right">
                  Participant ID
                </div>
              </th>
              <th>
                <div className="flex px-2 justify-center mb-4">
                  <input disabled
                    type="number"
                    value={data?.ParticipantID}
                    name="ParticipantID"
                    id="visitors"
                    className=" block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
                    placeholder=""
                    required
                  />
                </div>
              </th>
            </tr>
                </tbody>
            </table>
        </div>
    </div></>


  )
}
