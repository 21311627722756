import React from 'react'
import './accounts.css';

function Accounts() {
    return (
        <>
            <section className="text-gray-600 body-font bg-[#ffffff]">
                <div className="container 2xl:px-5 xl:px-5 lg:px-5 md:px-5 sm:px-5 px-0 py-6 mx-auto">
                    <div className="flex flex-col text-center w-full mb-5">
                        <h1 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-3 pb-0 text-[#0A2A4A] text-center">Accounting</h1>
                    </div>
                    <div className="flex flex-wrap">
                        {/* <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center p-4 ">
                               <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-inside-1_1133_14086" fill="white">
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                    </mask>
                                    <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                    <mask id="path-2-inside-2_1133_14086" fill="white">
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                    </mask>
                                    <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                    <mask id="path-3-inside-3_1133_14086" fill="white">
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                    </mask>
                                    <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                    <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#00C673" stop-opacity="0.8" />
                                            <stop offset="1" stop-color="#1F75DA" />
                                        </linearGradient>
                                    </defs>
                                    </svg><span className="text-gray-500 circle-text">01</span>
                               </div>
                                <div className="flex-grow mr-2 w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] text-[18px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">Includes powerful accounting software rich with functions of a full fledge accounting software.</p>
                                </div>
                            </div>
                        </div> */}


                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className="relative lg:top-[27px] lg:right-[-33px] lg:text-[30px] md:top-[27px] md:right-[-33px] md:text-[28px] top-[21px] right-[-25px] text-[21px] text-[#ffffff] font-semibold">
                                        01
                                    </span>
                                </div>
                                <div className="flex-grow ml-3 w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[15px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">Includes powerful accounting software rich with functions of a full fledge accounting software.</p>
                                </div>
                            </div>
                        </div>




                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className="relative lg:top-[27px] lg:right-[-33px] lg:text-[30px] md:top-[27px] md:right-[-33px] md:text-[28px] top-[21px] right-[-25px] text-[21px] text-[#ffffff] font-semibold">
                                        05
                                        </span>
                                </div>
                                <div className="flex-grow w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[15px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">Bank reconciliation facility.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className="relative lg:top-[27px] lg:right-[-33px] lg:text-[30px] md:top-[27px] md:right-[-33px] md:text-[28px] top-[21px] right-[-25px] text-[21px] text-[#ffffff] font-semibold">
                                        02
                                    </span>
                                </div>
                                <div className="flex-grow ml-3 w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] text-[15px] md:text-[18px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">Facility to print all types of voucher on-line.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className="relative lg:top-[27px] lg:right-[-33px] lg:text-[30px] md:top-[27px] md:right-[-33px] md:text-[28px] top-[21px] right-[-25px] text-[21px] text-[#ffffff] font-semibold">
                                        06
                                    </span>
                                </div>                                <div className="flex-grow w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] text-[15px] md:text-[18px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">Facility to import collection file in XLS file, this file may have been received branches or broking section.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className="relative lg:top-[27px] lg:right-[-33px] lg:text-[30px] md:top-[27px] md:right-[-33px] md:text-[28px] top-[21px] right-[-25px] text-[21px] text-[#ffffff] font-semibold">
                                        03
                                    </span>
                                </div>                               <div className="flex-grow w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] text-[15px] md:text-[18px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">Handling account schedules in accordance with Schedule VI of Companies Act.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className="relative lg:top-[27px] lg:right-[-33px] lg:text-[30px] md:top-[27px] md:right-[-33px] md:text-[28px] top-[21px] right-[-25px] text-[21px] text-[#ffffff] font-semibold">
                                        07
                                    </span>
                                </div>
                                <div className="flex-grow lg:h-28 md:h-28 h-[23rem] w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] md:text-[18px] text-[15px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">For TradePlus users – facility to transfer debit to TradePlus by database to database transfer before making payout from TradePlus, this transfer is without any file export / import and can be done to the extent of credit available in TradePlus ledger and entry in both databases carried out simultaneously.</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center lg:mt-0 md:mt-0 mt-[32px] p-4 rounded-lg">
                            <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="path-1-inside-1_1133_14086" fill="white">
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                    </mask>
                                    <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                    <mask id="path-2-inside-2_1133_14086" fill="white">
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                    </mask>
                                    <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                    <mask id="path-3-inside-3_1133_14086" fill="white">
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                    </mask>
                                    <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                    <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#00C673" stop-opacity="0.8" />
                                            <stop offset="1" stop-color="#1F75DA" />
                                        </linearGradient>
                                    </defs>
                                    </svg><span className="text-gray-500 circle-text">04</span>
                               </div>                                 
                               <div className="flex-grow w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] text-[18px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">All reports, ledgers, balance sheets on Dot Matrix / Page Printers.</p>
                                </div>
                            </div>
                        </div> */}


                        <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
                            <div className="h-full flex items-center  2xl:p-4 xl:p-4 lg:p-4 md:p-4 sm:p-4 p-0 rounded-lg">
                                <div className='relative garident-circle w-[25%]'>
                                    <svg className="absolute lg:w-[100%] lg:h-[100%] w-[74px] lg:top-0" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_1133_14086" fill="white">
                                            <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" />
                                        </mask>
                                        <path d="M141.814 82.0596C141.814 70.1306 138.198 58.4818 131.443 48.6498C124.689 38.8177 115.112 31.2641 103.977 26.9854C92.8414 22.7067 80.6708 21.9039 69.07 24.6828C57.4693 27.4618 46.9834 33.6919 38.9954 42.5515L82.8145 82.0596H141.814Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-1-inside-1_1133_14086)" />
                                        <mask id="path-2-inside-2_1133_14086" fill="white">
                                            <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" />
                                        </mask>
                                        <path d="M33.8396 48.6658C27.0954 58.5053 23.4922 70.158 23.5053 82.087C23.5184 94.0159 27.1471 105.661 33.9129 115.485C40.6787 125.31 50.2636 132.853 61.4035 137.12C72.5434 141.386 84.7149 142.176 96.3126 139.384L82.5053 82.0223L33.8396 48.6658Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-2-inside-2_1133_14086)" />
                                        <mask id="path-3-inside-3_1133_14086" fill="white">
                                            <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" />
                                        </mask>
                                        <path d="M100.857 137.914C111.528 134.295 120.939 127.696 127.978 118.898C135.017 110.1 139.389 99.4698 140.578 88.265L81.9075 82.04L100.857 137.914Z" stroke="#CBEEF7" stroke-width="28" mask="url(#path-3-inside-3_1133_14086)" />
                                        <circle cx="82.8145" cy="82.0439" r="45" fill="url(#paint0_linear_1133_14086)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_1133_14086" x1="26.0183" y1="37.0439" x2="102.475" y2="121.801" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00C673" stop-opacity="0.8" />
                                                <stop offset="1" stop-color="#1F75DA" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className="relative lg:top-[27px] lg:right-[-33px] lg:text-[30px] md:top-[27px] md:right-[-33px] md:text-[28px] top-[21px] right-[-25px] text-[21px] text-[#ffffff] font-semibold">
                                        04
                                    </span>
                                </div>
                                <div className="flex-grow ml-3 w-[75%]">
                                    <p className="text-[#808D9A] lg:text-[18px] text-[15px] md:text-[18px] font-small 2xl:ml-[2rem] xl:ml-[2rem] md:ml-[2rem] sm:ml-[2rem] lg:ml-[2rem] ml-0 lg:p-8 p-6 bg-[#F4FAFF]">All reports, ledgers, balance sheets on Dot Matrix / Page Printers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Accounts