

import { webUrl } from "../utils/Utils";
// const BASE_URL = 'https://tpluswebsite.azurewebsites.net/';
const BASE_URL = window.GLOBAL_API_CLIENT;

const date_url = 'https://tplus.in/download/File.php';

export const PostAPI = async (url, data, headers = {}) => {
  try {
    // headers['Content-Type'] = 'application/json';
    // headers['token'] = headers.token
    let res = await fetch(BASE_URL + url, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    if (res.statusText == "Unauthorized") { 
      localStorage.removeItem("trade_plus_token");
      window.location.href = webUrl + "downloads"
    }
    if (res.ok) {
      let resultData = await res.json();
      return { status: resultData.status || resultData.Status , result: resultData }
    } else {
      return { status: false, msg: 'Cannot Fetch API', result: [] }
    }
  } catch (e) {
    return { status: false, msg: 'Something Went Wrong error: ' + e.message, result: [] }
  }
}
export const GetAPI = async (url, data = '', headers = {}) => {
  try {
    let res = await fetch(BASE_URL + url + data, {
      method: 'GET',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem('trade_plus_token')
      },
    })
    if (res.statusText == "Unauthorized") {
      localStorage.removeItem("trade_plus_token");
      window.location.href = webUrl + "downloads"
    }
    if (res.ok) {
      let resultData = await res.json();
      return { status: resultData.Status || resultData.Status , result:  resultData.Data  }
    } else {
      return { status: false, msg: 'API Have Error', result: [] }
    }
  } catch (e) {
    return { status: false, msg: 'Something Went Wrong error: ' + e.message, result: [] }
  }
}
export const PasswordChange = async (data) => {
  try {
    let res = await fetch(BASE_URL + 'Tplus/ChangePassword', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem('trade_plus_token'),
      },
      body: JSON.stringify(data)
    });
    if (res.statusText == "Unauthorized") {
      localStorage.removeItem("trade_plus_token");
      window.location.href = webUrl + "downloads"

    }
    if (res.ok) {
      let resultData = await res.json();
      return { status: resultData.status || resultData.Status , result: resultData }
    } else {
      return { status: false, msg: 'Cannot Fetch API', result: [] }
    }
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message, result: [] }
  }
}
export const PostMethod = async (url, data, heders = {}) => {
  try {
    let res = await fetch(BASE_URL + url, {
      method: 'POST',
      headers: {
        ...heders,
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem('trade_plus_token'),
      },
      body: JSON.stringify(data)
    });

    if (res.statusText == "Unauthorized") {
      localStorage.removeItem("trade_plus_token");
      window.location.href = webUrl + "downloads"

    }
    if (res.ok) {
      let resultData = await res.json();
      return { status: resultData.status || resultData.Status , result: resultData }
    } else {
      return { status: false, msg: 'Cannot Fetch API', result: [] }
    }
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message, result: [] }
  }
}
export const Circularsapi = async (data) => {
  console.log("🚀 ~ file: Service.js ~ line 112 ~ Circularsapi ~ data", data)
  try {
    let res = await fetch(BASE_URL + 'Tplus/Circulars', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem('trade_plus_token'),
      },
      body: JSON.stringify(data)
    });
    if (res.statusText == "Unauthorized") {
      localStorage.removeItem("trade_plus_token");
      window.location.href = webUrl + "downloads"
    }
    if (res.ok) {
      let resultData = await res.json();
      return { status: resultData.status || resultData.Status , result: resultData }
    } else {
      return { status: false, msg: 'Cannot Fetch API', result: [] }
    }
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message, result: [] }
  }
}
export const Modificationapi = async (data) => {
  console.log("🚀 ~ file: Service.js ~ line 139 ~ Modificationapi ~ data", data)
  try {
    let res = await fetch(BASE_URL + 'Tplus/Modification', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem('trade_plus_token'),
      },
      body: JSON.stringify(data)
    });
    if (res.statusText == "Unauthorized") {
      localStorage.removeItem("trade_plus_token");
      window.location.href = webUrl + "downloads"
    }
    if (res.ok) {
      let resultData = await res.json();
      return { status: resultData.status || resultData.Status , result: resultData }
    } else {
      return { status: false, msg: 'Cannot Fetch API', result: [] }
    }
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message, result: [] }
  }
}

export const Notificationapi = async (data) => {
  console.log("🚀 ~ file: Service.js ~ line 160 ~ Notificationapi ~ data", data)
  try {
    let res = await fetch(BASE_URL + `Tplus/Notice?Product=${data}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        'Authorization': "Bearer " + localStorage.getItem('trade_plus_token'),
      },
      body: JSON.stringify(data)
    });
    if (res.statusText == "Unauthorized") {
      localStorage.removeItem("trade_plus_token");
      window.location.href = webUrl + "downloads"
    }
    if (res.ok) {
      let resultData = await res.json();
      return { status: resultData.status || resultData.Status , result: resultData }
    } else {
      return { status: false, msg: 'Cannot Fetch API', result: [] }
    }
  } catch (e) {
    return { status: false, msg: 'Something went Wrong Error: ' + e.message, result: [] }
  }
}

export const DateAndTime = async (headers = {}) => {
  try {
    let res = await fetch(date_url, {
      method: 'GET',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem('trade_plus_token')
      },
    })
    if (res.statusText == "Unauthorized") {
      localStorage.removeItem("trade_plus_token");
      window.location.href = webUrl + "downloads"
    }
    if (res.ok) {
      let resultData = await res.json();
      return { status: resultData.Status || resultData.Status , result:  resultData.Data  }
    } else {
      return { status: false, msg: 'API Have Error', result: [] }
    }
  } catch (e) {
    return { status: false, msg: 'Something Went Wrong error: ' + e.message, result: [] }
  }
}


// export const PasswordChange = async(url, data = '', headers = {}) => {
//     try {
//         let res = await fetch(BASE_URL + url + data, {
//             method: 'POST',
//             headers: {
//                 ...headers,
//                 'Content-Type': 'application/json',
//                 Authorization: "Bearer "+ localStorage.getItem('trade_plus_token')
//             },
//         })

//         if(res == "Unauthorized"){
//             localStorage.removeItem("trade_plus_token");
//          }

//         if (res.ok) {
//             let resultData = await res.json();
//             return { status: true, result: resultData }
//         } else {
//             return { status: false, msg: 'API Have Error', result: [] }
//         }
//     } catch (e) {
//         return { status: false, msg: 'Something Went Wrong error: ' + e.message, result: [] }
//     }
// }