import React from 'react';
const SegIMg  = require('./img/seg-img.png');

function Statements() {
    return (
        <>
            <section className="text-gray-600 body-font">
                <div className=' text-center'>
                    <h2 className='title text-lg lg:text-2xl font-bold sm:text-2xl md:text-2xl pt-8 pb-0 text-[#0A2A4A] text-center'>Statements</h2>
                </div>
                <div className="container px-10 pb-10  mx-auto flex flex-wrap">
                <div className="flex flex-wrap  w-full justify-center">
                        <div className="p-4 md:w-1/2 sm:w-1/2">
                        <div className='relative lg:w-full md:w-full w-[293px] lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                            <img src={SegIMg} className="lg:absolute md:absolute inherit lg:h-auto md:h-auto h-[167px]" />
                                <div className="absolute px-[2rem] xl:top-[38%] lg:top-[16%] md:top-[5%] top-[15%]">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[18px] text-[12px] font-medium text-center pt-4">Statement of account may be updated on daily basis and may also display Pay-in / Pay-out details from latest settlement along with scheduled pay-in / pay-out date.</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 md:w-1/2 sm:w-1/2">
                        <div className='relative lg:w-full md:w-full w-[293px] lg:h-[15rem] md:h-[15rem] h-[10rem]'>
                            <img src={SegIMg} className="lg:absolute md:absolute inherit lg:h-auto md:h-auto h-[167px]" />
                                <div className="absolute px-[2rem] xl:top-[38%] lg:top-[16%] md:top-[8%] top-[21%]">
                                    <p className="leading-relaxed text-[#808D9A] lg:text-[18px] md:text-[18px] text-[12px] font-medium text-center pt-4">By referring to ledger, a remote client may at his end verify as to the remittance he has made is being reflected under his account or not.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Statements