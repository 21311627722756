import React from "react";
const ractangle = require("./img/security-rectangle.svg");
const securityLine = require("./img/securityLine.png");

const Poligon = ({ className, counting }) => {
  return (
    <>
      <div className={className}>
        <svg
          className="w-[80px] md:w-[91px] h-[80px] md:h-[103px]"
          viewBox="0 0 91 103"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45.5 0L90.1003 25.75V77.25L45.5 103L0.899693 77.25V25.75L45.5 0Z"
            fill="url(#paint0_linear_1133_14051)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1133_14051"
              x1="-6"
              y1="0"
              x2="101.292"
              y2="96.3241"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#92DCDF"/>
              <stop offset="0.0001" stop-color="#DEFFF1"/>
              <stop offset="1" stop-color="#B0D3FE"/>
            </linearGradient>
          </defs>
        </svg>
        <span className="counting absolute top-[15px] lg:right-0 md:right-0 right-[21px] md:top-[1.7rem] lg:top-[1.7rem] sm:left-[1.5rem] md:left-[1.7rem] lg:left-[1.7rem] text-[2rem] text-[#00C673]  font-semibold ">
          {counting}
        </span>
      </div>
    </>
  );
};
const LineImg = ({ className }) => {
  return (
    <span
      className={`border-dashed border-t-[5px] border-[#0080F6]  ${className} end-point sm:hidden md:block lg:block`}
    ></span>
  );
};
const Security = () => {
  return (
    <>
      <section id="security-section ">
        <div className="container mx-auto">
          <div className="title-box text-center">
            <h2 className="title text-lg lg:text-2xl font-bold sm:text-3xl md:text-3xl pt-8 pb-0 text-[#0A2A4A]">
              Security
            </h2>
          </div>
          <div className="side-section   grid grid-cols-1 w-full">
            <div className="relative w-full lg:px-10 md:px-10 px-2  mt-[30px]">
              <div className="flex justify-left items-center pb-3">
                <Poligon className="relative" counting="01" />
                <LineImg className=" w-[33rem] lg:block md:block hidden relative"/>
                <p className="w-[60%] text-[#808D9A]  lg:text-[18px] md:text-[18px] text-[14px] ml-[2rem] mt-5">
                  Password for each user along with authority / restriction
                  setting for individual activity.
                </p>
              </div>
              <div className="flex justify-left items-center pb-3">
                <Poligon className="md:ml-[5rem] relative" counting="02"/>
                <LineImg className=" w-[28rem] lg:block md:block hidden relative"/>
                <p className="w-[60%] text-[#808D9A]  lg:text-[18px] md:text-[18px] text-[14px] ml-[2rem] mt-5">
                  Stores client code as in trade file of exchange for
                  verification against unauthorized modification.
                </p>
              </div>
              <div className="flex justify-left items-center pb-3">
                <Poligon className="md:ml-[9rem] relative" counting="03" />
                <LineImg className=" w-[24rem] lg:block md:block hidden relative"/>
                <p className="w-[60%] text-[#808D9A]  lg:text-[18px] md:text-[18px] text-[14px] ml-[2rem] mt-5">
                  Security of client / server and RDBMS Database.
                </p>
              </div>
              <div className="flex justify-left items-center pb-3">
                <Poligon className="md:ml-[5rem] relative" counting="04" />
                <LineImg className=" w-[28rem] lg:block md:block hidden relative"/>
                <p className="w-[60%] text-[#808D9A]  lg:text-[18px] md:text-[18px] text-[14px] ml-[2rem] mt-5">
                  Automatic locking of settlement on generation of bills.
                </p>
              </div>
              <div className="flex justify-left items-center pb-3">
                <Poligon className="relative" counting="05"/>
                <LineImg className=" w-[33rem] lg:block md:block hidden relative" />
                <p className="w-[60%] text-[#808D9A]  lg:text-[18px] md:text-[18px] text-[14px] ml-[2rem] mt-5">
                  Each user may be restricted / permitted to access data of
                  selected company/exchange.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Security;
