import React,{useEffect, useState} from 'react';
import { GetAPI } from '../../../hooks/Service';

const LicenceKey = () => {
  const [LicenseData, setLicense] = useState({});
  const [loading, setLoading] = useState(false)

  const data = JSON.parse(localStorage.getItem("ProfileTab"))

  useEffect(() => {
    let token = localStorage.getItem('trade_plus_token');
    setLoading(true)
    const CallFun = async () => {
        let headers = {
            Authorization: "Bearer " + token
        }
        let params = '';
        let resultResponse = await GetAPI('Tplus/License', params, headers);
        if (resultResponse.status) {
            setLicense(resultResponse.result)
            setLoading(false)
        }else{
          setLoading(false)
        }
    }

    if (token) {
        CallFun();
    }
}, [])

  return (
   
        <div className='back-forword-btn 2xl:mx-10 xl:mx-10 lg:mx-10 md:mx-10 sm:mx-10 mx-5 2xl:mt-10 xl:mt-10 lg:mt-10 md:mt-10 sm:mt-10 mt-2 2xl:mb-12 xl:mb-12 lg:mb-12 md:mb-12 sm:mb-12 mb-6 2xl:p-8 xl:p-8 lg:p-8 md:p-8 sm:p-8 p-4'>
          {loading ? (
            <div role="status" className="position-loading">
            <svg
              aria-hidden="true"
              className="flex w-12 h-12 items-center justify-center text-gray-200 animate-spin fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
          ):(<div>
            <div   className='text-[#3491FF] 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[14px ]	mb-4 '>Last Updated On {LicenseData?.LastUpdatedDt} at {LicenseData?.LastUpdatedTime}</div>
            <div  className='2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[14px ]	text-[#3D3D3D]	 mb-2'>The License Key in : {LicenseData?.LicenseKey} </div>
            {/* <div className='text-[#D13129] mb-4 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[12px  ] mt-4 font-medium'>1234 5678 9012 3456 7890</div> */}
            <div className='text-[#3491FF] mb-4 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[14px  ] mt-3'>The Key is generated based on your details Registered with us.<div>Inform us at earliest if any discrepancy in the information.</div></div>
            <div className='2xl:flex xl:flex lg:flex md:flex'>
              <div className='mr-1 2xl:mb-4 xl:mb-4 lg:mb-4 md:mb-4 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[14px  ]	text-[#3D3D3D] font-medium'>Same key is to be used for all products used by</div>
              <div className='text-[#3491FF] 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0 mb-4 2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[14px ]	'>{data?.ClientName}</div>
            </div>
            <div className='2xl:text-[18px] xl:text-[18px] lg:text-[18px] md:text-[16px] sm:text-[14px] text-[14px  ]	text-[#3D3D3D]'>As per our records, payment is not received by us, hence a limited period License key has been generated, and therefore you will be able to use products till <span className='font-bold	'>{LicenseData?.TillDate}</span> without any problem</div>
          </div>)}
        </div>
  )
}

export default LicenceKey