import React from 'react';
import Header from '../components/common/Header/';
import Footer from "../components/common/Footer/Index";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {
    return ( < >

        <Header />
        <div className = "" > { props.children } </div> 
        <ToastContainer />
        <Footer />
        </>
    )
}
export default Layout;