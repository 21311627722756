import React, { useState } from "react";
import Slider from "react-slick";
import tradeweb from "../../components/common/Images/trade-web.png"
import tradeweb1 from "../../components/common/Images/trade-web1.png"
import tradeweb2 from "../../components/common/Images/trade-web2.png"
import tradeweb3 from "../../components/common/Images/trade-web3.png"
import tradeweb4 from "../../components/common/Images/trade-web4.png"
import { LazyLoadImage } from "react-lazy-load-image-component";





const BannerList = [
    { title: "Thanks to technology, Stock Brokers now can expand their operations over a larger geographical region. Now broker can have their branch or sub-broker working in a remote village anywhere in India. Now a sub-broker does not have to tell his broker to buy/sell a share. He functions independently within the trading limit set for him." },
    { title: "So far as trading goes, this is perfect, but what when a sub-broker or branch manager wants to verify their brokerage, deliveries, and other charges that a broker has levied are matching with his data or for that why should an occasional investor keep on dialing to get his broker connected or on other hand, why should a broker occupy best of employees into answering calls from clients for confirmations, bills, and ledgers?" },
    { title: "Internet, unmatched connectivity as of today has been put to use by SecMark in ‘TradeWeb’ so at to give much-required accessibility to back-office data of stock brokers, even at night or on holidays, for clients to help themselves" },

]
const Banner = () => {
  const [left, setLeft] = useState(0)
  console.log("🚀 ~ file: Banner.js ~ line 10 ~ Banner ~ left", left)
  const [right, setRight] = useState(1)
  console.log("🚀 ~ file: Banner.js ~ line 12 ~ Banner ~ right", right)


  const handleLeftIcon = () => {
    setLeft(left + 1)
    setRight(right - 1)
  }

  const handleRightIcon = () => {
    setLeft(left - 1)
    setRight(right + 1)

  }
  const BackArrow = (props) => {
    return (
        <button type="button" onClick={()=>{props.onClick();handleLeftIcon()}} className={`${(left < 5 && left < 0)     ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute left-[-5%] top-[50%]" :"stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[-5%] top-[50%]" }`}>
        <svg  width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        </button>
    )
}

const ForwardArrow = (props) => {
    return (
        <button type="button" onClick={()=>{props.onClick();handleRightIcon()}}className={`${right > 0 && right < 5   ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -ml-5 z-50 text-blue-700 border hover:bg-[#ffffff] shadow-lg  hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute lg:right-[-3%] md:right-[-3%] right-[-15%] lg:top-[45%] md:top-[45%] top-[43%] " :  "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn  bg-white -ml-5 z-50 text-blue-700 border shadow-lg hover:bg-[#3491FF]   hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute lg:right-[-3%] md:right-[-3%] right-[-15%] lg:top-[45%] md:top-[45%] top-[43%]"}`}>
      <svg className="" width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      </button>
    )
}
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        initialSlide: 0,
        nextArrow: <ForwardArrow />,
        prevArrow: <BackArrow />,
    };
    return (
        <div className="hero-banner  bg-[#F5FAFE]" >
            <div className='container flex flex-wrap justify-between items-center pl-10 pr-10  mx-auto relative overflow-hidden  mt-0'>
                <main className=" md:py-10   px-4 md:px- ">
                    <div className="grid gap-8 grid-cols-1  lg:grid-cols-2  ">
                        <div className="text-center lg:text-left md:text-left">
                            <h1 className="text-3xl tracking-tight font-Lexend text-[#0A2A4A] font-semibold md:leading-3 lg:mx-auto  pb-5 
                            ">
                                TradeWeb
                            </h1>
                            <p className="text-[17px] lg:text-left md:text-left text-center lg:leading-4 md:leading-4 tracking-tight font-Lexend text-[#0A2A4A] lg:text-xl md:text-lg font-medium  sm:mx-auto   lg:mx-0">
                                Back office software for individual stock brokers
                            </p>
                            <ul className='list-disc'>
                                {BannerList.map((single, index) => (
                                    <li key={index} className="mt-3 lg:text-[18px] text-gray-500 sm:mt-5 text-[15px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0">
                                        {single.title}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="lg:ml-[40px] lg:w-[100%] md:ml-[40px] md:w-[100%]">
                        <LazyLoadImage
                                src='/assets/img/Rectangle-tp.svg'
                                alt="banner-img"
                                className=" absolute lg:top-[0px] md:top-0 md:ml-[9rem] lg:ml-[9rem] lg:h-auto lg:-left-5 md:-left-4  md:h-auto h-[165px] right-[9rem] lg:right-0 md:right-0 top-[47px]"
                                effect="blur"
                            />
                            <LazyLoadImage
                                src='/assets/img/Rectangle-botm.png'
                                alt="banner-img"
                                effect="blur"
                                className="absolute lg:bottom-[-5rem] lg:ml-[28rem] md:bottom-[7rem] 
                                min-h-0 
                                h-[189px]
                                md:ml-3 
                                lg:w-[150px]
                                md:h-[300px] 
                                lg:h-[350px] 
                                 lg:top-[21.6rem]
                                 md:top-[21.6rem]
                                 top-[13.6rem]  
                                 lg:left-0
                                 md:left-0
                                 left-[9rem]
                                "
                            />
                            <Slider {...settings}>
                                <div>
                                <div className="lg:mb-10 lg:mt-[6px] md:mb-10 md:mt-[6px] mt-0 mb-0 ">
                                      <p className="text-[#00C673] font-semibold  text-center flex justify-center text-[25px] m-0  ">
                                      Dashboard</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>
                                    <LazyLoadImage
                                        src={tradeweb}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain"
                                        effect="blur"
                                    />
                                </div>
                                <div>
                                <div className="lg:mb-10 lg:mt-[6px] md:mb-10 md:mt-[6px] mt-0 mb-0 ">
                                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Ledger</p>
                                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                </div>                                    <LazyLoadImage
                                        src={tradeweb1}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain"
                                        effect="blur"
                                    />
                                </div>
                                <div>
                                <div className="lg:mb-10 lg:mt-[6px] md:mb-10 md:mt-[6px] mt-0 mb-0 ">
                                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Transaction</p>
                                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                </div>                                      <LazyLoadImage
                                        src={tradeweb2}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain"
                                    />
                                </div>
                                <div>
                                <div className="lg:mb-10 lg:mt-[6px] md:mb-10 md:mt-[6px] mt-0 mb-0 ">
                                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Holdings</p>
                                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                </div>                                     <LazyLoadImage
                                        src={tradeweb3}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain"
                                        effect="blur"
                                    />
                                </div>
                                <div>
                                <div className="lg:mb-10 lg:mt-[6px] md:mb-10 md:mt-[6px] mt-0 mb-0 ">
                                    <p className="text-[#00C673] font-semibold text-center flex justify-center text-[25px] m-0 ">Client master</p>
                                    <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                </div>                                    <LazyLoadImage
                                        src={tradeweb4}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain"
                                        effect="blur"
                                    />
                                </div>
                                {/* <img
  src="/assets/img/banner-img.svg"
  alt="banner-img"
  className="w-100"
/> */}
                            </Slider>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
export default Banner;