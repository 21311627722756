
import React,{useEffect,useState} from 'react';
import OtherSection from './OtherSection';
import Accordion from './Accordion';

import Banners from './Banners';
import Table from './Table';
import TechnicalDetails from './TechnicalDetails';
// import OtherAssociate from './OtherAssociate';
import Esign from './Esign';


function ProductEsign
() {

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
},[])

useEffect(() => {
  function handleWindowResize() {
    setWindowSize(getWindowSize());
  }

  window.addEventListener('resize', handleWindowResize);

  return () => {
    window.removeEventListener('resize', handleWindowResize);
  };
}, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}


  return (
    <>
    <Banners/>
    <Esign/>
    <Accordion windowSize={windowSize?.innerWidth}/>
   {/* <OtherAssociate/> */}
  </>
  )
}

export default ProductEsign;