import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import moment from "moment";
import { Circularsapi, Modificationapi } from "../../../hooks/Service";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import jsPDF from "jspdf";
import Paginate from "./Pagination";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { Input, Space } from "antd";
import DataGrid, {
  Column,
  Button,
  Selection,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  MasterDetail,
} from "devextreme-react/data-grid";
import HandleDetails from "./HandleDetails";
import useFileDownloader from "../../../hooks/useFileDownloader";
import { toast } from "react-toastify";
import Downloader from "./Downloader";

const { Search } = Input;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "90vh",
  overflowX: "auto",
};

const CircularAndMOdification = ({
  openModal,
  onclose,
  maintitle,
  subtitle,
  MyDownloadFN,
}) => {
  const { files, downloadFile, remove } = useFileDownloader();
  const DownloadZipFile = (file) => {
    let obj = {
      name: file?.split("/")?.reverse()?.[0],
      file: file,
      filename: file?.split("/")?.reverse()?.[0],
    };
    if (file) {
      downloadFile(obj);
      // toast.success("File Downloaded", {
      //   progress: undefined,
      //   autoClose: 5000,
      // })
    } else {
      toast.warning("File Not Found", {
        progress: undefined,
        autoClose: 5000,
      });
    }

    // file ? downloadFile(obj):

    //   toast.error("invalid Password", {
    //     progress: undefined,
    //     autoClose: 5000,
    //   })
  };

  const [open, setOpen] = React.useState(openModal);
  const [collapsed, setCollapsed] = useState(false);
  const [list, setList] = useState([]);
  const [apibody, setapiBody] = useState("");
  // console.log("🚀 ~ file: CircularAndMOdification.js ~ line 53 ~ apibody", apibody)
  const [loading, setloading] = useState(false);
  const [check, setCheck] = useState(false);

  //   const [QueriesData, setQueries] = useState([]);
  const [pagData, setPageData] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onclose(false);
  };

  const circulars = async () => {
    setloading(true);
    let circularData = await Circularsapi({ product: subtitle });
    if (circularData.status) {
      const data = circularData?.result?.Data.filter((val) => {
        if (!val.AttachName.includes(".pdf")) {
          return val;
        }
      });
      setList(data);
      setloading(false);
    }
  };
  const modification = async () => {
    setloading(true);
    let modificationData = await Modificationapi({ product: subtitle });
    if (modificationData.status) {
      setList(modificationData?.result?.Data);
    }
    setloading(false);
  };

  useEffect(() => {
    setOpen(openModal);
    // MyDownloadFN('demo.zip','10')
  }, [openModal]);

  useEffect(() => {
    if (maintitle == "Circulars") {
      openModal && circulars(subtitle);
    }
    if (maintitle == "Modifications") {
      openModal && modification(subtitle);
    }
  }, [openModal]);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  // useEffect(()=>{pageChange(1)},[list])
  const pageChange = (page) => {
    var start = (page - 1) * 9;
    var end = start + 9;
    let data = [...list].slice(start, end);
    setPageData(data);
  };
  function getStringBetween(str, start, end) {
    const result = str.match(new RegExp(start + "(.*)" + end));

    return result[1];
  }

  // useEffect(()=>{
  //   let date = list.map((d)=>{
  //     let newdare = d.Date.split("/")
  //     newdare = newdare[2] + '-' + newdare[1] + '-' + newdare[0];
  //     return {...d,'Date':newdare}
  //   })
  //   setList(date)
  // },[list.length])

  /* 
<a href="http://www.TPlus.in/Circulars/circular151.zip">Click here</a> to download Circular  explaining procedures to be carried out for Corporate action of Bonus in Larsen -n- Toubro 
(NSE Symbol: LT)

*/
  const showBody = (body) => {
    if (body == apibody) {
      setapiBody("");
    } else {
      setapiBody(body);
    }
  };
  const onSearch = (value) => console.log(value);
  const download = (url) => {
    DownloadZipFile(url);
    return;
  };

  // useEffect(()=>{pageChange(1)},[pagData])
  const onContentReady = (e) => {
    if (!collapsed) {
      e.component.expandRow(["EnviroCare"]);
      setCollapsed(true);
    }
  };

  const onRowPre = (e) => {
    if (e.rowType == "header") {
      //   e.rowElement.style.display = "none";
      e.rowElement.style.backgroundColor = "black !important";
      e.rowElement.style.fontSize = "1rem";
      e.rowElement.style.lineHeight = "1.5rem";
      //     font-size: 1rem;
      // line-height: 1.5rem;
    }
    if (e.rowType == "data") {
      e.rowElement.style.margin = "15px";
      e.rowElement.style.fontStyle = "medium";
      e.rowElement.style.color = "#3D3D3D";
      e.rowElement.style.lineHeight = "35px";
      e.rowElement.style.fontWeight = 400;
      if (e.rowIndex % 2 === 0) {
        e.rowElement.style.backgroundColor = "#E1F1FF";
      }
    }
  };
  const DownloadBtn = (id, AttachYN) => {
    return (
      <p style={{ fontSize: "12px" }} className="text-blue-500 cursor-pointer">
        {maintitle !== "Modifications" && AttachYN === "Yes" ? (
          <a
            href={`http://www.TPlus.in/Circulars/circular${id}.zip`}
            download
            target="_blank"
          >
            <DownloadIcon />
          </a>
        ) : (
          "No FIle"
        )}
      </p>
    );
  };

  function stripHtml(html) {
    if (!html) return "";
    let tmp = document.createElement("DIV");
    // html = html.replace("nbsp", '')
    // html = html.replace("nbsp;", '')

    html = html.toString().replace("&nbsp", "");
    console.log(
      "🚀 ~ file: CircularAndMOdification.js ~ line 196 ~ html",
      html
    );

    tmp.innerHTML = html;

    var text = tmp.textContent || tmp.innerText || "";

    console.log(
      "🚀 ~ file: CircularAndMOdification.js ~ line 183 ~ html",
      text,
      "DWMO"
    );

    return text;
  }
  const renderHtml = (Details) => {
    const text = stripHtml(Details);
    const data = text.replace(/\s+/g, " ");
    console.log(
      "🚀 ~ file: CircularAndMOdification.js ~ line 209 ~ renderHtml ~ text",
      text
    );
    console.log(
      "🚀 ~ file: CircularAndMOdification.js ~ line 210 ~ renderHtml ~ data",
      data
    );
    return <p className="text-left">{data}</p>;
  };

  const [openIndex, setOPenIndex] = useState("");

  const deatils = (Details, data, downloadzip,srNo) => {
    // console.log(data);

    if (maintitle === "Modifications") {
      return <p>{stripHtml(Details)} </p>;
    } else {
      return (
        <HandleDetails
          Details={Details}
          MoreDetails={data.Body}
          openIndex={openIndex}
          // srNo={data.SrNo || srNo}
          title={subtitle}
          DownloadZipFile={DownloadZipFile}
          srNo={srNo}
          setOPenIndex={setOPenIndex}

        />
      );
      // return (<p>
      //   {stripHtml(Details)}
      //   <p dangerouslySetInnerHTML={{__html:data.Body}}></p>
      //   <button  onClick={()=>{setCheck(true)}} className="btn text-blue-500 ml-2 cursor-pointer" >Click</button>
      // </p>)
    }
  };

  const ActiveBtn = (id) => {
    return (
      <>
        <button
          className="btn  text-black"
          onClick={() => setOPenIndex(id == openIndex ? false : id)}
        >
          {id != openIndex ? (
            <svg
              className="rotate"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-down"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-up"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"
              />
            </svg>
          )}
        </button>
      </>
    );
  };

  // const renderBtn = (data) => {
  //   return (<p>sdjfgsdfjk</p>)
  //   // <MasterDetail
  //   //       enabled={false}
  //   //       component={({data}) => {
  //   //         console.log("🚀 ~ file: CircularAndMOdification.js ~ line 265 ~ data", data)
  //   //         return (<p dangerouslySetInnerHTML={{__html:data.data.Body}}></p>)

  //   //       }}
  //   //     />
  // }

  const viewDate = (date) => {
    let newdate = date.replace(/\//g, "-");
    newdate = newdate.split("-");
    newdate = newdate[2] + "/" + newdate[1] + "/" + newdate[0];

    newdate = moment(new Date(newdate)).format("DD/MM/yyyy");
    console.log(
      "🚀 ~ file: MyQueryTable.js ~ line 119 ~ viewDate ~ newdate",
      newdate
    );
    return newdate;
  };

  const contentReady = (e) => {
    if (!e.component.getSelectedRowKeys().length) {
      e.component.selectRowsByIndexes(0);
    }
  };

  const selectionChanged = (e) => {
    e.component.collapseAll(-1);
    e.component.expandRow(e.currentSelectedRowKeys[0]);
  };

  const circluarBtn = (data) => {
    return <p></p>;
  };
  return (
    <div>
      <Modal
        className="w-full overflow-x-auto full-width-modal"
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between mb-4 w-[100%]">
            <div className="flex justify-start font-semibold text-2xl items-center text-blue-800 w-[70%] capitilize">
              {maintitle}
            </div>
            <div className="flex justify-between items-center w-[30%]">
              <div></div>
              <div>
                <CloseIcon onClick={onclose} className="cursor-pointer" />
              </div>
            </div>
          </div>
          <hr />
          <>
            {loading ? (
              <div role="status" className="loading-pannel">
                <svg
                  aria-hidden="true"
                  className="flex w-12 h-12 items-center justify-center text-gray-200 animate-spin fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className="sm:rounded-lg">
                <DataGrid
                  dataSource={list}
                  allowColumnReordering={true}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  onContentReady={onContentReady}
                  onRowPrepared={onRowPre}
                  wordWrapEnabled={true}
                  keyExpr="SrNo"
                  // onSelectionChanged={selectionChanged}
                  className="word-wrap"
                  // width={1300}
                >
                  {/* <GroupPanel visible={true} /> */}
                  <SearchPanel visible={true} highlightCaseSensitive={true} />
                  {/* <Grouping autoExpandAll={false} /> */}
                  {maintitle !== "Modifications" && <Selection mode="single" />}

                  {/* {check && <MasterDetail enabled={true} component={({data}) => {
        {maintitle !== "Modifications" && <MasterDetail enabled={true} component={({data}) => {
            return (<p dangerouslySetInnerHTML={{__html:data.data.Body}}></p>)
          }} />} */}

                  {/* {maintitle !== "Modifications" && <MasterDetail
          enabled={true}
          component={({data}) => {
            console.log("🚀 ~ file: CircularAndMOdification.js ~ line 265 ~ data", data)
            return (<p dangerouslySetInnerHTML={{__html:data.data.Body}}></p>)
            
          }}
        />} */}

                  {maintitle !== "Modifications" ? (
                    <Column
                      dataField="SrNo"
                      caption="Sr No"
                      width={80}
                      dataType="number"
                      alignment="left"
                    />
                  ) : (
                    ""
                  )}
                  <Column
                    dataField="SrNo"
                    caption="Date"
                    width={150}
                    defaultSortOrder={"desc"}
                    dataType="number"
                    alignment="left"
                    cellRender={(e) => viewDate(e.data.Date)}
                    hidingPriority={2}
                  />
                  <Column
                    dataField={maintitle === "Modifications" ? "Menu" : "Time"}
                    caption={maintitle === "Modifications" ? "Menu" : "Time"}
                    dataType="string"
                    // width={maintitle === "Modifications" ? "300px" : "80px"}
                    width={150}
                    alignment="left"
                    cellRender={(e) => renderHtml(e.data.Menu || e.data.Time)}
                    allowSorting={false}
                    hidingPriority={1}
                  />
                  <Column
                    dataField={
                      maintitle === "Modifications" ? "Details" : "Subject"
                    }
                    caption={
                      maintitle === "Modifications" ? "Details" : "Subject"
                    }
                    // width={"100%"}
                    alignment="left"
                    wordWrapEnabled={true}
                    cellRender={(e) =>deatils(e?.data?.Details || e?.data?.Subject,e?.data,DownloadZipFile,e.data.SrNo)}
                    allowSorting={false}
                    hidingPriority={0}
                    
                  />
                  {/* <Button name="edit" /> */}
                  {/* </Column> */}

                  {maintitle !== "Modifications" ? (
                    <Column
                      dataField="Action"
                      caption="Action"
                      dataType="string"
                      alignment="center"
                      width={80}
                      allowSorting={false}
                      cellRender={(e) => ActiveBtn(e.data.SrNo)}
                    />
                  ) : (
                    ""
                  )}
                  <Pager allowedPageSizes={"10"} showPageSizeSelector={false} />
                  <Paging defaultPageSize={10} />
                </DataGrid>
                {/* <table className="w-full text-sm text-center text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white capitalize bg-[#3491FF] ">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-center lg:text-base text-sm ">SrNo</th>
                        <th scope="col" className="px-6 py-3 text-center lg:text-base text-sm">Date</th>
                        <th scope="col" className="px-6 py-3 text-center lg:text-base text-sm">{maintitle == "Modifications"?"Menu":"Time"}</th>
                        <th scope="col" className="px-6 py-3 text-center lg:text-base text-sm">{maintitle == "Modifications"?"Details":"Subject"}</th>
                        <th scope="col" className="px-6 py-3 text-center lg:text-base text-sm">AttachName</th>
                        {maintitle == "Circulars" && <th scope="col" className="px-6 py-3 text-center lg:text-base text-sm">Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {pagData?.length ? pagData?.map((d,i)=>(
                      <>  
                        <tr class={`${i%2==0&&'bg-[#F3F7FC]'}`}>
                          <th class='py-3 border-l text-[#3D3D3D] text-[12px]'><td>{d.SrNo}</td></th>
                          <th class='py-3 border-l text-[#3D3D3D] text-[12px]'>{d.Date}</th>
                          {maintitle == "Modifications"? <th class='py-3 border-l text-[#3D3D3D] text-[12px] max-w-[200px] px-3  break-words' dangerouslySetInnerHTML={{__html: d.Menu}}></th>: 
                          <th class='py-3 border-l text-[#3D3D3D] text-[12px]' >{d.Time}</th>}
                          {maintitle == "Modifications"? <th class='py-3 border-l text-[#3D3D3D] text-[12px] max-w-[200px] px-3  break-words' dangerouslySetInnerHTML={{__html: d.Details}}></th>: 
                          <th class='py-3 border-l text-[#3D3D3D] text-[12px]' >{d.Subject}</th>}
                          <th>{maintitle !== "Modifications" && d.AttachYN==='Yes' ?   <a href={`http://www.TPlus.in/Circulars/circular${d.SrNo}.zip`}  download target="_blank"><DownloadIcon /></a>:"No FIle"}</th>
                          <th onClick={() => { d.Body && showBody(d.SrNo)}} class='py-3 border-l text-[12px]'>{d.Body && <ArrowForwardIosIcon className={apibody!==d.SrNo ? "rotate180": "rotate90"}/>}</th>
                        </tr>
                        {apibody==d.SrNo &&<tr><th colSpan="6" className="p-[12px]" dangerouslySetInnerHTML={{__html: d.Body}}></th></tr>}
                      </>
                    )):
                    <div className="flex justify-center mt-10">
                      <div className="loading-pannel font-semibold text-2xl items-center text-blue-800">No data Found</div>
                    </div>}
                </tbody>
           </table> */}
              </div>
            )}
          </>
        </Box>
      </Modal>
      {files.length ? (
        <div className=" fixed bg-slate-300  bottom-20 left-2 lg:w-[20%] md:w-[20%]  w-[80%] px-6 rounded-md z-[999999999999] ">
          <div className="w-[100%] flex justify-end"></div>
          <Downloader files={files} remove={remove} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CircularAndMOdification;
