import React from 'react';
import './singleInvoice.scss';
import Microsoftimg from "../../../../components/common/Images/MicrosoftTeams-image (1).png";

const SingleInvoice  = ({BillInfo})=>{
    const [data,setData] = React.useState("");

    React.useEffect(()=>{
      try{
        let data1 = JSON.parse(localStorage.getItem("ProfileTab"));
        setData(data1)
      }catch(e){

      }
    },[])

 

    return (
        <>

  <div className="pdf-page size-a4 single-invoice-table back-forword-btn rounded-2xl px-5" 
  id="divcontents"
  >


  <div className="gird grid-cols-1 mb-6 justify-right">
      <img src={Microsoftimg} className="ml-auto w-[300px]" alt="secmark-logo" />
      <div className="" >
      <div align="right" rowspan={4} className="text-right">
        <p className='text-right'>

          <font size={2}  className="text-right">
            CIN No : <b>L67190MH2011PLC220404</b>
            <br/>
            B-102 1st Floor Rustomjee Central Park 
            <br />
            Chakala Junction  Opp. Kanakia Wall Street 
            <br />
            Andheri Kurla Road Andheri (East) Mumbai-400069
            <br />
            E-mail: info@Tplus.in
            <br/> 
            URL: www.Tplus.in
            <br />
            LLP IN: AAO-3933
          </font>
        </p>
        </div>
      </div>
      
  </div>

  <table align="center"  width="100%" className='body-table'>
    <tbody>
      <tr className='ml-4'>
        <td rowSpan={4} className="text-align-top pl-2">
          {BillInfo?.CompanyName}
          <br />
          {BillInfo?.Add1}
          <br />
          {BillInfo?.Add2}
          <br />
          {BillInfo?.Add3}
          <br />
          {BillInfo?.City}-{BillInfo?.Pin}
        </td>
        <td align="right">Bill No : &nbsp;</td>
        <td align="left" nowrap="nowrap">
          <b>&nbsp;{BillInfo?.BillNo}</b>
        </td>
        
      </tr>

        <tr>
            <td align="right">Date : &nbsp;</td>
            <td align="left">
            <b>&nbsp;{BillInfo?.BillDate}</b>
            </td>
        </tr>
      
        <tr>
            <td align="right">AMC Due From :</td>
            <td align="left">
            <b>&nbsp;{BillInfo?.FromDate}</b>
            </td>
        </tr>

        <tr>
            <td align="right">Installation : &nbsp;</td>
            <td align="left">
            <b>&nbsp;{BillInfo?.ToDate}</b>
            </td>
        </tr> 
    </tbody>
  </table>

  <table className='w-full' >
    <tbody>
    <tr className='ring-0 bg-[#3491FF] text-white px-20 bg-tr'>
        <td colSpan={2}>
          <b>Description </b>
        </td>
        <td align="right">
          <b>Rupees </b>
        </td>
      </tr>
      <tr className='h[150px]'>
        <td colSpan={2} className="pl-2">
            <div dangerouslySetInnerHTML={{__html: BillInfo?.Description}} ></div>
          <p>
            Your GST ID:<b>&nbsp;{BillInfo?.YourGSTID}</b>
          </p>
        </td>
        <td align="right">{BillInfo?.Amount}</td>
      </tr>
      <tr>
        <td align="right" colSpan={2}>

          {BillInfo?.SGST ? <>
          SGST Collected @ 9%
          <br />
          </>:""}

          {BillInfo?.CGST ? <>
          CGST Collected @ 9%
          <br />
          </>:""}


          {BillInfo?.IGST ? <>
          IGST Collected @ 18%
          <br />
          </>:""}
          
          {BillInfo?.UGST?<>
          UGST Collected @ 18%
          <br />
          </>:""}




        </td>
        <td align="right">

            { BillInfo?.SGST ? <>{BillInfo?.SGST}</> : ""}
            { BillInfo?.CGST ? <><br/>{BillInfo?.CGST}</> : ""}
            { BillInfo?.IGST ? <><br/>{BillInfo?.IGST}</> : ""}
            { BillInfo?.UGST ? <><br/>{BillInfo?.UGST}</> : ""}

        </td>
      </tr>
      <tr>
        <td colSpan={2} className="pl-2">{BillInfo?.TotalInWords}</td>
        <td align="right">
          <b>{BillInfo?.Total}</b>
        </td>
      </tr>
    </tbody>
  </table>
  <table align="center" width="100%" className='no-border-table'>
    <tbody>
      <tr>
        <td className="pl-2">
          <font size={2}>E&amp;OE</font>
        </td>
        <td align="right" className="pl-2">
          <font size={2}>{data?.ClientCode}&nbsp;</font>
        </td>
      </tr>
      <tr>
        <td colSpan={2} className="pl-2">
        {BillInfo?.GSTText}
        </td>
      </tr>
      <tr>
        <td colSpan={2} className="pl-2">
          {BillInfo?.CategoryText}
        </td>
      </tr>
  
   

      <tr>
        <td>
          <font size={2} className="pl-2">
          Subject to Mumbai Jurisdiction only. 
          </font>
        </td>
        <td align="right" className="pl-2">
          <font size={2}>SECMARK CONSULTANCY LTD.</font>

        </td>
      </tr>

      <tr>
        <td>
          <font size={2} className="pl-2">
          This being computer generated statement, not signed.
          </font>
        </td>
        <td align="right">
          <font size={2}>PAN: {BillInfo?.PanNo}</font>
        </td>
      </tr>
      </tbody>
  
  </table>

  </div>

            

        </>
    )
}
export default SingleInvoice;