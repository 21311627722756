import React, { useState, useEffect } from "react";
import { PostAPI, GetAPI } from "../../../hooks/Service";
import BottomTabs from "./BottomTabs";
import ProductTable from "./ProductTable";
import ProductTabs from "./ProductTabs";
import SliderCard from "./SliderCard";
import Table from "./Table";
import useFileDownloader from "../../../hooks/useFileDownloader";
import Downloader from "./Downloader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProfileSliderCard } from "./SliderCard";

const MyProfileForm = (props) => {
  const [profile, setprofile] = useState("True");
  const [Details, setDetails] = useState("True");
  const [check, setcheck] = useState();
  const [Detailcheck, setDetailcheck] = useState();
  const [checkCard, setCheckCard] = useState({});
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState({
    accountcode: "",
    name: "",
    adderess: "",
    city: "",
    pincode: "",
    company: "",
    clientname: "",
    clientname2: "",
    panno: "",
    provisionalid: "",
    provisionalid2: "",
  });
  console.log(
    "🚀 ~ file: MyProfileForm.js ~ line 31 ~ MyProfileForm ~ data",
    data
  );
  const [SendData, setSendData] = useState();
  // const Profile={"ClientCode":"A090","ClientName":"A.C.Choksi Share Brokers Pvt.Ltd.","Address1":"IITS House, 2nd Floor, 33 Saibaba Marg,","Address2":"Kala Ghoda, Fort","Address3":"","City":"MUMBAI","Pincode":"400023","ContactPerson":[{"Name":"Purnendu S. Brahmbhatt","EmailID":"account@acchoksi.com","MobileNo":"22 6159 5114/5151"},{"Name":"Bimal","EmailID":"bimal@acchoksi.com","MobileNo":"22 6159 5100"},{"Name":"Shruti D. Indule","EmailID":"compliance@acchoksi.com","MobileNo":"9769459210"},{"Name":"Aniyan K. George","EmailID":"depository@acchoksi.com","MobileNo":"022-6159 5171 - 73"},{"Name":"Kenneth J. Paulose","EmailID":"kenneth@acchoksi.com","MobileNo":"99677 99993 / 81088 99993"},{"Name":"Sayaji S. Surve","EmailID":"kyc@acchoksi.com","MobileNo":"022-6159 5164"},{"Name":"Vipul K. Joshi","EmailID":"operation@acchoksi.com","MobileNo":"91 22 6159 5161-5162"},{"Name":"Varsha V. Joshi","EmailID":"pms@acchoksi.com","MobileNo":""},{"Name":"Hemant K. Kansara","EmailID":"rm@acchoksi.com","MobileNo":"61595137/9323766140/8208064869"},{"Name":"Varsha V. Joshi","EmailID":"varsha@acchoksi.com","MobileNo":"22 6159 5170"}],"TradePlus":{"TradePlus":"Y","TradeNet":"Y","Website":"Y","ESign":"Y","NseCash":"Y","NseFO":"Y","NseFX":"N","NseClgID":"9929","BseCash":"Y","BseFO":"Y","BseFX":"N","BseClgID":"1","MCXCash":"N","MCXFO":"N","MCXFX":"N","McxClgID":"0"},"Commex":{"Commex":"N","OffLineBranches":"N","CommNet":"N","Website":"N","ESign":"N","Ncdex":"N","NcdexClgID":"0","Mcx":"N","McxClgID":"0","Nsel":"N","NselClgID":"0","AhmNmce":"N","AhmNmceClgID":"0","Icex":"N","IcexClgID":"0","Nsx":"N","NsxClgID":"0","Ace":"N","AceClgID":"0"},"Cross":{"Cross":"Y","CrossNet":"Y","Website":"Y","ESign":"Y","ParticipantID":"28400"},"Estro":{"Estro":"N","EstroNet":"N","Website":"N","ESign":"N","ParticipantID":""},"OtherCompanies":null}
  const Senddata = () => {
    setSendData(data);
  };
  const { files, downloadFile, remove } = useFileDownloader();
  const DownloadZipFile = (file) => {
    console.log("Clicked ", file, window.browser);
    let obj = {
      name: file?.split("/")?.reverse()?.[0],
      file: file,
      filename: file?.split("/")?.reverse()?.[0],
    };
    if (file) {
      downloadFile(obj);
      toast.success("File Downloaded", {
        progress: undefined,
        autoClose: 5000,
      });
    } else {
      toast.warning("File Not Found", {
        progress: undefined,
        autoClose: 5000,
      });
    }
  };
  useEffect(() => {
    let token = localStorage.getItem("trade_plus_token");
    setloading(true);
    const CallFun = async () => {
      let headers = {
        Authorization: "Bearer " + token,
      };
      let params = "";
      let resultResponse = await GetAPI("Tplus/Profile", params, headers);
      if (resultResponse.status) {
        setdata(resultResponse.result);
        // localStorage.setItem("ProfileTab",JSON.stringify(resultResponse?.result))
        console.log(
          "🚀 ~ file: MyProfileForm.js ~ line 70 ~ CallFun ~ resultResponse.result",
          resultResponse?.result
        );
        setCheckCard(resultResponse?.result);
        setloading(false);
      }
    };
    if (token) {
      CallFun();
    }
  }, []);
  const storeData = (e) => {
    let Obj = { ...data };
    Obj[e.target.name] = e.target.value;
    setdata({ ...Obj });
  };
  const handlechange = () => {
    if (validate(data)) {
      setcheck(validate(data));
    } else {
      setcheck("");
      setprofile("True");
    }
  };
  const validate = (values) => {
    let errors;
    if (
      !values.accountcode ||
      !values.name ||
      !values.adderess ||
      !values.city ||
      !values.pincode ||
      !values.company
    ) {
      errors = "All Field Are Reqire...";
    }
    return errors;
  };
  const changehandle = () => {
    if (Detailvalidate(data)) {
      setDetailcheck(Detailvalidate(data));
    } else {
      setDetailcheck("");
      setDetails("True");
    }
  };
  const Detailvalidate = (values) => {
    let errors;
    if (
      !values.clientname ||
      !values.clientname2 ||
      !values.panno ||
      !values.provisionalid ||
      !values.provisionalid2
    ) {
      errors = "All Field Are Reqire...";
    }
    return errors;
  };
  return (
    <div className="border back-forword-btn rounded-2xl 2xl:m-7 xl:m-7 lg:m-7 md:m-7 m-3  md:m-10">
      {loading ? (
        <div role="status" className="position-loading">
          <svg
            aria-hidden="true"
            className="flex w-12 h-12 items-center justify-center text-gray-200 animate-spin fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div>
          {/* {JSON.stringify(Profile)} */}
          <div className="flex justify-between px-5 lg:px-12 py-4">
            <div className="font-semibold lg:text-xl md:text-xl text-[19px] text-left  text-[#3D3D3D]">
              My Profile
            </div>
            <div className="font-medium text-xl text-end text-[#3D3D3D] cursor-pointer flex">
              {/* <div> {profile == "True"?<svg onClick={()=>{setprofile("false")}}  xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>:<svg className= ' p-2' onClick={handlechange} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                          </svg>
                    } 
                    </div> */}
            </div>
          </div>
          <hr className="pb-4 border-t-[#EBEBEB]"></hr>

          <div className={`2xl:flex xl:flex md:flex flex-wrap	 justify-between pb-6 ${ profile == "false" ? " input-border-all" : ""}`}>
            
              <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 w-[100%] lg:px-12 px-4">
                <div className="flex items-center">
                  <div className="capitalize  font-medium justify-start w-2/5 lg:text-[18px] text-[14] text-[#3D3D3D]">
                    *Account Code
                  </div>
                  <input
                    value={data?.ClientCode || ""}
                    disabled={profile == "True" ? true : false}
                    name="ClientCode"
                    className="input-border w-3/5 bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline justify-end lg:text-[18px] text-[14]"
                    type="number"
                    placeholder={data?.ClientCode || ""}
                    onChange={storeData}
                  />
                </div>
                <div className="flex items-center">
                  <div className="capitalize  font-medium w-2/5 text-[#3D3D3D] lg:text-[18px] text-[14]">
                    *Name
                  </div>
                  <input
                    value={data?.ClientName || ""}
                    disabled={profile == "True" ? true : false}
                    name="ClientName"
                    className="input-border w-3/5 bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                    type="text"
                    placeholder={data?.ClientName || ""}
                    onChange={storeData}
                  />
                </div>
                <div className="flex items-center">
                  <div className="capitalize  font-medium w-2/5 text-[#3D3D3D] lg:text-[18px] text-[14]">
                    *Address
                  </div>
                  <input
                    value={data?.Address1 || ""}
                    disabled={profile == "True" ? true : false}
                    name="Address1"
                    className="input-border w-3/5 bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                    type="text"
                    placeholder={data?.Address1 || ""}
                    onChange={storeData}
                  />
                </div>
                {check && (
                  <div className="flex items-center mt-1">
                    <p className="w-2/5"></p>
                    <p className="text-red-500 mx-2 w-3/5">{check}</p>
                  </div>  
                )}

              
            </div>
            <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 w-[100%] lg:px-12 px-4	">
                <div className="flex items-center">
                  <div className="capitalize  font-medium w-2/5 text-[#3D3D3D] lg:text-[18px] text-[14]">
                    *City
                  </div>
                  <input
                    value={data?.City || ""}
                    disabled={profile == "True" ? true : false}
                    name="City"
                    className="input-border w-3/5 bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                    type="text"
                    placeholder={data?.City || ""}
                    onChange={storeData}
                  />
                </div>
                <div className="flex items-center">
                  <div className="capitalize  font-medium w-2/5 text-[#3D3D3D] lg:text-[18px] text-[14]">
                    *Pin Code
                  </div>
                  <input
                    value={data?.Pincode || ""}
                    disabled={profile == "True" ? true : false}
                    name="Pincode"
                    className="input-border w-3/5 bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                    type="number"
                    placeholder={data?.Pincode || ""}
                    onChange={storeData}
                  />
                </div>
                <div className="flex items-center">
                  <div className="capitalize  font-medium w-2/5 text-[#3D3D3D] lg:text-[18px] text-[14]">
                    *Other Companies
                  </div>
                  <input
                    value={
                      data?.OtherCompanies?.map(
                        (d) => d?.CompanyName
                      )?.toString() || ""
                    }
                    disabled={profile == "True" ? true : false}
                    name="OtherCompanies"
                    className="input-border w-3/5 bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                    type="text"
                    placeholder=""
                    onChange={storeData}
                  />
                </div>
                <div className="flex items-center">
                  <div className="capitalize  font-medium w-2/5 text-[#3D3D3D] lg:text-[18px] text-[14]">
                    *Other Companies code
                  </div>
                  <input
                    value={
                      data?.OtherCompanies?.map(
                        (d) => d?.CompanyCode
                      )?.toString() || ""
                    }
                    disabled={profile == "True" ? true : false}
                    name="OtherCompanies"
                    className="input-border w-3/5 bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                    type="text"
                    placeholder={data?.OtherCompaniesCode || ""}
                    onChange={storeData}
                  />
                </div>
                <div className="flex items-right justify-right">
                  <p
                    className="underline cursor-pointer	 underline-offset-1 text-[#3491FF] lg:text-lg md:text-lg text-[14px] mx-3 ml-auto "
                    onClick={() => {
                      props.setcurrentTab("changepasswordprofile");
                    }}
                  >
                    Change Password
                  </p>
                </div>
              </div>
            
          </div>
          <hr className=" border-t-[#EBEBEB]"></hr>
          <div className="flex justify-between px-5 lg:px-12  py-6">
            <div className="font-semibold lg:text-xl md:text-xl text-[19px] text-left  text-[#3D3D3D]">
              Provide your GST details
            </div>
            {/* <div className='font-medium lg:text-xl md:text-xl text-[17px] text-end text-[#3D3D3D] cursor-pointer'>
                {Details == "True"?<svg onClick={()=>{setDetails("false")}}  xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                        </svg>:<svg className= ' p-2' onClick={changehandle} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zm8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"/>
                          </svg>
                    }    
                </div> */}
          </div>
          <hr className="pb-4 border-t-[#EBEBEB]"></hr>
          <div
            className={`2xl:flex xl:flex lg:flex md:flex flex-wrap justify-between ${
              Details == "false" ? " input-border-all" : ""
            }`}
          >
            <div className="2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-1/3 w-[100%] lg:px-12 px-4">
              <div className="block items-center">
                <div className="capitalize lg:text-[18px] text-[14]  font-medium justify-start w-2/5  text-[#3D3D3D]">
                  *Client name
                </div>
                <input
                  value={data?.ClientName1 || ""}
                  disabled={Details == "True" ? true : false}
                  name="ClientName1"
                  className="input-border w-[100%] bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline justify-end lg:text-[18px] text-[14]"
                  type="text"
                  placeholder={data?.ClientName1 || ""}
                  onChange={storeData}
                />
              </div>
              <div className="block items-center">
                <div className="capitalize lg:text-[18px] text-[14]  font-medium w-2/5 text-[#3D3D3D]	"></div>
                <input
                  value={data?.ClientName2 || ""}
                  disabled={Details == "True" ? true : false}
                  name="ClientName2"
                  className="input-border w-[100%] bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                  type="text"
                  placeholder={data?.ClientName2 || ""}
                  onChange={storeData}
                />
              </div>
            </div>
            <div className="2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-1/3 w-[100%] lg:px-12 px-4	">
              <div className="block items-center">
                <div className="capitalize lg:text-[18px] text-[14]  font-medium w-2/5 text-[#3D3D3D]">
                  *Provisional ID
                </div>
                <input
                  disabled={Details == "True" ? true : false}
                  value={data?.provisionalid || ""}
                  name="provisionalid"
                  className="input-border w-[100%] bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                  type="text"
                  placeholder={data?.provisionalid || ""}
                  onChange={storeData}
                />
              </div>
              <div className="block items-center">
                <div className="capitalize lg:text-[18px] text-[14]  font-medium w-2/5 text-[#3D3D3D]	"></div>
                <input
                  disabled={Details == "True" ? true : false}
                  value={data?.provisionalid2 || ""}
                  name="provisionalid2"
                  className="input-border w-[100%] bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                  type="text"
                  placeholder={data?.provisionalid2 || ""}
                  onChange={storeData}
                />
              </div>
            </div>
            <div className="2xl:w-1/3 xl:w-1/3 lg:w-1/3 md:w-1/3 w-[100%] lg:px-12 px-4	">
              <div className="block items-center">
                <div className="capitalize lg:text-[18px] text-[14]  font-medium w-2/5 text-[#3D3D3D]	">
                  *Pan No
                </div>
                <input
                  value={data?.panno || ""}
                  disabled={Details == "True" ? true : false}
                  name="panno"
                  className="input-border w-[100%] bg-[#F4FAFF] text-[#9E9E9E] mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline lg:text-[18px] text-[14]"
                  type="text"
                  placeholder={data?.panno || ""}
                  onChange={storeData}
                />
              </div>
              {Detailcheck && (
                <div className="flex items-center mt-1">
                  <p className="w-2/5"></p>
                  <p className="text-red-500 mx-2 w-3/5">{Detailcheck}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <div> */}
      {/* <div className="font-semibold lg:text-xl md:text-xl text-[17px] text-left text-[#3D3D3D] px-5 lg:px-12 py-6">
          GST Release Document
        </div> */}
      {/* <div className="flex flex-wrap lg:grid lg:grid-cols-5 justify-around mx-10 gst-card"> */}
      {/* {data?.TradePlus?.TradePlus == "Y" && (
            <ProfileSliderCard
              subTitle="Trade Plus"
              img={"/assets/img/svg-5.png"}
              download={"/assets/zip/Tradeplus.zip"}
              buttonTxt={data?.TradePlus?.TradePlus}
              DownloadZipFile={DownloadZipFile}
              clickToDownload
            />
          )} */}
      {/* {data?.Estro?.Estro == "Y" && (
            <ProfileSliderCard
              subTitle="Estro"
              img={"/assets/img/svg-1.svg"}
              download={"/assets/zip/GST/GST_Release_Document_Estro.zip"}
              DownloadZipFile={DownloadZipFile}
              buttonTxt={data?.Estro?.Estro}
              clickToDownload
            />
          )} */}
      {/* {data?.Cross?.Cross == "Y" && (
            <ProfileSliderCard
              subTitle="Cross"
              img={"/assets/img/svg-2.png"}
              download={"/assets/zip/GST/GST_Release_Document_Cross.zip"}
              DownloadZipFile={DownloadZipFile}
              buttonTxt={data?.Cross?.Cross}
              clickToDownload
            />
          )} */}
      {/* {data?.Commex?.Commex == "Y" && (
            <ProfileSliderCard
              subTitle="Commex"
              img={"/assets/img/svg-6.png"}
              download={"/assets/zip/GST/GST_Release_Document_Commex.zip"}
              DownloadZipFile={DownloadZipFile}
              buttonTxt={data?.Commex?.Commex}
              clickToDownload
            />
          )} */}
      {/* {<ProfileSliderCard subTitle='Pms' img={'/assets/img/svg-6.png'} download={''} buttonTxt={data}  clickToDownload/>} */}
      {/* </div> */}

      <hr className=" border-t-[#EBEBEB] mt-3"></hr>
      <div className="flex justify-between px-5 lg:px-12  py-6">
        <div className="font-semibold lg:text-xl md:text-xl text-[19px] text-left  text-[#3D3D3D]">
          Contact Details
        </div>
      </div>
      <hr className="pb-4 border-t-[#EBEBEB]"></hr>

      {loading ? (
        <div role="status" className="position-loading">
        <svg
          aria-hidden="true"
          className="flex w-12 h-12 items-center justify-center text-gray-200 animate-spin fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
      ):(<div className="lg:mx-10 mx-3 ">
        <Table ContactPerson={data?.ContactPerson || []} />
      </div>)}
      {/* </div> */}
      <div className="mt-8">
        <div className="font-semibold lg:text-xl md:text-xl text-[17px] text-left lg:ml-12 ml-6 md:my-8 my-3text-[#3D3D3D]">
          Products
        </div>
        {loading ? (
          <div role="status" className="position-loading">
          <svg
            aria-hidden="true"
            className="flex w-12 h-12 items-center justify-center text-gray-200 animate-spin fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
        ):(<div className="lg:mx-10 mx-3">
          <ProductTabs TradePlus={data?.TradePlus || []} data={data} />
        </div>)}
      </div>
      {/* <div className='flex justify-end'>
            <button className="flex w-[80px] items-center text-[18px] capitalize tracking-wide bg-[#3491FF] text-white p-3 rounded-lg focus:outline-none focus:shadow-outline mb-[15px] mr-[15px]"
            onClick={Senddata}
            >
              Submit
            </button>
            </div> */}
      {files.length ? (
        <div className=" fixed bg-slate-300  bottom-20 left-2 z-50 w-[20%] px-6 rounded-md z-[999999999999] ">
          <div className="w-[100%] flex justify-end"></div>

          <Downloader
            files={files}
            //   remove={(e) => remove(e)}
            remove={remove}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default MyProfileForm;
