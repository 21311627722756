import React from "react";
import Slider from "react-slick";
import SliderCard from "./SliderCard";
import axios from "axios";
import fileDownload from "js-file-download";
import SliderWIthSwipe from "./SliderWIthSwipe";
import { Circularsapi } from "../../../hooks/Service";
import { useEffect } from "react";
import { toast } from 'react-toastify';
import { useState } from "react";
import TradePlusPopUp from "./TradePlusPopUp";
import useFileDownloader from "../../../hooks/useFileDownloader";

const BackArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      class="back-forword-btn  bg-white md:m-2 -m-5 absolute z-50 ext-blue-700 border border-blue-700 hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800left-[12%] "
    >
      <svg
        className="stroke-[#3491ff] hover:stroke-[#ffffff]"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};
const ForwardArrow = (props) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      class="back-forword-btn  bg-white m-3 z-50 text-blue-700 border border-blue-700 hover:bg-[#3491FF]   hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center
    absolute right-[4%] 
    "
    >
      <svg
        className="stroke-[#3491ff] hover:stroke-[#ffffff]"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};
const TradePluseDownload = (props) => {
  const [model, setModal] = useState("");
  const {dateData} = props



  const { files, downloadFile, remove } = useFileDownloader();
  const DownloadZipFile = (file) => {
    console.log("🚀 ~ file: Index.js ~ line 59 ~ DownloadZipFile ~ file", file)
    let obj = {
      name: file?.split("/")?.reverse()?.[0],
      file: file,
      filename: file?.split("/")?.reverse()?.[0],
    };
    console.log("🚀 ~ file: Index.js ~ line 65 ~ DownloadZipFile ~ obj", obj)
    if (file) {
      downloadFile(obj);
    } else {
      toast.warning("File Not Found", {
        progress: undefined,
        autoClose: 5000,
      });
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    nextArrow: <ForwardArrow />,
    prevArrow: <BackArrow />,
  };
  const [checkObj, setCheckObj] = useState({});
  // const openmodel = () => {
  //   props.setopen(true)
  // }
  const downloadfile = () => {
    let url = `/assets/zip/Bill/Compliance_Check_206AB.pdf`;
    DownloadZipFile(url);
  }
  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem("ProfileTab"));
    let check = Data?.["TradePlus"];
    setCheckObj(check);
  }, []);
  const slider2 = [checkObj?.TradeNet, checkObj?.Website];
  return (
    <>
          {checkObj.TradePlus === "Y" && (
        <div className="test-back">
        <>
          <div className=" py-10">
          <div id="trade-plus-download-section text-center">
            <div className="font-medium lg:text-3xl md:text-3xl text-[24px] mb-4	text-center text-[#0A2A4A]">
              TradePlus & TradeNet
            </div>
            <div className="lg:text-[18px] mg:text-[18px] text-[16px] text-center text-[#808D9A] lg:pb-0 md:pb-0 pb-4 font-normal px-8">
              Facilitates in-depth reports and trade screens while ensuring
              security for each individual user.
            </div>
            <div className="mb-1 lg:text-[18px] mg:text-[18px] text-[14px] text-center text-[#808D9A] font-normal px-8">
            {
              props?.Result?.map((d,i)=>{
                return (
                  i==0 && <div dangerouslySetInnerHTML={{__html: d?.result?.Data?.Message}}></div>
                )
              })
            }
            </div>
          </div>
          <div
            className="grid sm:grid-cols-2 md:grid-cols-4 justify-between mx-10 download-sliders"
            id="trade-download-slider"
            >
            {![checkObj.TradePlus, checkObj.ESign].every((d) => d == "N") && (
              <div className=" " >
                <h2 className=" text-white text-lg text-center rounded-tl rounded-bl bg-[#3491FF] py-3 m-0 " >Head Office</h2>
                <div className="table-auto w-full  whitespace-no-wrap p-0 m-0 border-r-2" >
                  <div className="bg-[#3491]  odd-color lg:mb-0 md:mb-0 mb-6" >
                    <div className={`${checkObj?.TradePlus=="N" && "hidden"}`}>
                      <div>    
                        <SliderCard
                        subTitle="TradePlusX "
                        img={"/assets/img/svg-1.svg"}
                        arrow={true} 
                        date={dateData.length > 0 && dateData?.find(a => a?.[1] == "tradeplus/TradeplusX.zip")?.[2]}
                        download={"/download/tradeplus/TradeplusX.zip"}
                        DownloadZipFile={props.DownloadZipFile}
                        clickToDownload
                        buttonTxt={checkObj?.TradePlus}
                      />            
                      </div>
                    </div>
                    <div className={`${checkObj?.TradePlus=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="TradePlus"
                          img={"/assets/img/svg-1.svg"}
                          date= { dateData.length > 0 &&  dateData.find(a=> a[1] == "tradeplus/Tradeplus.zip")[2]}
                          arrow={true}
                          download={"/download/tradeplus/Tradeplus.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.TradePlus}
                          clickToDownload
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.TradePlus=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="Utility"
                          img={"/assets/img/svg-1.svg"}
                          arrow={true}
                          date= { dateData.length > 0 &&  dateData.find(a=> a[1] == "tradeplus/tputils.zip")[2]}
                          download={"/download/tradeplus/tputils.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.TradePlus}
                          clickToDownload
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.TradePlus=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="DB Support"
                          img={"/assets/img/svg-1.svg"}
                          date= { dateData.length > 0 &&  dateData.find(a=> a[1] == "tradeplus/Tradeplusdb.zip")[2]}
                          arrow={false}
                          download={"/download/tradeplus/Tradeplusdb.zip"}
                          dtitle={"DB Support"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={checkObj?.TradePlus}
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.ESign=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="E-Sign"
                          img={"/assets/img/svg-1.svg"}
                          date= { dateData.length > 0 &&  dateData.find(a=> a[1] == "Esignpro/esignpro.zip")[2]}
                          arrow={true}
                          download={"/download/Esignpro/esignpro.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={checkObj?.ESign}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {![checkObj.TradeNet].every((d) => d == "N") && (
              <div className=" " >
                <h2 className=" text-white text-lg text-center  bg-[#3491FF] py-3 m-0 " >Branch</h2>
                <div className="table-auto w-full h-20 whitespace-no-wrap  ">
                  <div className="bg-[#3491]   odd-color">

                  <div className={`${checkObj?.TradeNet=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="TradeNet"
                          img={"/assets/img/svg-7.svg"}
                          arrow={true}
                          date= { dateData.length > 0 &&  dateData.find(a=> a[1] == "Tradenet/Tradenet.zip")[2]}
                          download={"/download/Tradenet/Tradenet.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.TradeNet}
                          clickToDownload
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.TradeNet=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="TradeNetX"
                          img={"/assets/img/svg-7.svg"}
                          arrow={true}
                          date= { dateData.length > 0 &&  dateData.find(a=> a[1] == "Tradenet/TradeNetX.zip")[2]}
                          download={"/download/Tradenet/TradeNetX.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          buttonTxt={checkObj?.TradeNet}
                          clickToDownload
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {![checkObj.Website].every((d) => d == "N") && (
              <div>
                <h2 className="text-white text-lg text-center bg-[#3491FF] py-3 m-0 " >Individual Client</h2>
                <div className="table-auto w-full h-20   border-l-2 whitespace-no-wrap lg:mb-0 md:mb-0 mb-12">
                  <div className="bg-[#3491]  odd-color" >
                    <div className={`${checkObj?.Website=="N" && "hidden"}`}>
                      <div> <SliderCard
                        subTitle="TradeWeb"
                        img={"/assets/img/svg-7.svg"}
                        arrow={true}
                        date= { dateData.length > 0 &&  dateData.find(a=> a[1] == "misc/Tradeweb.zip")[2]}
                        download={"/download/misc/Tradeweb.zip"}
                        DownloadZipFile={props.DownloadZipFile}
                        clickToDownload
                        buttonTxt={checkObj?.Website}
                      /></div>
                    </div>
                    <div className={`${checkObj?.Website=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="TradeWebX"
                          img={"/assets/img/svg-7.svg"}
                          arrow={true}
                          download={"/download/misc/Tradeweb.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={"N"}
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.Website=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="TradeWeb API"
                          img={"/assets/img/svg-7.svg"}
                          arrow={true}
                          download={"/download/misc/Tradeweb.zip"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.Website=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle=".Net Core"
                          img={"/assets/img/svg-7.svg"}
                          arrow={true}
                          date= { dateData.length > 0 &&  dateData.find(a=> a[1] == "misc/dotnet-hosting-3.1.4-win.exe")[2]}
                          download={"/download/misc/dotnet-hosting-3.1.4-win.exe"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={checkObj?.TradePlus
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {checkObj?.TradePlus == "Y" && (
              <div className="lg:mt-0 md:mt-0 mt-8">
                <h2 className=" text-white text-lg text-center bg-[#3491FF] py-3 m-0 rounded-tr rounded-br" >    Knowledge Base</h2>
                <div className="table-auto w-full h-20 whitespace-no-wrap border-l-2 ">
                  <div className="bg-[#3491]   odd-color">

                    <div className={`${checkObj?.TradePlus=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="Circulars"
                          section="T"
                          img={"/assets/img/svg-6.svg"}
                          arrow={false}
                          download={""}
                          buttonTxt={checkObj?.TradePlus}
                          clickToDownload
                          view
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.TradePlus=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="Help"
                          img={"/assets/img/svg-6.svg"}
                          arrow={false}
                          download={true}
                          dtitle={"Trade"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={checkObj?.TradePlus}
                          view

                        // onClick={onOpen}
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.TradePlus=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="Modifications"
                          section="T"
                          img={"/assets/img/svg-6.svg"}
                          arrow={false}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          table
                          download={""}
                          buttonTxt={checkObj?.TradePlus}
                          view
                        />
                      </div>
                    </div>
                    <div className={`${checkObj?.TradePlus=="N" && "hidden"}`}>
                      <div>
                        <SliderCard
                          subTitle="TradePlusX"
                          title="TradeX"
                          img={"/assets/img/svg-6.svg"}
                          arrow={false}
                          download={true}
                          dtitle={"TradePlus-X"}
                          DownloadZipFile={props.DownloadZipFile}
                          clickToDownload
                          buttonTxt={checkObj?.TradePlus}
                          view
                        />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )}
          </div>
          <TradePlusPopUp
            openModal={model}
            checkObj={checkObj}
            onclose={() => {
              setModal(false);
            }}
            DownloadZipFile={props.DownloadZipFile}
          />
          </div>
        </>
      
    </div>)}
    </>
  );
};

export default TradePluseDownload;
