import React, { useState } from "react";

const OthersTable = ({ data }) => {
  console.log("🚀 ~ file: CommexTable.js ~ line 4 ~ CommexTable ~ data", data);
  const [SelectAll, setSelectAll] = useState({
    first: false,
    second: false,
    frsAry: [],
    secAry: [],
  });

  const handleChange1 = (e) => {
    let ary = [...SelectAll.frsAry];
    if (!ary.includes(e.target.name)) {
      ary.push(e.target.name);
    } else {
      ary = ary.filter((d) => d !== e.target.name);
    }

    // let list = {...SelectAll}
    // list[e.target.name]=e.target.checked
    // list.second=false
    setSelectAll({ ...SelectAll, frsAry: ary });
  };
  const handleChange2 = (e) => {
    let ary = [...SelectAll.secAry];
    if (!ary.includes(e.target.name)) {
      ary.push(e.target.name);
    } else {
      ary = ary.filter((d) => d !== e.target.name);
    }
    // let list = {...SelectAll}
    // list[e.target.name]=e.target.checked
    // list.second=false
    setSelectAll({ ...SelectAll, secAry: ary });
  };

  function createData(Branch) {
    return { Branch};
  }
  const rows = [
    createData("Off line Branches"),
    createData("Website"),
    // createData("eSign"),
    createData("PMS Exe"),
  ];
  return (
    <div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg my-5 mb-10	">
        <div className="text-xs text-white capitalize bg-[#3491FF]">
          <div className="flex items-center justify-center py-3">
            <div className="text-lg font-medium normal-case">Associated Modules</div>
          </div>
        </div>
        <table className="text-sm text-center text-gray-500  w-full"> 
          <thead className="text-xs  text-gray-700 uppercase  ">
            <tr className="bg-[#F3F7FC]">
              <th classNameName="border-l">
              </th>
              <th></th>
              {/* <th scope="col" className="px-6 py-3 text-center border-l">
                Clearing ID
              </th> */}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr className={`${i % 2 !== 0 && "bg-[#F3F7FC]"}`}>
                <th className=" border-l">
                  <div className="flex items-center justify-left pl-6 p-6">
                    <div className="px-4">
                      <input
                        checked ={row.Branch == "PMS Exe" ? true : false}
                        id="default-checkbox"
                        name={row.Branch}
                        type="checkbox"
                        value=""
                        className="w-4 h-4 text-blue-600 bg-[#3491FF] rounded"  
                        onChange={handleChange2}
                      />
                    </div>
                    <div className="px-3 text-[#3491FF] text-sm font-medium">{row.Branch}</div>
                    {console.log("🚀 ~ file: OthersTable.js ~ line 83 ~ OthersTable ~ row.Branch", row.Branch)}
                  </div>
                </th>
                <th></th>
                {/* <th className="p-2 w-1/5">
                  <div className="flex justify-start ">
                    <input
                      disabled
                      type="text"
                      value={data?.[row.apipre]}
                      id="visitors"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer      "
                      placeholder=""
                      required
                    />
                  </div>
                </th> */}
              </tr>
            ))}
            <tr className="">
              <th></th>
              <th>
                {/* <div className="text-sm font-medium text-right">
                  Participant ID
                </div> */}
              </th>
              <th>
                {/* <div className="flex px-2 justify-center mb-4">
                  <input
                    disabled
                    type="number"
                    id="visitors"
                    className=" block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer  "
                    placeholder=""
                    required
                  />
                </div> */}
              </th>
            </tr>
            <hr></hr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OthersTable;
