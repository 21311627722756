import React, { useState } from "react";
import { v4 as uuid } from "uuid";

const useFileDownloader = () => {
  const [files, setFiles] = useState([]);
  console.log("🚀 ~ file: useFileDownloader.js ~ line 6 ~ useFileDownloader ~ files", files)

  const downloadFile = (file) => {
    setFiles([...files, { ...file, downloadId: uuid() }]);
  };

  const remove = (removeId) =>
    setFiles([...files.filter((file) => file.downloadId !== removeId)]);

  return {
    files,
    downloadFile,
    remove,
  };
};

export default useFileDownloader;
