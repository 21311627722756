import { createSlice } from '@reduxjs/toolkit';

const initialState = { 
    value: 0,
    downloadLoading : [],
}

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    start_loader: (state) => {
      state.value = 1;
    },
    stop_loader: (state) => {
      state.value = 0;
    },
    download_loader: (state,action) =>{
        state.downloadLoading = [...state.downloadLoading,...action.payload];
    }
  },
})

// Action creators are generated for each case reducer function
export const { start_loader, stop_loader,download_loader } = loaderSlice.actions
export default loaderSlice.reducer;