import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'antd/dist/antd.min.css';
import './App.css';
import Home       from './pages/Home';
import TradePlus  from './pages/Product';
import Products   from './pages/Products';
import ProducrEstro from './pages/ProductEstro';
import ProductEsign from './pages/ProdcutEsign';
import Layout     from './layout/';
import React      from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProductCommex from './pages/ProductCommex';
import ProductCross from './pages/ProductCross';
import Download from './pages/Download';
import DownloadCard from './pages/Download/Cards/DownloadCard';
import TabsAndPilles from './pages/Download/TabsAndPilles/Index';
import TradeWeb from './pages/trade-web';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import 'react-lazy-load-image-component/src/effects/blur.css';
import BranchEstro from './pages/ProductEstro/BranchEstro';
import TradePlusTable from './pages/Download/TabsAndPilles/TradePlusTable';
import BillTable from './pages/Download/TabsAndPilles/BillTable';
// import Demo from './pages/Download/TabsAndPilles/Demo';




function App() {

  const [title,setTitle] = React.useState('Dashboard');


  const layoutWrapper = (props)=>{
    return ( <Provider store={store}> <Layout pageTitle={title}>{props}</Layout> </Provider> )
  }

  return (
    <React.StrictMode>
    <BrowserRouter>
          <Routes>
              <Route exact path="/" element={layoutWrapper(<Home />)} />
              <Route exact path="/products" element={layoutWrapper(<Products />)} />
              <Route exact path="/trade-plus" element={layoutWrapper(<TradePlus />)} />
              <Route exact path="/estro" element={layoutWrapper(<ProducrEstro />)} />
              <Route exact path="/e-sign" element={layoutWrapper(<ProductEsign />)} />
              <Route exact path="/commex" element={layoutWrapper(<ProductCommex />)} />
              <Route exact path="/cross" element={layoutWrapper(<ProductCross />)} />
              <Route exact path="/trade-web" element={layoutWrapper(<TradeWeb />)} />
              <Route exact path="/downloads" element={layoutWrapper(<Download />)} />
              <Route exact path="/downloads/:id" element={layoutWrapper(<DownloadCard />)} />
              <Route exact path="/downloads/:id/tabs" element={layoutWrapper(<TabsAndPilles />)} />
          </Routes>
    </BrowserRouter>
    </React.StrictMode>
  );  
}

export default App;