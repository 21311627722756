import React from "react";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";
import { downloadState } from "../../components/common/ProductCards/SliderData";
const BackArrow = (props) => {
  return (
    <button type="button" onClick={props.onClick} class="back-forword-btn bg-white md:-m-7 -m-5	absolute z-50	 ext-blue-700 border border-blue-700 hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center left-[-1%] ml-[0px]">
      <svg className="stroke-[#3491ff] hover:stroke-[#ffffff]  " width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
  )
}
const ForwardArrow = (props) => {
  return (
    <button type="button" onClick={props.onClick} class="back-forword-btn  bg-white z-50 text-blue-700 border border-blue-700 hover:bg-[#3491FF]   hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center   absolute right-[0%]">
      <svg className="stroke-[#3491ff] hover:stroke-[#ffffff]" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </button>
  )
}

const DownloadSlider = () => {
const navigate =  useNavigate()
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <ForwardArrow />,
    prevArrow: <BackArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 521,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="bg-[#F4FAFF]" id="main-download-slider">
        <div className="title-box text-center">
          <h2 className="title text-center text-lg lg:text-3xl font-semibold sm:text-3xl md:text-3xl pt-8 text-[#00000]">
            Download
          </h2>
        </div>
        <div className="container flex flex-wrap justify-between items-center mx-auto  py-6">
          <Slider {...settings}>
            {downloadState.map((value, index) => (
              // <Link to={value.link}>
              <div key={index + "_value"} className="h-[20rem] card-width">
                <div className="reative h-[5rem] w-[5rem] bg-trasparent justify-center items-center flex mx-auto ">
                  {/* <Link to={value.link}> */}
                    <img
                      alt="No Image"
                      src={value.image}
                      className=" absolute h-[6rem] z-50 w-[6rem] top-[10%] shadow-sm border-[#F4FAFF] border-[10px] rounded-full  uppercase "
                      onClick={()=>{navigate(`${value.link}`)}}
                    />{" "}
                  {/* </Link> */}
                </div>
                <Link to={value.link}>
                  <div className="flex justify-center p-10 w-full h-52 bg-white md:p-10  md:bg-[#FFFFFF] drop-shadow-none	     rounded-xl">
                    <div className=" justify-self-center">
                      <div>
                        <Link to={value.link}>
                          <h2 className="text-center text-lg underline  mt-[2rem] font-medium text-[#3491FF] capitalize ">
                            {value.title}
                          </h2>
                        </Link>
                      </div>
                      <p className="text-center text-sm  font-medium text-[#0A2A4A] h-fit">
                        {value.use}
                      </p>
                      {/* <div className=" text-center text-xs mt-4 font-small text-[#808D9A]">
                            {value.description}
                          </div> */}
                    </div>
                  </div>
                </Link>
              </div>
              // </Link>l;.
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default DownloadSlider;
