import React, { useState, useEffect } from "react";
import BillingTable from "./BillingTable";
import Microsoftimg from "../../../components/common/Images/MicrosoftTeams-image (1).png";
import BillTable from "./BillTable";
const AccountDescription = ({
  BillInfo,
  setinvoice,
  isDowbload = false,
  HandleClick,
  index,
  loadPanelVisible,
  windowSize
}) => {
  console.log("🚀 ~ file: AccountDescription.js:12 ~ BillInfo", BillInfo)

  let data = JSON.parse(localStorage.getItem("ProfileTab"));
  return (
    <div className="back-forword-btn 2xl:p-8 xl:p-8 lg:p-8 md:p-8 sm:p-8 p-2 mt-4 rounded-2xl	2xl:m-10 xl:m-10 lg:m-10 md:m-10 sm:m-10 m-3">
      {loadPanelVisible ? (
        <div role="status" className="position-loading">
        <svg
          aria-hidden="true"
          className="flex w-12 h-12 items-center justify-center text-gray-200 animate-spin fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
      ):(<div>
        <div className="flex">
          {isDowbload ? (
            <> </>
          ) : (
            <div
              className="flex justify-end cursor-pointer"
              onClick={() => {
                setinvoice(false);
              }}
            >
              <svg
                width="50"
                height="50"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="50" height="50" rx="8" fill="#3491FF" />
                <path
                  d="M22.57 18.9297L16.5 24.9997L22.57 31.0697"b
                  stroke="white"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M33.4999 25H16.6699"
                  stroke="white"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
          <img src={Microsoftimg} className="m-auto 2xl:w-[20%] xl:w-[20%] lg:w-[20%] md:w-[20%] w-[50%]" />
          {!isDowbload ? (
            <svg
              className="w-6 flex items-center mt-[-20px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 122.88 120.89"
              onClick={() =>
                HandleClick(BillInfo?.Year + "/" + BillInfo?.Bill, "", index,BillInfo?.BillDate)
              }
            >
              {/* billNo, current = "", index = 0,date,Bill */}
              <title>download-file</title>
              <path d="M84.58,47a7.71,7.71,0,1,1,10.8,11L66.09,86.88a7.72,7.72,0,0,1-10.82,0L26.4,58.37a7.71,7.71,0,1,1,10.81-11L53.1,63.12l.16-55.47a7.72,7.72,0,0,1,15.43.13l-.15,55L84.58,47ZM0,113.48.1,83.3a7.72,7.72,0,1,1,15.43.14l-.07,22q46,.09,91.91,0l.07-22.12a7.72,7.72,0,1,1,15.44.14l-.1,30h-.09a7.71,7.71,0,0,1-7.64,7.36q-53.73.1-107.38,0A7.7,7.7,0,0,1,0,113.48Z" />
            </svg>
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-between mt-6 grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3">
          <div>
            <div className="font-bold text-lg text-[#3D3D3D]">
              {BillInfo?.CompanyName}
            </div>
            <div className="text-[#3D3D3D] text-md">{BillInfo?.Add1},</div>
            <div className="text-[#3D3D3D] text-md">{BillInfo?.Add2},</div>
            <div className="text-[#3D3D3D] text-md">{BillInfo?.Add3}</div>
            <div className="text-[#3D3D3D] text-md">
              {BillInfo?.City} - {BillInfo?.Pin}
            </div>
          </div>
          <div className="justify-between py-[15px]">
            <div className="text-[#3D3D3D] text-md">
              Bill No:{" "}
              <span className="font-bold ml-2">{BillInfo?.BillNo}</span>
            </div>
            <div className="text-[#3D3D3D] text-md">
              Date :{" "}
              <span className="font-bold ml-3">{BillInfo?.BillDate}</span>
            </div>
            <div className="text-[#3D3D3D] text-md">
              From :{" "}
              <span className="font-bold ml-3">{BillInfo?.FromDate}</span>
            </div>
            <div className="text-[#3D3D3D] text-md">
              To : <span className="font-bold ml-7">{BillInfo?.ToDate}</span>
            </div>
          </div>

          <div>
            <div className="text-[#3D3D3D] text-md">
              CIN No : <b>L67190MH2011PLC220404</b>
            </div>
            <div className="text-[#3D3D3D] text-md">
              36/227, RDP 10, Sector 6, Charkop, Kandivali (West), Mumbai - 400
              067
            </div>
            <div className="text-[#3D3D3D] text-md">
              From : <span className="font-bold ml-3">11/04/2022</span>
            </div>
            <div className="text-[#3D3D3D] text-md">
              E-mail: <span className="font-bold ml-3">info@Tplus.in</span>
            </div>
            <div className="text-[#3D3D3D] text-md">
              URL: <span className="font-bold ml-6"> www.Tplus.in</span>
            </div>
          </div>
        </div>

        <div className="my-10">
        {/* <BillingTable BillInfo={BillInfo} /> */}
          {windowSize >= 768 ? <BillingTable BillInfo={BillInfo} /> : <BillTable BillInfo={BillInfo}/>}
        </div>
        <div>
          <div className="flex justify-between w-[99%] text-[#3D3D3D] text-md">
            <div>E&OE</div>
            <div>{data?.ClientCode}</div>
          </div>
          <div className="py-4 text-[#3D3D3D]">
            <span className="font-bold">{BillInfo?.GSTText}</span>
          </div>
          <div className="font-bold text-md	text-[#3D3D3D]">
            {BillInfo?.CategoryText}
          </div>
          <div className="2xl:flex xl:flex lg:flex md:flex flex-wrap justify-between mb-10 ">
            <div>
              <div className="py-4 text-[#3D3D3D]">
                Subject to Mumbai Jurisdiction only.
              </div>
              <div className=" text-[#3D3D3D]">
                This being computer generated statement, not signed.
              </div>
            </div>
            <div className={windowSize <= 428 && ""}>
              <div className="font-bold py-4 text-[#3d3d3d] 2xl:text-lg xl:text-lg lg:text-lg md:text-lg sm:text-lg text-[16px]">
                SECMARK CONSULTANCY LTD.
              </div>
              <div className="text-[#3D3D3D] text-end"> 
                (PAN: <span className="font-bold">{BillInfo?.PanNo})</span>
              </div>
            </div>
          </div>
        </div>
      </div>)}
    </div>
  );
};

export default AccountDescription;
