export const initalState = [
    {id: 1, active: true, image: '/assets/img/svg-1.svg', title: 'TradePlus ',link: "/trade-plus", use: 'For BSE & NSE Stock Brokers', 
    description: "TradePlus is at the forefront as being the most user-friendly and innovative back-office development solution for stock brokers. TradePlus has end to end solutions for broking back office and currently is used by more then 100 associates. TradePlus provides back-office on mobile App as well as a dashboard which helps users for on screen quick glance solutions." },
    {id: 2, active: true, image: '/assets/img/svg-3.png', title: 'Estro',link: "/estro", use: 'For NDSL DPs', description: "Electronic Security Treasury Records Organiser (ESTRO) is a complete backoffice / front office co-ordination, billing and accounting software for DPs with NSDL"},
    {id: 3, active: true, image: '/assets/img/svg-2.png', title: 'Cross', link: "/cross", use: 'For CDSL DPs', description: "CDSL Record Organizer & Support System (CROSS) is a complete software for managing all activities in a CDSL Depository participant’s office"},
    {id: 4, active: true, image: '/assets/img/svg-4.png', title: 'Commex',  link: "/commex", use: 'For MCX & NCDEX commodities', description: "Backoffice solution for commodity derivatives segments of Indian Stock Exchanges."},
    {id: 5, active: true, image: '/assets/img/product-5.svg', title: 'TradeWeb ', link:"/trade-web", use: 'For BSE & NSE Stock Brokers', 
    description: "TradeWeb provides a web based platform for brokers and  sub brokers to perform back office operations " },
    {id: 6, active: false, image: '/assets/img/svg-1.svg', title: 'Associated modules', link: "/e-sign", use: 'For MCX & NCDEX commodities',
     description: <>
    <ul>
        <li className="product-card-list">
        E-Sign - (for digitally signed contracts, bills and Ledgers etc.)
        </li>
        <li className="product-card-list">
        Browser based online branches
        </li>
        <li className="product-card-list">
        Offline branches with database and data transfer
        </li>
      
    </ul>
    
    </>},
   ];  
   export const downloadState = [
    {id: 1, active: true, image: '/assets/img/svg-1.svg',link:"/downloads/TradePlus&TradeNet", linkname:"TradePlus",title: 'TradePlus & TradeNet', use: 'For BSE & NSE Stock Brokers'},
    {id: 2, active: true, image: '/assets/img/svg-3.png',link:"/downloads/ESTRO&EstroNet", linkname:"ESTRO&EstroNet",title: 'Estro & EstroNet', use: 'Back office / front office co-ordination, billing and accounting software for DPs with NSDL'},
    {id: 3, active: true, image: '/assets/img/svg-2.png',link:"/downloads/CROSS&CrossNet", linkname:"CROSS&CrossNet", title: 'Cross & CrossNet', use: 'Back office / front office co-ordination, billing and accounting software for DPs with CDSL'},
    {id: 5, active: true, image: '/assets/img/svg-4.png',link:"/downloads/CommEx&CommNet", linkname:"CommEx", title: 'Commex', use: 'Back office Software for Commodity Exchange'},
    // {id: 6, active: false, image: '/assets/img/svg-2.png',link:"/downloads/eSign",  linkname:"eSign",title: 'eSign ', use: ''},
    // {id: 6, active: false, image: '/assets/img/svg-4.png',link:"/downloads/PMS",  linkname:"PMS",title: 'PMS', use: 'Portfolio Management Services'},
]
   export const initaldata = [
    {id: 1, active: true, image: '/assets/img/Facility-1.svg', title: 'Delivery charge', use: 'For BSE & NSE Stock Brokers',},
    {id: 2, active: true, image: '/assets/img/Facility-2.svg', title: 'Service Tax', use: 'For CDSL DPs',},
    {id: 3, active: true, image: '/assets/img/Facility-3.svg', title: 'STT', use: 'For NDSL DPs',},
    {id: 4, active: true, image: '/assets/img/Facility-4.svg', title: 'Turnover Charges', use: 'For MCX & NCDEX commodities',},
    {id: 5, active: false, image: '/assets/img/Facility-5.svg', title: 'Stamp Duty', use: 'Back Office Data Access Utility',},
    {id: 6, active: false, image: '/assets/img/Facility-1.svg', title: 'Delivery charge', use: 'For MCX & NCDEX commodities',},
];
   export const initaltext = [
    {id: 1, active: true, sno:'01',  title: 'Maintenance of multiple Demat A/c with different DPs and account types.', use: 'For BSE & NSE Stock Brokers',},
    {id: 2, active: true, sno:'02',  title: 'Automatic Demat Data entry based on Delivery positions of clients.', use: 'For CDSL DPs',},
    {id: 3, active: true, sno:'03',  title: 'Scrip, Client wise Demat reports to give a clear picture of deliveries lying with us as against outstanding receivable / deliverable to clients.', use: 'For NDSL DPs',},
    {id: 4, active: true, sno:'04',  title: 'Printing of Demat Transfer Memo to be submitted to DP. This includes Pay-in, Pay-out, Inter Settlement based on account from which to transfer i.e. beneficiary / pool.', use: 'For MCX & NCDEX commodities',},
    {id: 5, active: false, sno:'05',  title: 'Various reports like Demat Audit, Holding statement, Demat standing available.', use: 'Back Office Data Access Utility',},
    {id: 6, active: false, sno:'06',  title: 'Possible inter-settlement report is available. Automatic generation of inter-settlement transfer entries and printing of instructions.', use: 'For MCX & NCDEX commodities',},
    {id: 7, active: false, sno:'07',  title: 'Transfer into own beneficiary account based on payment receivable status of client is possible. Scanning for client’s holding in our beneficiary balance for making delivery against his sale and generation transfer instruction from our beneficiary into our pool for the purpose of pay-in is also possible.', use: 'For MCX & NCDEX commodities',},
    {id: 8, active: false, sno:'08',  title: 'Demat confirmation letter & report available.', use: 'For MCX & NCDEX commodities',},
    {id: 9, active: false, sno:'09',  title: 'Demat Reconciliation using SPEEDS file, DPC9, DP50, Exchange pay out file, file received from DP etc. may be used for reconciliation.', use: 'For MCX & NCDEX commodities',},
    {id: 10, active: false, sno:'10',  title: 'Corporate action adjustment for Bonus, Split etc. can also generate Journal vouchers for dividend credit for client\'s shares lying with us.', use: 'For MCX & NCDEX commodities',},
    {id: 11, active: false, sno:'11',  title: 'Viewing of holding reports to list quantity of shares of clients lying with us.', use: 'For MCX & NCDEX commodities',},

   ];

   export const initalpagegraph = [
    {id: 1, active: true,   title: 'Reports based on clients, Security for providing to clients or for internal controls and usage.', use: 'For BSE & NSE Stock Brokers',},
    {id: 2, active: true,   title: 'Multiple settlement reports with various options.', use: 'For CDSL DPs',},
    {id: 3, active: true,   title: 'Reports like Brokerages, Service Tax payable, Client Performance, security wise concentration on periodic basis like daily, monthly, quarterly etc.', use: 'For NDSL DPs',},
    {id: 4, active: true,  title: 'Trades daybook (Trades Register) in order of time, security, client for particular day or period across the settlement(s) along option to select / deselect columns.', use: 'For MCX & NCDEX commodities',},
    {id: 5, active: false, title: 'Various reports like Demat Audit, Holding statement, Demat standing available.', use: 'Back Office Data Access Utility',},
    {id: 6, active: false, title: 'Possible inter-settlement report is available. Automatic generation of inter-settlement transfer entries and printing of instructions.', use: 'For MCX & NCDEX commodities',},
    {id: 7, active: false, title: 'Transfer into own beneficiary account based on payment receivable status of client is possible. Scanning for client’s holding in our beneficiary balance for making delivery against his sale and generation transfer instruction from our beneficiary into our pool for the purpose of pay-in is also possible.', use: 'For MCX & NCDEX commodities',},
    {id: 8, active: false, title: 'Demat confirmation letter & report available.', use: 'For MCX & NCDEX commodities',},
    {id: 9, active: false, title: 'Demat Reconciliation using SPEEDS file, DPC9, DP50, Exchange pay out file, file received from DP etc. may be used for reconciliation.', use: 'For MCX & NCDEX commodities',},
    {id: 10, active: false, title: 'Corporate action adjustment for Bonus, Split etc. can also generate Journal vouchers for dividend credit for client\'s shares lying with us.', use: 'For MCX & NCDEX commodities',},
    {id: 11, active: false, title: 'Viewing of holding reports to list quantity of shares of clients lying with us.', use: 'For MCX & NCDEX commodities',},

   ];