import React, { useState } from "react";
import Slider from "react-slick";
import commex from "../../components/common/Images/commex.png"
import commex1 from "../../components/common/Images/commex1.png"
import commex2 from "../../components/common/Images/commex2.png"
import commex3 from "../../components/common/Images/commex3.png"
import commex4 from "../../components/common/Images/commex4.png"
import { LazyLoadImage } from "react-lazy-load-image-component";



const Banners = () => {
    const [left, setLeft] = useState(0)
    console.log("🚀 ~ file: Banner.js ~ line 10 ~ Banner ~ left", left)
    const [right, setRight] = useState(1)
    console.log("🚀 ~ file: Banner.js ~ line 12 ~ Banner ~ right", right)


    const handleLeftIcon = () => {
        setLeft(left + 1)
        setRight(right - 1)
    }

    const handleRightIcon = () => {
        setLeft(left - 1)
    setRight(right + 1)
    }
    const BackArrow = (props) => {
        return (
            <button type="button" onClick={() => { props.onClick(); handleLeftIcon() }} className={`${(left < 5 && left < 0) ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -m-8  z-50 text-blue-700 border hover:bg-[#ffffff]  shadow-lg hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute left-[-5%] top-[50%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn bg-white -m-8 absolute z-50 shadow-lg	text-blue-700 border hover:bg-[#3491FF] hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 left-[-5%] top-[50%]"}`}>
                <svg width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.168 3.56969L7.46297 9.27469C6.78922 9.94844 6.78922 11.0509 7.46297 11.7247L13.168 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        )
    }

    const ForwardArrow = (props) => {
        return (
            <button type="button" onClick={() => { props.onClick(); handleRightIcon() }} className={`${right > 0 && right < 5 ? "stroke-[#ffffff] hover:stroke-[#3491ff] back-forword-btn  bg-[#3491ff] -ml-5 z-50 text-blue-700 border hover:bg-[#ffffff] shadow-lg  hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute lg:right-[-3%] md:right-[-3%] right-[-11%] lg:top-[45%] md:top-[45%] top-[44%]" : "stroke-[#3491ff] hover:stroke-[#ffffff] back-forword-btn  bg-white -ml-5 z-50 text-blue-700 border shadow-lg hover:bg-[#3491FF]   hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 absolute lg:right-[-3%] md:right-[-3%] right-[-11%] lg:top-[45%] md:top-[45%] top-[44%]"}`}>
                <svg className="" width="25" height="25" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.83203 3.56969L13.537 9.27469C14.2108 9.94844 14.2108 11.0509 13.537 11.7247L7.83203 17.4297" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        )
    }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        initialSlide: 0,
        nextArrow: <ForwardArrow />,
        prevArrow: <BackArrow />,
    };
    return (
        <div className="hero-banner  bg-[#F5FAFE]" >
            <div className='container flex flex-wrap  items-center mt-0 md:my-5  mx-auto relative overflow-hidden'>
                <main className="px-10 py-8 md:py-10 md:px- ">
                    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 my-10">
                        <div className="text-center lg:text-left md:text-left">
                            <h1 className="text-3xl tracking-tight font-Lexend text-[#0A2A4A] font-semibold md:leading-3 sm:mx-auto  pb-5 lg:mx-0">
                                Commex
                            </h1>
                            <p className="lg:text-2xl text-[17px] mb-3 lg:leading-4 md:leading-4 leading-8 tracking-tight font-Lexend text-[#0A2A4A] sm:text-2xl md:text-2xl font-medium  sm:mx-auto   lg:mx-0
                            ">
                                Back office software for Commodities Derivatives
                            </p>
                            <p className="text-2xl leading-4 mt-3 tracking-tight font-Lexend text-[#0A2A4A] sm:text-2xl md:text-2xl font-medium  sm:mx-auto   lg:mx-0
                            ">
                                Trades
                            </p>
                            <p className="mt-3 text-[15px] text-[#808D9A] font-light sm:mt-5 lg:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Supports all files of Indian exchanges
                            </p>
                            <p className="mt-3 text-[15px] text-[#808D9A] font-light sm:mt-5 lg:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4 ">
                                List of terminal along with sub-broker / client identification.
                            </p>
                            <p className="mt-3 text-[15px] text-[#808D9A] font-light sm:mt-5 lg:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Unique trade edit screen that displays Order ID, trade ID, terminal No, time, brokerage, brokerage %, brokerage type, net rate, standard rate for each trade and with shortcuts to recalculate brokerages, search, split etc                            </p>
                            <p className="mt-3 text-[15px] text-[#808D9A] font-light sm:mt-5 lg:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Provision to apply absolute brokerage on multiple trades on select & apply basis.
                            </p>
                            <p className="mt-3 text-[15px] text-[#808D9A] font-light sm:mt-5 lg:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Automatic effect for Custodial trades.                           </p>
                            <p className="mt-3 text-[15px] text-[#808D9A] font-light sm:mt-5 lg:text-[18px] sm:max-w-xl sm:mx-auto md:mt-5 md:text-[18px] lg:mx-0 border-l-2 sm:border-[#3491FF] pl-4">
                                Generate trade files from back office for clients / sub-brokers for use in his office.
                            </p>
                        </div>
                        <div className="2xl:ml-[40px] xl:ml-[40px] lg:ml-[40px] md:ml-[40px] sm:ml-[40px] ml:[20px] w-[100%]">
                            {/* <i
                                src='/assets/img/Rectangle-tp.svg'
                                alt="banner-img"
                                className=" absolute top-[0px] ml-[9rem]"
                            />
                            <img
                                src='/assets/img/Rectangle-botm.png'
                                alt="banner-img"
                                className="absolute lg:bottom-[-5rem] lg:ml-[28rem] md:bottom-[7rem] 
                                min-h-0 
                                h-[350px]
                                md:ml-3 
                                lg:w-[150px]
                                md:h-[300px] 
                                lg:h-[300px] 
                                top-[29.6rem] 
                                "
                            /> */}
                            <Slider {...settings}>
                                <div>
                                    <div className="lg:mb-10 lg:mt-[3px] md:mb-8 md:mt-[3px] mb-0 mt-0">
                                        <p className="text-[#00C673] font-semibold  text-center flex justify-center lg: lg:text-[25px] md:text=[25px] text-[20px]  m-0  ">
                                        Performance Report</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div> 
                                    <LazyLoadImage
                                        src={commex}                       
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain" effect="blur"
                                    />
                                </div>
                                <div>
                                    <div className="lg:mb-10 lg:mt-[3px] md:mb-8 md:mt-[3px] mb-0 mt-0">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center  lg:text-[25px] md:text=[25px] text-[20px] m-0 ">Bill Summary</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>           
                                    <LazyLoadImage
                                        src={commex1}
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain" effect="blur"
                                    />
                                </div>
                                <div>
                                    <div className="lg:mb-10 lg:mt-[3px] md:mb-8 md:mt-[3px] mb-0 mt-0">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center  lg:text-[25px] md:text=[25px] text-[20px] m-0 ">Ledger</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                     
                                    <LazyLoadImage
                                        src={commex2} effect="blur"
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain"
                                    />
                                </div>
                                <div>
                                    <div className="lg:mb-10 lg:mt-[3px] md:mb-8 md:mt-[3px] mb-0 mt-0">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center  lg:text-[25px] md:text=[25px] text-[20px] m-0 ">Special Charges Details</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                   
                                     <LazyLoadImage
                                        src={commex3} effect="blur"
                                        alt="banner-img"
                                        className="w-[100%] p-4 h-[369px] object-contain"
                                    />
                                </div>
                                <div>
                                    <div className="lg:mb-10 lg:mt-[3px] md:mb-8 md:mt-[3px] mb-0 mt-0">
                                        <p className="text-[#00C673] font-semibold text-center flex justify-center  lg:text-[25px] md:text=[25px] text-[20px] m-0 ">Client Master</p>
                                        <hr class=" mx-auto w-[10%] h-[2px] bg-[#00C673] rounded border-0  "></hr>
                                    </div>                                   
                                     <LazyLoadImage
                                        src={commex4}
                                        alt="banner-img" effect="blur"
                                        className="w-[100%] p-4 h-[369px] object-contain"
                                    />
                                </div>
                                {/* <img
  src="/assets/img/banner-img.svg"
  alt="banner-img"
  className="w-100"
/> */}
                            </Slider>


                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}
export default Banners;