import React,{useState,useEffect} from "react";
import SliderCard from "./SliderCard";
import axios from "axios";
import fileDownload from "js-file-download";
const PmsDownload = (props) => {

const {dateData} = props
console.log("🚀 ~ file: PmsDownload.js:8 ~ PmsDownload ~ dateData", dateData)

  let pmsData = JSON.parse(localStorage.getItem("ProfileTab"));
  
  const DownloadZipFile = async (zippedFile, fileName = "ZippedData.zip") => {
    // var blob = new Blob([str2bytes(myData)], {type: "application/zip"});
    // saveAs(blob, "data.zip");
    axios
      .get(zippedFile, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
    //   const blobPromise =  fetch(zippedFile)
    // .then(function (response) {
    //     if (response.status === 200 || response.status === 0) {
    //         return Promise.resolve(response.blob());
    //     } else {
    //         return Promise.reject(new Error(response.statusText));
    //     }
    // })

    //   let blob = new Blob([blobPromise], { type: 'application/zip' })

    //   const downloadUrl = URL.createObjectURL(blob)
    //   let a = document.createElement("a");
    //   a.href = downloadUrl;
    //   a.download = fileName;
    //   document.body.appendChild(a);
    //   a.click();
  };
  return (
    <div className=" test-back">
      {[ "R050" ,"S001" , "A005"].includes(pmsData.ClientCode) && <div className="py-10">
        <div>
          <div className="font-medium lg:text-3xl md:text-3xl text-[25px] mb-4	text-center text-[#0A2A4A]">
            PMS
          </div>
          <div className="mb-2 lg:text-[18px] mg:text-[18px] text-[16px] text-center text-[#808D9A] font-normal lg:px-[7rem] md:px-[7rem] px-4">
            {" "}
            Manages portfolios with high degree of automation, internal controls
            and compliance.
          </div>
        </div>
        <div className="grid sm:grid-cols-2 md:grid-cols-4 justify-between mx-10 download-sliders"
>
          <div className="">
            <h2 className="text-white text-lg text-center rounded bg-[#3491FF] py-3 m-0">
              Head Office
            </h2>
            <div className="table-auto w-full  whitespace-no-wrap p-0 m-0 border-r-2" >
                  <div className="bg-[#3491]  odd-color lg:mb-0 md:mb-0 mb-6" >
                    <div>
                    <SliderCard
                      index={props.index}
                      clickToDownload
                      title="Head office"
                      subTitle="PMS"
                      img={"/assets/img/svg-1.svg"}
                      arrow={false}
                      date={dateData.length > 0 && dateData?.find(a=> a[1] == "Accts/PMS.zip")?.[2]}
                      download={"/download/Accts/PMS.zip"}
                      DownloadZipFile={props.DownloadZipFile}
                      buttonTxt={true}
                    />
                      </div>                    
                  </div>
                </div>
            
          </div>
          {/* <div className="relative">
          <h2 className=" text-center lg:text-2xl text-[18px] font-medium text-[#000000]">
            Branch & Individual Client
          </h2>
          <SliderCard
            title="Knowledge Base"
            subTitle="Circular"
            img={"/assets/img/svg-7.svg"}
            arrow={false}
            index={props.index}
          />
        </div> */}
          {/* <div className="text-gray-600 body-font">
          <div className="single-slider px-5 py-0">
            <div>
              <div className="flex justify-center p-2  bg-slate-300 md:p-5 bg-[#FFFFFF] items-center w-80  rounded-xl">
                <div></div>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </div>}
    </div>
  );
};

export default PmsDownload;
