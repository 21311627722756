import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CloseIcon from "@mui/icons-material/Close";



export default function BasicModal({
  openModal,
  onclose,
  dtitle,
  DownloadZipFile,
  sectionId,
}) {
  console.log("🚀 ~ file: ModelPopUp.js ~ line 18 ~ dtitle", dtitle)

  const [open, setOpen] = React.useState(openModal);
  const [TradePlusX, setTradePlusX] = useState("");
  const [list, setList] = useState("");
  const [title, setTitle] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    onclose(false);
  };

  const trade = [
    {title: "TradePlus",subtitle: "SQL Server 2000 SetUp",href: "/download/Documentation/SQLSERVERSETUP.zip",},

    {title: "TradePlus",subtitle: "Step to Run DB",href: "/download/Documentation/RunDBTradeplus.zip",},

    {title: "TradePlus",subtitle: "Log Truncate",href: "/download/Documentation/TradeplusLogTrucncate.zip",},

    {title: "TradePlus",subtitle: "Restore DataBase",href: "/download/Documentation/RestoreTradeplus.zip",},

    {title: "TradePlus",subtitle: "Restore DataBase in SQL 2005",href: "/download/Documentation/RestoreTradeplusDB2005.zip"},

    {title: "TradePlus",subtitle: "Backup Device",href: "/download/Documentation/TradeplusBackupdevice.zip"},

    {title: "TradePlus",subtitle: "Archival",href: "/download/Documentation/TplusArchival.zip"},

    {title: "TradePlus",subtitle: "Archival 2nd Time",href: "/download/Documentation/TPLUSARCHIVAL2.zip"},

    {title: "TradePlus",subtitle: "Contract Reset",href: "/download/Documentation/contractreset.zip"},

    {title: "TradePlus",subtitle: "Remisier",href: "/download/Documentation/TplusRemissier.zip"},

    {title: "TradePlus",subtitle: "Freeze Dormant Client",href: "/download/Documentation/TplusFreezeDormat.zip"},

    {title: "TradePlus",subtitle: "Margin Management",href: "/download/Documentation/MarginManagement.zip"},

    {title: "TradePlus",subtitle: "Quarterly Settlement of Accounts",href: "/download/Documentation/QuarterlySettlement.zip"},

    {title: "TradePlus",subtitle: "Step File Upload",href: "/download/Documentation/STPFileUpload.zip"},

    {title: "TradePlus",subtitle: "BackUp Device in 2005",href: "/download/Documentation/TradeplusBackupDevice2005.zip"},

    {title: "TradePlus",subtitle: "Step To Configer Profile",href: "/download/Documentation/Profiler.zip"},  

    {title: "TradePlus",subtitle: "BSE to Change(XLS File)",href: "/download/Documentation/BSE_TO_Charges.zip"},

    {title: "TradePlus",subtitle: "BOI XLS to TXT",href: "/download/Documentation/BOI_XLS2TXT.zip"},

    {title: "Esign",subtitle: "Installation",href: "/download/Documentation/esignsetup.zip",},

    {title: "Esign",subtitle: "Certificate Setup",href: "/download/Documentation/CertificateSetup.zip",},

    {title: "Esign",subtitle: "Restore Database",href: "/download/Documentation/RestoreTradeplus.zip",},

    {title: "Esign",subtitle: "BackUp Device",href: "/download/Documentation/EsignTplusBakDiv.zip",},

    {title: "Esign",subtitle: "ShrinkDatabace",href: "/download/Documentation/shrinkdbesign.zip",},

    {title: "Esign",subtitle: "Manage HTML",href: "/download/Documentation/eSignPro_ManageHTML.zip",},

    {title: "Esign",subtitle: "Footer Files Format",href: "/download/misc/FOOTERFILES.ZIP",},

    {title: "Esign",subtitle: "Footer Old File Format",href: "/download/misc/FOOTERFILESO.ZIP",},
    

    {title: "TradeNet",subtitle: "Step To Configure",href: "/download/Documentation/SetupTradenet3.zip",},

    {title: "TradeNet",subtitle: "Internet Explorer Setting",href: "/download/Documentation/SettingIE.zip",},

    {title: "TradeWeb",subtitle: "Step To Configure",href: "/download/Documentation/ConfigureTradeweb.zip",},

    {title: "TradeWeb",subtitle: "Log truncate",href: "/download/Documentation/TradeWeblogturncate.zip",},    

    // {title:"Restore Tradeplus", path:"/assets/zip/Trade/RestoreTradeplus.zip"},

    // {title:"Restore Tradeplus DB2005", path:"/assets/zip/Trade/RestoreTradeplusDB2005.zip"},

    // {title:"Run DB Tradeplus", path:"/assets/zip/Trade/RunDBTradeplus.zip"},
    // {title:"Tplus Archival", path:"/assets/zip/Trade/TplusArchival.zip"},
    // {title:"TPLUS ARCHIVAL2", path:"/assets/zip/Trade/TPLUSARCHIVAL2.zip"},
    // {title:"Tplus Freeze Dormat", path:"/assets/zip/Trade/TplusFreezeDormat.zip"},
    // {title:"Tplus Remissier", path:"/assets/zip/Trade/TplusRemissier.zip"},
    // {title:"Trade-plus Backup device", path:"/assets/zip/Trade/TradeplusBackupdevice.zip"},
    // {title:"Trade-plus Backup Device 2005", path:"/assets/zip/Trade/TradeplusBackupDevice2005.zip"},
    // {title:"Trade-plus Log Trucncate", path:"/assets/zip/Trade/TradeplusLogTrucncate.zip"},
    // {title:"Trade-Web-logturncate", path:"/assets/zip/Trade/TradeWeblogturncate.zip"},
  ];
  const cross = [
    {title:"Cross", subtitle: "SQL Server 2000 Setup",href: "/download/Documentation/SQLSERVERSETUP.zip  ",},
    {title:"Cross", subtitle: "Step TO Run DB",href: "/download/Documentation/RunDBCross.zip",},
    {title:"Cross", subtitle: "Log truncate", href: "/download/Documentation/CrossLogTruncate.zip" },
    {title:"Cross", subtitle: "Restore Database",href: "/download/Documentation/RestorCross.zip",},
    {title:"Cross", subtitle: "Backup Devive",href: "/download/Documentation/CrossBackupDevice.zip",},
    {title:"Cross", subtitle: "Backup Devive SQL 2005",href: "/download/Documentation/BDSQLS2005.zip",},
    {title:"Cross", subtitle: "Attach Database", href: "/download/Documentation/Crossattach.zip" },
    {title:"Cross", subtitle: "Archival",href: "/download/Documentation/crossarchival1.zip",},
    {title:"Cross", subtitle: "Archival 2nd Time",href: "/download/Documentation/CrossArchival2ndtime.zip",},
    {title:"Cross", subtitle: "Cross File Format",href: "/download/Documentation/crossfileformat.zip",},
    {title:"Cross", subtitle: "AMC Calculation",href: "/download/Documentation/cross_amc.zip",},
    {title:"Cross", subtitle: "POA File Format",href: "/download/Documentation/CrossPOAFileformat.zip",},
    {title:"Cross", subtitle: "Day End Process",href: "/download/Documentation/DayEndProcess.zip",},
    {title:"Cross", subtitle: "Cross Import",href: "/download/Documentation/CrossImport.zip",},
    {title:"Cross", subtitle: "Reconcile Holding",href: "/download/Documentation/ReconcileHolding.zip",},
    {title:"Cross", subtitle: "Step To Configure Profile",href: "/download/Documentation/Profiler.zip",},
    {title:"Esign", subtitle: "Installtion", href: "/download/Documentation/EsignCrossInstall.zip" },
    {title:"Esign", subtitle: "Certificate Setup", href: "/download/Documentation/CertificateSetup.zip" },
    {title:"CrossNet", subtitle: "Steps To Configure",href: "/download/Documentation/ConfigureCrossNet.zip",},
    {title:"CrossNet", subtitle: "Internet Explorer Setting", href: "/download/Documentation/SettingIE.zip" },
    {title:"CrossWeb", subtitle: "Steps To Configure",href: "/download/Documentation/ConfigureCrossweb.zip",},


    // {title:"Cross",subtitle: "Brokerage base don Product",path: "/download/zip/CrossHelp/BDSQLS2005.zip",},
    // {title:"Cross", subtitle: "Run DB Commex", path: "/assets/zip/CrossHelp/Crossattach.zip" },
    // {title:"Cross", subtitle: "SQL SERVER SETUP",path: "/assets/zip/CrossHelp/CrossBackupDevice.zip",},
    // {title:"Cross", subtitle: "SQL SERVER SETUP",path: "/assets/zip/CrossHelp/crossfileformat.zip",},
    // {title:"Cross", subtitle: "SQL SERVER SETUP",path: "/assets/zip/CrossHelp/CrossLogTruncate (1).zip",},
    // {title:"Cross", subtitle: "SQL SERVER SETUP",path: "/assets/zip/CrossHelp/EsignCrossInstall.zip",},
    // {title:"Cross", subtitle: "SQL SERVER SETUP",path: "/assets/zip/CrossHelp/Profiler.zip",},
    // {title:"Cross", subtitle: "SQL SERVER SETUP",path: "/assets/zip/CrossHelp/RunDBCross.zip",},
    // {title:"Cross", subtitle: "SQL SERVER SETUP",path: "/assets/zip/CrossHelp/SettingIE (1).zip",},
  ];
  const commex = [
    {title:"Commex", subtitle: "SQL Server 2000 Setup",href: "/download/Documentation/SQLSERVERSETUP.zip",},
    {title:"Commex", subtitle: "Log Truncate",href: "/download/Documentation/Commaxlogturncate.zip",},
    {title:"Commex", subtitle: "Restore Database",href: "/download/Documentation/RestorCommex.zip",},
    {title:"Commex", subtitle: "BackUp Device",href: "/download/Documentation/CommexBackupDevice.zip",},
    {title:"Commex", subtitle: "Archival",href: "/download/Documentation/COMMEXARCHIVAL.zip",},
    {title:"Commex", subtitle: "Product Wise Brokerage",href: "/download/Documentation/BrokeragebasedonProduct.zip",},
    {title:"Commex", subtitle: "Step To Configure Prifile",href: "/download/Documentation/Profiler.zip",},
    {title:"Commex", subtitle: "Step To Create DSN",href: "/download/Documentation/COMMEXDSN.zip",},
    {title:"Commex", subtitle: "Remisier",href: "/download/Documentation/RemissierCommex.zip",},
    {title:"Commex", subtitle: "Step TO Run DB",href: "/download/Documentation/RunDBCommex.zip",},
    {title:"Esign", subtitle: "Installation",href: "/download/Documentation/esigncommexsetup.zip",},
    {title:"Esign", subtitle: "Certificate Setup",href: "/download/Documentation/CertificateSetup.zip",},
    {title:"Esign", subtitle: "Footer Files",href: "/download/misc/FOOTERFILESC.ZIP",},
  ];

  const esign = [
    { title: "Esign DBRESTORE", path: "/assets/zip/Esign/EsignDBRESTORE.zip" },
    {
      title: "eSignPro_ManageHTML",
      path: "/assets/zip/Esign/eSignPro_ManageHTML.zip",
    },
    { title: "esign setup", path: "/assets/zip/Esign/esignsetup.zip" },
    {
      title: "Esign Tplus BakDiv",
      path: "/assets/zip/Esign/EsignTplusBakDiv.zip",
    },
  ];

  const estro = [
    {title:"Estro", subtitle: "SQL Server 2000 Setup",href: "/download/Documentation/SQLSERVERSETUP.zip",},
    {title:"Estro", subtitle: "Step TO Run DB",href: "/download/Documentation/RunDBEstro.zip",},
    {title:"Estro", subtitle: "Log Truncate",href: "/download/Documentation/Estrologtruncate.zip",},
    {title:"Estro", subtitle: "Restore Database",href: "/download/Documentation/RestorEstro.zip",},
    {title:"Estro", subtitle: "BackUp Device",href: "/download/Documentation/EstroBackupdevice.zip",},
    {title:"Estro", subtitle: "Backup Devive on 2005",href: "/download/Documentation/BackUpDevice2005Estro.zip",},
    {title:"Estro", subtitle: "Archival",href: "/download/Documentation/EstroArchival.zip",},
    {title:"Estro", subtitle: "Step To Configure Prifile",href: "/download/Documentation/Profiler.zip",},
    {title:"Estro", subtitle: "Back Office File Format [NSDL Old Version]",href: "/download/Documentation/NSDLBKOffFormatO.zip",},
    {title:"Estro", subtitle: "Step To Configure",href: "/download/Documentation/ConfigureEstroWeb.zip",},
    {title:"Estro", subtitle: "Day End Process",href: "/download/Documentation/DayEndProcessestro.zip",},
    {title:"Estro", subtitle: "Step To Configure Prifile",href: "/download/Documentation/Profiler.zip",},
    {title:"Estro", subtitle: "POA File Format",href: "/download/Documentation/NSDLPOAFileformat.zip",},
    {title:"Esign", subtitle: "Installtion",href: "/download/Documentation/EsignEstroInstall.zip",},
    {title:"Esign", subtitle: "Certificate Setup",href: "/download/Documentation/CertificateSetup.zip",},
    {title:"EstroNet", subtitle: "Step To Configure",href: "/download/Documentation/ToSetupEstroNet.zip",},
    {title:"EstroNet", subtitle: "Internet Explorer Setting",href: "/download/Documentation/SettingIE.zip",},
    {title:"EstroWeb", subtitle: "Step To Configure",href: "/download/Documentation/SettingIE.zip",},

    // {
    //   title: "Certificate Setup",
    //   path: "/assets/zip/CertificateSetup.zip",
    // },
    // {
    //   title: "Esign Estro Install",
    //   path: "/assets/zip/EsignEstroInstall.zip",
    // },
    // { title: "Setting IE", path: "/assets/zip/SettingIE.zip" },
    // {
    //   title: "To Setup EstroNet",
    //   path: "/assets/zip/ToSetupEstroNet.zip",
    // },
  ];

  const tradeplusx = [
    {
      title: "Overview &amp; Setup",
      href: "/download/tradeplus/TradePlusX.pdf",
    },
    {
      title: "Margin Management",
      href: "/download/tradeplus/MarginManagement.pdf",
    },
    {
      title: "Control Center <span class='text-[#746868]'> (Imports, Billing, Error Diagnosis) </span> vc",
      href: "/download/tradeplus/ControlCenter.pdf",
    },
    {
      title: "Changes - March 2021 <span class='text-[#746868]'> (Menu ShortCuts, Ledger Drilldown, Masking) </span>",
      href: "/download/tradeplus/ControlCenter.pdf",
    },
    {
      title: "Control Center",
      href: "/download/tradeplus/ControlCenter.pdf",
    },
    {
      title: "Automated Demat Process<span class='text-[#746868]'> (Completely automated Demat Process)</span>",
      href: "/download/tradeplus/Demat_Process.pdf",
    },
    
    {
      title: "Settlement Of Client Account/ Quarterly Square Off <span class='text-[#746868]'> (As per guidlines issued by exchanges on 16 January 2020)</span> ",
      href: "/download/tradeplus/Demat_Process.pdf",
    },
    {
      title: "NSE Trade Import through API <span class='text-[#746868]'>(To take care of Discontinuation of NOTIS)</span>",
      href: "/download/tradeplus/NSETradesThroughAPI.pdf",
    },
    {
      title: "Change Request through TradeWeb <br/><span class='text-[#746868]'> (Client can request for Mobile, Email, Address Changethrough TradeWeb with OTP Confirmation. <br/>Scrutiny and Authorization through TradePlusX)</span>",
      href: "/download/tradeplus/EditRequiestViaTradeWeb.pdf",
    },
    {
      title: "Comming Soon </br>  Special Brokerages & STT Related Changes",
      href: "",
    },
    // {
    //   title: "Demat Process",
    //   href: "/assets/zip/Trade/TradePdf/Demat_Process.pdf",
    // },
    // {
    //   title: "Edit Requiest Via Trade-Web",
    //   href: "/assets/zip/Trade/TradePdf/EditRequiestViaTradeWeb.pdf",
    // },
    // {
    //   title: "Margin Management",
    //   href: "/assets/zip/Trade/TradePdf/MarginManagement.pdf",
    // },
    // {
    //   title: "NSE Trades Through API",
    //   href: "/assets/zip/Trade/TradePdf/NSETradesThroughAPI.pdf",
    // },
    // {
    //   title: "Running Account Settlement",
    //   href: "/assets/zip/Trade/TradePdf/RunningAccountSettlement.pdf",
    // },
    // {
    //   title: "Short Cut Keys-Ledger Masking",
    //   href: "/assets/zip/Trade/TradePdf/ShortCutKeys_Ledger_Masking .pdf",
    // },
    // {
    //   title: "Trade Plus-X",
    //   href: "/assets/zip/Trade/TradePdf/TradePlusX.pdf",
    // },
  ];

  // const TradePlus = [
  //   {title:"TradePlus", subtitle:"BOI_XLS2TXT", href:"/assets/zip/TradeHelp/BOI_XLS2TXT.zip"},
  //   {title:"TradePlus", subtitle:"BSE_TO_Charges", href:"/assets/zip/TradeHelp/BSE_TO_Charges.zip"},
  //   {title:"TradePlus", subtitle:"STPFileUpload", href:"/assets/zip/TradeHelp/STPFileUpload.zip"},
  //   {title:"TradePlus", subtitle:"QuarterlySettlement", href:"/assets/zip/TradeHelp/QuarterlySettlement.zip"},
  //   {title:"TradePlus", subtitle:"MarginManagement", href:"/assets/zip/TradeHelp/MarginManagement.zip"},
  //   {title:"Esign", subtitle:"CertificateSetup", href:"/assets/zip/TradeHelp/CertificateSetup (1).zip"},
  //   {title:"Esign", subtitle:"shrinkdbesign", href:"/assets/zip/TradeHelp/shrinkdbesign.zip"}
  // ]

  // const TradeNet = [
  //   // {title:"", href:"/assets/zip/TradeHelp/ConfigureTradeweb.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/EsignDBRESTORE.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/eSignPro_ManageHTML.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/esignsetup.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/EsignTplusBakDiv.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/FOOTERFILES.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/FOOTERFILESO.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/Profiler.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/SettingIE (1).zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/SetupTradenet3.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/SQLSERVERSETUP.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/TradeplusBackupDevice2005.zip"},
  //   // {title:"", href:"/assets/zip/TradeHelp/TradeWeblogturncate.zip"},
  // ]

  // const TradeWeb = [

  // ]

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  useEffect(() => {
    if (openModal) {
      if (dtitle == "Trade"){
        setList(trade);        setTradePlusX([])

      }
      if (dtitle == "commex") {
        setList(commex);        setTradePlusX([])

      }
      if (dtitle == "cross") {
        setList(cross);        setTradePlusX([])

      }
      if (dtitle == "esign") {
        setList(esign);        setTradePlusX([])

      }
      if (dtitle == "estro") {
        setList(estro);
        setTradePlusX([])

      }

      if (dtitle == "TradePlus-X") {
        setTradePlusX(tradeplusx)
        setList([]);
      }

    }
  }, [dtitle, openModal]);

  const style = {
    position: "absolute",
    height: "90%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: `${TradePlusX?.length ? "70%" : "80%" }`,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflowY: 'hidden'
  
  };
  return (
    <div>
      <Modal
        className="w-100%"
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="md:p-4 p-2" >
          <div className="flex justify-between mb-4">
            <div className="helpheading font-semibold text-2xl items-center text-blue-800 capitalize">
              {dtitle == "Trade Plus-X"
                ? dtitle: "Help"}
                {/* : ` ${dtitle + "Help"}`} */}
            </div>
            <div>
              <CloseIcon onClick={onclose} className="cursor-pointer" />
            </div>
          </div>
          {/* <div className="font-semibold text-2xl text-blue-800 ">TradePlus</div>
          <div className="font-semibold text-2xl text-blue-800 ">Esign</div> */}

          <hr />
          <div className="Popup md:px-5 px-0">
            <div className="w-full mt-3 mb-3 text-[20px] font-medium text-blue-800">
              <b>{list?.length ? list[0].title:""} </b>
            </div>

            {list.length ?<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4  pr-10 mb-5 w-[100%]" >
              {list.length ? (list.map((d, i) => {
                return (
                  <>
                    {["Cross","TradePlus","Commex","Estro"].includes(d.title)  && (
                      <div className="w-[100%]"onClick={() => {
                            
                        DownloadZipFile(d.href, sectionId);
                      }}>
                        {/* {list[i].title !== list[i-1]?.title &&<div className="w-full">{list[i+1].title}</div>} */}
                        <div className="flex justify-between items-center helpmodel">
                          <div className="m">{d.subtitle}</div>
                          <a
                          // href={d.href}
                            className="flex items-center  uppercase text-size font-[8px] tracking-wide bg-[#3491FF] text-white p-2 rounded-lg focus:outline-none focus:shadow-outline"
                          >
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.5"
                              />
                            </svg>
                          </a>

                          {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                        </div>

                        <hr />
                      </div>)}

                  </>
                );
              })
              ) : (
                <></>
              )}

            </div>:""}
            {list.length ? <div className="w-full mt-3 mb-3 text-[20px] font-medium text-blue-800">
              <b>ESign</b>
            </div>:""}
            {list.length ?<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4  pr-10 mb-5 w-[100%]" >
              {list.length ? (list.map((d, i) => {
                return (
                  <>
                    {d.title == "Esign" && (
                      <div className="w-[100%]"  onClick={() => {
                          
                        DownloadZipFile(d.href, sectionId);
                      }}>
                      {/* {list[i].title !== list[i-1]?.title &&<div className="w-full">{list[i+1].title}</div>} */}
                      <div className="flex justify-between items-center helpmodel">
                        <div className="m">{d.subtitle}</div>
                        <a
                        // href={d.href}
                          className="flex items-center  uppercase text-size font-[8px] tracking-wide bg-[#3491FF] text-white p-2 rounded-lg focus:outline-none focus:shadow-outline"
                         
                        >
                          <svg
                            width="21"
                            height="18"
                            viewBox="0 0 21 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                              fill="white"
                              stroke="white"
                              stroke-width="0.5"
                            />
                          </svg>
                        </a>

                        {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                      </div>

                      <hr />
                    </div>)}
                  </>
                );
              })
              ) : (
                <></>
              )}
            </div>:""}


            {list[15]?.title == "EstroNet" && <div className="w-full mt-3 mb-3 text-[20px] font-medium text-blue-800">
              <b>EstroNet</b>
            </div>}
            {list[26]?.title == "TradeNet" && <div className="w-full mt-3 mb-3 text-[20px] font-medium text-blue-800">
              <b>TradeNet</b>
            </div>}
            {list[18]?.title == "CrossNet" && <div className="w-full mt-3 mb-3 text-[20px] font-medium text-blue-800">
              <b>CrossNet</b>
            </div>}
            {list?.length ?<div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4  pr-10 mb-5 w-[100%]" >
              {list.length ? (list.map((d, i) => {
                return (
                  <>
                    {d.title == "TradeNet" && (
                      <div className="w-[100%] " onClick={() => {
                        DownloadZipFile(d.href, sectionId);
                      }}>
                        {/* {list[i].title !== list[i+1].title &&<div className="w-full">{list[i+1].title}</div>} */}
                        <div className="flex  justify-between items-center helpmodel">
                          <div className="">{d.subtitle}</div>
                          <a 
                          // href={d.href}
                            className="flex items-center  uppercase text-size font-[8px] tracking-wide bg-[#3491FF] text-white p-2 rounded-lg focus:outline-none focus:shadow-outline"
                           
                          >
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.5"
                              />
                            </svg>
                          </a>

                          {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                        </div>

                        <hr />
                      </div>)}
                      {d.title == "EstroNet" && (
                      <div className="w-[100%] "onClick={() => {
                        DownloadZipFile(d.href, sectionId);
                      }}>
                        {/* {list[i].title !== list[i+1].title &&<div className="w-full">{list[i+1].title}</div>} */}
                        <div className="flex  justify-between items-center helpmodel">
                          <div className="">{d.subtitle}</div>
                          <a
                          //  href={d.href}
                            className="flex items-center  uppercase text-size font-[8px] tracking-wide bg-[#3491FF] text-white p-2 rounded-lg focus:outline-none focus:shadow-outline"
                            
                          >
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.5"
                              />
                            </svg>
                          </a>

                          {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                        </div>

                        <hr />
                      </div>)}
                      {d.title == "CrossNet" && (
                      <div className="w-[100%] "onClick={() => {
                        DownloadZipFile(d.href, sectionId);
                      }}>
                        {/* {list[i].title !== list[i+1].title &&<div className="w-full">{list[i+1].title}</div>} */}
                        <div className="flex  justify-between items-center helpmodel">
                          <div className="">{d.subtitle}</div>
                          <a
                          //  href={d.href}
                            className="flex items-center  uppercase text-size font-[8px] tracking-wide bg-[#3491FF] text-white p-2 rounded-lg focus:outline-none focus:shadow-outline"
                            
                          >
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.5"
                              />
                            </svg>
                          </a>

                          {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                        </div>

                        <hr />
                      </div>)}
                      
                  </>
                );
              })
              ) : (
                <></>
              )}
            </div>:""}


            {list[28]?.title == "TradeWeb" && <div className="w-full mt-3 mb-3 text-[20px] font-medium text-blue-800">
              <b>TradeWeb</b>
            </div>}
            {list[17]?.title == "EstroWeb" && <div className="w-full mt-3 mb-3 text-[20px] font-medium text-blue-800">
              <b>EstroWeb</b>
            </div>}
            {list[20]?.title == "CrossWeb" && <div className="w-full mt-3 mb-3 text-[20px] font-medium text-blue-800">
              <b>CrossWeb</b>
            </div>}
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4  pr-10 mb-5 w-[100%]" >
              {list.length ? (list.map((d, i) => {

                return (
                  <>
                    {d.title == "TradeWeb" && (
                      <div className="w-[100%] " 
                      onClick={() => {
                        DownloadZipFile(d.href, sectionId);
                      }}
                      >
                        {/* {list[i].title !== list[i+1].title &&<div className="w-full">{list[i+1].title}</div>} */}
                        <div className="flex  justify-between items-center helpmodel">
                          <div className="">{d.subtitle}</div>
                          <a 
                            // href={d.href}
                            className="flex items-center  uppercase text-size font-[8px] tracking-wide bg-[#3491FF] text-white p-2 rounded-lg focus:outline-none focus:shadow-outline"
                           
                          >
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.5"
                              />
                            </svg>
                          </a>

                          {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                        </div>

                        <hr />
                      </div>)}
                      {d.title == "CrossWeb" && (
                      <div className="w-[100%] " onClick={() => {
                        DownloadZipFile(d.href, sectionId);
                       }}>
                        {/* {list[i].title !== list[i+1].title &&<div className="w-full">{list[i+1].title}</div>} */}
                        <div className="flex  justify-between items-center helpmodel">
                          <div className="">{d.subtitle}</div>
                          <a
                            // href={d.href}
                            className="flex items-center  uppercase text-size font-[8px] tracking-wide bg-[#3491FF] text-white p-2 rounded-lg focus:outline-none focus:shadow-outline"
                           
                          >
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.5"
                              />
                            </svg>
                          </a>

                          {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                        </div>

                        <hr />
                      </div>)}
                      {d.title == "EstroWeb" && (
                      <div className="w-[100%] " onClick={() => {
                        DownloadZipFile(d.href, sectionId);
                      }}>
                        {/* {list[i].title !== list[i+1].title &&<div className="w-full">{list[i+1].title}</div>} */}
                        <div className="flex  justify-between items-center helpmodel">
                          <div className="">{d.subtitle}</div>
                          <a 
                          // href={d.href}
                            className="flex items-center  uppercase text-size font-[8px] tracking-wide bg-[#3491FF] text-white p-2 rounded-lg focus:outline-none focus:shadow-outline"
                           
                          >
                            <svg
                              width="21"
                              height="18"
                              viewBox="0 0 21 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                                fill="white"
                                stroke="white"
                                stroke-width="0.5"
                              />
                            </svg>
                          </a>

                          {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                        </div>

                        <hr />
                      </div>)}
                  </>
                );
              })
              ) : (
                <></>
              )}
            </div>

            <div className="" >
            {TradePlusX?.length ? TradePlusX.map((d) => {
              return (
                <div className="flex justify-between items-center helpmodel ">
                        <div className="m" dangerouslySetInnerHTML={{__html:d?.title}}></div>
                        {!d.title.includes("Comming Soon") &&<button
                        href={d?.href}
                          className="flex items-center  uppercase text-size font-[8px]  tracking-wide bg-[#3491FF]  p-2 rounded-lg focus:outline-none focus:shadow-outline"
                          onClick={() => {
                            DownloadZipFile(d.href, sectionId);
                          }}
                        >
                          <svg
                            width="21"
                            height="18"
                            viewBox="0 0 21 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.1843 10.5135V14.8378C20.1843 16.0172 19.2015 17 18.0221 17H3.16216C1.9828 17 1 16.0172 1 14.8378V10.5135C1 10.199 1.27518 9.92383 1.58968 9.92383C1.90418 9.92383 2.17936 10.199 2.17936 10.5135V14.8378C2.17936 15.3882 2.61179 15.8206 3.16216 15.8206H18.0221C18.5725 15.8206 19.0049 15.3882 19.0049 14.8378V10.5135C19.0049 10.199 19.2801 9.92383 19.5946 9.92383C19.9091 9.92383 20.1843 10.1597 20.1843 10.5135ZM9.45186 12.3612C9.76636 12.6757 10.1988 12.8329 10.5919 12.8329C10.985 12.8329 11.4175 12.6757 11.732 12.3612L14.4838 9.60934C14.7197 9.37346 14.7197 9.01966 14.4838 8.78378C14.2479 8.54791 13.8941 8.54791 13.6583 8.78378L11.1816 11.2604V1.58968C11.1816 1.27518 10.9064 1 10.5919 1C10.2774 1 10.0022 1.27518 10.0022 1.58968V11.2214L7.52557 8.7447C7.2897 8.50883 6.93589 8.50883 6.70002 8.7447C6.46415 8.98057 6.46415 9.33438 6.70002 9.57025L9.45186 12.3612Z"
                              fill="white"
                              stroke="white"
                              stroke-width="0.5"
                            />
                          </svg>
                        </button>}

                        {/* <FileDownloadIcon className='cursor pointer' onClick={()=>{DownloadZipFile(d.path,sectionId)}}/> */}
                      </div>
              )
            }):""}
          </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}